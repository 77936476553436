import React from 'react'


import { map,isEmpty } from "lodash";

import { toast } from 'react-toastify';

import convertDate from './../../../../convertDateToDisplay'

import { RestUrls } from "./../../../../Urls"
import ReturnMoneyCashValuesWorker from './ReturnMoneyCashValuesWorker';

export default function BodyModalReturnMoneyWorker(props) {

    const { item, showHideModal,  workerData } = props

    //console.log('props', props);

    const returnMoneyTranzila = () => { 
        toast.success('החשבונית החזר נשלחה בהצלחה');
        showHideModal();
    }

    
    const href = RestUrls.baseApiUrl1 + 'assets/files/invoices/'+ item?.pdfTranzilaFile;

  return (
    <div className='bodyModalReturnMoneyprops'>

        <div className='heshData'>
            <h3>פרטי החשבונית</h3>
            <ul>
                <li>
                    <span>{item.created_atShow} - </span>
                    
                    <span>₪{ item.money ? (parseFloat(item.money)).toFixed(2) : parseFloat(item.moneyPay).toFixed(2)  }</span>
                    { item?.pdfTranzilaFile && <a style={{fontSize : '16px'}} href={ href } target='_blank' rel="noreferrer" >לצפיית החשבונית &raquo;</a> }
                </li>
            </ul>
        </div>

        { !isEmpty(item?.moneyReturns?.data) && <div className='moneyReturnsData'>

            <h3>חשבוניות זיכוי קיימות</h3>

            <ul>
                    {   map(item?.moneyReturns?.data, pdfFile => {
                        
                        return < ShowMyHesh key={pdfFile?.id} item = { pdfFile } />
                    
                        })
                    }
                
            </ul>

        </div> }

        <h3>יצירת חשבונית זיכוי חדשה</h3>

        <div className='ReturnMoneyCreditCard'>
            <ReturnMoneyCashValuesWorker item={ item }  workerData = { workerData } returnMoneyTranzila={ returnMoneyTranzila } />
        </div>
        

    </div>
  )
}

function  ShowMyHesh(props) {

    const {item} = props

    const href = RestUrls.baseApiUrl1 + 'assets/files/invoices/'+ item?.pdfTranzilaFile;
    
    let returnType = 'מזומן';

    if(item?.returnType === 'card' ) {

        returnType = 'כרטיס אשראי';

    } else if(item?.returnType === 'bank' ) {


        returnType = 'העברה בנקאית';

    }

    return(
        <li>
            <span>{convertDate(item.created_at)} - </span>
            <span>חשבונית זיכוי </span>
            {item?.responseTranzilaMoneyBack ? <strong>כ"א</strong> : <strong>{returnType}</strong>}
            <span> - ₪{item?.cardCash}</span>
            <a style={{fontSize : '16px'}} href={ href } target='_blank' rel="noreferrer" >לצפיית החשבונית &raquo;</a>
        </li>
    );


}