import React, { Component } from "react";
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import { Table, Button } from "react-bootstrap";
import convertDate from "./../../../convertDateToDisplay";
//import { ConstantsNames } from "./../../../Urls"
import ShareDocument from "./ShareDocument";
import DigformsPDF from "../Clients/DigformsPDF";
//import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

export default class WsignStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusDocs: false,
      displayAll: false,
    };
  }

  loadData = (
    url,
    controller,
    objectToSend = {},
    stateNameResponse = false
  ) => {
    let setStateName;

    if (stateNameResponse) {
      setStateName = stateNameResponse;
    } else {
      setStateName = "data";
    }

    let getData = getDataFromApi(url, objectToSend, controller);
    getData
      .then(function (response) {
        return response;
      })
      .then((getData) =>
        this.setState({ [setStateName]: getData }, function () {
          //console.log(getData);

          if (stateNameResponse === "deletteDocRow") {
            setTimeout(() => this.componentDidMount(), 50);
          }
        })
      );
  };

  componentDidMount() {
    //console.log(this.props.clientId + ' ' + this.props.workerId);
    this.loadData(
      "getStatusDocs",
      "Wsign",
      { clientId: this.props.clientId, workerId: this.props.workerId },
      "statusDocs"
    );
  }

  deletteMe = (id) => {
    this.loadData("deletteDocRow", "Wsign", { docRowId: id }, "deletteDocRow");
  };

  render() {
    //let clientId = this.props.clientId;
    //let fileId = 'c4ca4238a0b923820dcc509a6f75849b';

    let activeId = this.props.activeId ? this.props.activeId : false;
    let counter = 0;

    return (
      <section className="Wsign">
        <div className="reLoad">
          <button
            className="goBackCustomBtn"
            onClick={() => this.componentDidMount()}
          >
            <i className="fas fa-sync-alt"></i>
          </button>
        </div>

        <DigformsPDF
          reloadDocuments={() => this.componentDidMount()}
          showSendOnlyMails={false}
          clientId={this.props.clientId ? this.props.clientId : false}
          workerId={this.props.workerId ? this.props.workerId : false}
        />

        {this.state.statusDocs.documents &&
        this.state.statusDocs.documents[0] ? (
          <div className="StatusDocs">
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>תאריך פתיחה</th>
                  <th>תאריך חתימה</th>
                  <th>שם מסמך</th>
                  <th>שיתוף</th>
                  <th>#</th>
                </tr>
              </thead>

              <tbody>
                {this.state.statusDocs.documents.map((item) => {
                  let classBounce = "";
                  let classBold = "";

                  if (parseInt(item.id) === parseInt(activeId)) {
                    classBounce = "animate__animated animate__flash";
                    classBold = "boldClass";
                  }

                  if (counter < 3 || this.state.displayAll) {
                    counter++;
                    let dateSign = item.fileName ? (
                      convertDate(item.dateSign)
                    ) : (
                      <span style={{ color: "red" }}>לא נחתם</span>
                    );

                    return (
                      <tr key={item.id} className={classBold}>
                        <td>
                          <div className={classBounce}>
                            {convertDate(item.created_at)}
                          </div>
                        </td>
                        <td>
                          <div className={classBounce}>{dateSign}</div>
                        </td>
                        <td>
                          <div className={classBounce}>{item.name}</div>
                        </td>
                        <td>
                          <ShareDocument item={item} mail={this.props?.mail} />
                        </td>
                        <td>
                          <button
                            style={{ fontSize: "14px" }}
                            disabled={item.fileName ? true : false}
                            className="goBackCustomBtn"
                            onClick={() => this.deletteMe(item.id)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  } else return false;
                })}
              </tbody>
            </Table>

            <div style={{ textAlign: "left" }}>
              <Button
                className=""
                onClick={() =>
                  this.setState({ displayAll: !this.state.displayAll })
                }
                variant="secondary"
                size=""
              >
                {!this.state.displayAll ? "כל המסמכים" : "סגירה"}
              </Button>
            </div>
          </div>
        ) : (
          <h2 className="noDocuments">לא קיימים מסמכים</h2>
        )}
      </section>
    );
  }
}
