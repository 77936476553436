import React, { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { map } from "lodash"; //isEmpty
import { toast } from "react-toastify";
import getDataFromApi from "../../../../DinamicForm/newApi/getDataFromApi";
import loader from "../../../../../img/preLoader.gif";

export default function MultiSelectorRakazOz(props) {
  const { action, seniorId, data, options, onSelectSocialWorker } = props;

  let label;
  if (action === "coordinator") {
    label = "שם רכז/ת מטפל";
  } else {
    label = 'שם עו"ס מבקר';
  }

  const [selected, setSelected] = useState(cleanData(data));

  const [loading, setLoading] = useState(false);
  const [, setResponse] = useState(false); // response

  //console.log('selected >', selected);

  const handleSearch = (value) => {
    setSelected(value);
    if (value?.length) {
      onSelectSocialWorker?.(value?.[0]);
    } else {
      onSelectSocialWorker?.(null);
    }
    const sendData = {
      seniorId: seniorId,
      selected: value,
      action: action,
    };

    sendtoApi(
      "changeLinksCoordinatorOZ",
      "first",
      sendData,
      setLoading,
      setResponse
    );
  };

  //console.log('response', response);

  return (
    <div className="selectInput">
      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      <Autocomplete
        id="combo-box-demo"
        className="autocompleteInput"
        /* size='small'     */
        multiple
        options={cleanData(options)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selected}
        onChange={(event, newValue) => {
          //const value = newValue?.id ? newValue.id : '';
          //console.log('value', value);
          //setAsset(value);
          handleSearch(newValue);
        }}
        getOptionLabel={(selected) => selected.name}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            //value={option.title}
            label={label}
            //error={ showErrors && isEmpty(formData.mosads) }
            //helperText={ errors[inputsBuilder[11].name] ? inputsBuilder[11].helperText : "" }
          />
        )}
      />
    </div>
  );
}

function cleanData(data) {
  const newData = [];

  map(data, (item) => {
    newData.push({
      id: item?.id ? item?.id : item?.userId,
      name: item.name,
    });
  });

  return newData;
}

function sendtoApi(url, controller, objectToSend, setLoading, setResponseData) {
  setLoading(true);

  //console.log(url);

  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    if (getData.error) {
      toast.error(getData.error, {
        toastId: "responseError",
      });
    }

    if (getData.ok) {
      toast.success(getData.ok);
      setResponseData(getData?.data);
    } else {
      toast.error("שגיאה", {
        toastId: "genericError",
      });
    }
  });

  getData.catch((error) => {
    console.log(error);
  });

  getData.finally(() => {
    setLoading(false);
  });
}
