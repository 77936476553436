import React, { Fragment, useEffect, useState } from 'react'

import { sendtoAdmin } from '../../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../../img/preLoader.gif"
import NewTransaction from './NewTransaction';
import TransactionsList from './TransactionsList';
import WorkerType from './WorkerType';

import { isEmpty } from "lodash";

export default function FinanceWorkerIndex(props) {

    const { dataParam, linkedClients } = props

    //console.log('linkedClients', linkedClients);

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

    const [workerTransactions, setWorkerTransactions ] = useState([]);

    const [isAnotherFirmImport, setIsAnotherFirmImport] = useState(dataParam?.importFirmName ? true : false);

    useEffect(() => {

      loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = () => { 

      const send = {
        workerId : dataParam?.id
      };
      sendtoAdmin('loadData', 'financeWorkers', send, setResponseData, setLoading);

    }

    useEffect(() => {
    
      if(responseData?.workerTransactions) {

        setWorkerTransactions(responseData?.workerTransactions);

      }

    }, [responseData])
    

    //console.log( "dataPage", dataParam );
    //console.log('workerTransactions: ',workerTransactions);

  return (
    <div className='accountsWorker'>

      <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

      < WorkerType dataParam = { dataParam } setLoading = {setLoading} isAnotherFirmImport = {isAnotherFirmImport} setIsAnotherFirmImport = { setIsAnotherFirmImport } />

      { responseData && <Fragment>
        
        < NewTransaction isAnotherFirmImport = {isAnotherFirmImport} dataParam = { dataParam } transactions = { responseData?.transactions } setWorkerTransactions = {setWorkerTransactions} />

        < TransactionsList  reload = { loadData } linkedClient = { isEmpty(linkedClients) ? false : linkedClients[0].dataSenior } dataParam = { dataParam } setLoading = {setLoading} workerTransactions = { workerTransactions } setWorkerTransactions = {setWorkerTransactions} />

      </Fragment> }
      

      
    </div>
  )
}
