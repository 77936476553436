import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";

import loader from "../../../../img/preLoader.gif";
import convertDate from "../../../convertDateToDisplay";
import getDataFromApi, {
  sendtoAdmin,
} from "../../../DinamicForm/newApi/getDataFromApi";

export default function AddVisitsToSocialWorker(props) {
  const [showFormAndData, setShowFormAndData] = useState(false);

  const addVisits = () => {
    //console.log('addVisits');
    setShowFormAndData(!showFormAndData);
  };

  return (
    <Fragment>
      {/* <Button
        className="addBtn addSection"
        onClick={addVisits}
        variant="secondary"
        size="sm"
      >
        הוספה / הסרה ללקוח ביקור בית <i className="fas fa-calendar-plus" />
      </Button> */}

      <AddForm
        {...props}
        setShowFormAndData={setShowFormAndData}
        showFormAndData={showFormAndData}
        socialWorkerId={props.socialWorkerId}
      />
    </Fragment>
  );
}

function AddForm(props) {
  const {
    clientId,
    workerId,
    fullName,
    showFormAndData,
    setShowFormAndData,
    socialWorkerId,
    refetch,
  } = props;

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(false);
  const [visitType, setVisitType] = useState("");

  const setLocalResponseData = (data) => {
    setResponseData(data);
    if (data && data.existVisit && data.data) {
      setShowFormAndData(true);
    }
  };

  useEffect(() => {
    const send = {
      clientId: clientId,
      workerId: workerId,
      action: "getInfo",
    };

    sendtoAdmin(
      "getManualVisits",
      "HomeVisits",
      send,
      setLocalResponseData,
      setLoading
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log(responseData);

  const addVisit = () => {
    if (!socialWorkerId) {
      toast.error('לא נבחר עו"ס');
      return;
    }
    const send = {
      clientId: clientId,
      workerId: workerId,
      action: "newVisit",
      visitType: visitType,
      fullName: fullName,
      socialWorkerId: socialWorkerId,
    };

    const send1 = {
      clientId: clientId,
      workerId: workerId,
      action: "getInfo",
    };

    sendtoApi(
      "getManualVisits",
      "HomeVisits",
      send,
      setLoading,
      setResponseData,
      send1,
      () => {
        refetch?.();
      }
    );
  };

  const deleteVisit = (_visitType) => {
    //console.log(' deleteVisit',  deleteVisit);

    const send = {
      clientId: clientId,
      workerId: workerId,
      action: "deleteVisit",
      visitType: _visitType ? _visitType : visitType,
      fullName: fullName,
    };

    const send1 = {
      clientId: clientId,
      workerId: workerId,
      action: "getInfo",
    };

    sendtoApi(
      "getManualVisits",
      "HomeVisits",
      send,
      setLoading,
      setResponseData,
      send1,
      () => {
        refetch?.();
      }
    );
  };

  if (!showFormAndData) {
    return <></>;
  }

  return (
    <div className="showFormAndData">
      <div className="addForm">
        <img
          src={loader}
          alt="loader"
          className={
            !loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        {responseData && !responseData?.existVisit ? (
          <Row className="formCont">
            <div className="col-md-8 divBoostrap col-sm-12">
              <InputGroup className="customInput">
                <InputGroup.Prepend className="inputPrependLabel ">
                  <InputGroup.Text>סוג ביקור</InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl
                  as="select"
                  /* 'inputPrepend customSelect' */
                  className="inputPrepend customSelect"
                  onChange={(event) => setVisitType(event.target.value)}
                  value={visitType}
                  name="visitType"
                >
                  <option value="">בחר/י</option>

                  {responseData.visitTypes.map((item) => {
                    if (true) {
                      /* currentState={this.state} */
                      return (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      );
                    } else return false;
                  })}
                </FormControl>
              </InputGroup>
            </div>
            <div className="col-md-4 divBoostrap col-sm-12">
              <Button
                className="addVisit"
                onClick={addVisit}
                variant={visitType ? "primary" : "secondary"}
                disabled={!visitType}
                size="sm"
              >
                <span>הוספה</span>
              </Button>
            </div>
          </Row>
        ) : (
          <div className="existVisit">
            {!loading && responseData?.data && (
              <div className="existVisit">
                <p>
                  קיימת בקשה לביקור ביום:{" "}
                  {convertDate(responseData?.data?.created_at)}
                </p>

                {/* <Button
                  onClick={() => deleteVisit(responseData?.data?.visitType)}
                  variant="danger"
                  size="sm"
                >
                  לביטול הבקשה
                </Button> */}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function sendtoApi(
  url,
  controller,
  objectToSend,
  setLoading,
  setResponseData,
  send1,
  successCB = () => {}
) {
  setLoading(true);
  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    if (getData?.ok) {
      toast.success(getData?.ok);
      sendtoAdmin(
        "getManualVisits",
        "HomeVisits",
        send1,
        setResponseData,
        setLoading,
        "all",
        successCB
      );
    } else if (getData?.error) {
      toast.error(getData?.error);
    } else {
      toast.error("שגיאה");
    }

    //console.log('getData', getData);
  });

  getData.catch((error) => {
    console.log(error);
  });

  getData.finally(() => {
    setLoading(false);
  });
}
