import React, { useState, useEffect } from 'react';

import { sendtoAdmin } from '../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../img/preLoader.gif"
import ReportBeforeAsama from './ReportBeforeAsama';



export default function EditVisitBeforeAsama(props) {

    const {editRowId} = props

    

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

    useEffect(() => {

        const send = {
          editRowId: editRowId.id
        };
    
        sendtoAdmin('getDataEditRowBeforeAsama', 'HomeVisits', send, setResponseData, setLoading);
    
    
      }, [editRowId]);


   

  return (
    <div className='editVisitBeforeAsama'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />
        
        {responseData && < ReportBeforeAsama
            clientId={responseData.seniorId}
            savedData={responseData.statusData}
            allSavedData={responseData}
            visitDate={false}
            chosensocialWorker={false}

        /> }
    </div>
  )
}
