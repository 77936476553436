import React, { Component } from "react";
import InputMet from "./InputMet";

import { InputGroup, FormControl, Button, Alert } from "react-bootstrap";

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import loader from "../../../../img/preLoader.gif";
import { toast } from "react-toastify";
import convertDateToDb from "./../../../convertDateToDb";

export default class ReturnMoneyCashValues extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);
    let today = new Date();

    this.state = {
      loading: false,
      cardCash: this.props.item.superTotal,
      returnMoneyTranzila: false,
      text: "",
      returnType: "cash", // -cash -card -bank new 09/2023
      returnBankDate: convertDateToDb(
        new Date(today.toString().replace(/-/g, "/"))
      ),
    };
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend = {},
    stateNameResponse = false
  ) => {
    let setStateName;

    if (stateNameResponse) {
      setStateName = stateNameResponse;
    } else {
      setStateName = "data";
    }

    let getData = getDataFromApi(url, objectToSend, controller);
    getData
      .then(function (response) {
        return response;
      })
      .then((getData) =>
        this.setState({ [setStateName]: getData, loading: false }, function () {
          if (stateNameResponse === "returnMoneyTranzila") {
            //this.modalShow(true);
            //console.log(this.state.returnMoneyTranzila);

            if (
              this.state.returnMoneyTranzila &&
              this.state.returnMoneyTranzila.success
            ) {
              this.props.returnMoneyTranzila();
            } else {
              toast.error("שגיאה ביצירת חשבונית זיכוי");
            }
          }
        })
      );
  };

  sendPayRow4CashReturn = () => {
    this.setState({ loading: true });

    const sendMe = {
      invoiceId: this.props.item.id,
      clientId: this.props.item.clientId,
      cash: this.state.cardCash,
      text: this.state.text,
      returnType: this.state.returnType, // -cash -card -bank new 09/2023
      returnBankDate: convertDateToDb(this.state.returnBankDate),
    };

    //console.log('sendMe', sendMe);

    this.sendtoAdmin("returnCashMoney", "Pays", sendMe, "returnMoneyTranzila");
  };

  submit = (event) => {
    //console.log(event);

    this.setState(event, function () {
      //console.log(this.state)
    });
  };

  handleSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, function () {
      //console.log(this.state)
    });
  };

  render() {
    // console.log('state: ',this.state);

    let cardFlag = this.state.cardCash && this.state.text ? true : false;

    return (
      <div>
        <div className="row returnMoneyCardValues">
          <img
            src={loader}
            alt="loader"
            className={
              !this.state.loading
                ? "loader"
                : "loader active animate__animated animate__fadeIn"
            }
          />

          <h2 className="blueH2">פרטים לקבלת זיכוי</h2>

          <div className="col-md-3 divBoostrap col-sm-12">
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel">
                <InputGroup.Text id="basic-addon3">אמצעי תשלום</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                className="inputPrepend selectPrepend"
                onChange={this.handleSelect}
                name="returnType"
                value={this.state.returnType}
                id="basic-url"
                aria-describedby="basic-addon3"
              >
                <option value="cash">מזומן</option>
                <option value="bank">העברה בנקאית</option>
                <option value="card">כרטיס אשראי</option>
              </FormControl>
            </InputGroup>
          </div>

          <div className="col-md-3 divBoostrap col-sm-12">
            <InputMet
              text="תאריך תשלום"
              as={"dateInput"}
              value={this.state.returnBankDate}
              placeholder=""
              name="returnBankDate"
              submit={this.submit}
            />
          </div>

          <div className="col-md-2 divBoostrap col-sm-12">
            <InputMet
              text="סכום"
              inputType={"number"}
              as={false}
              value={this.state.cardCash}
              placeholder=""
              name="cardCash"
              submit={this.submit}
            />
          </div>

          <div className="col-md-4 divBoostrap col-sm-12">
            <InputMet
              text="תיאור זיכוי"
              inputType={"text"}
              as={false}
              value={this.state.text}
              placeholder=""
              name="text"
              submit={this.submit}
            />
          </div>

          {/* <Button className=""  onClick={ () => this.addPay('card') } size=""  >תשלום באשראי</Button> */}
        </div>

        {this.state.returnMoneyTranzila &&
        this.state.returnMoneyTranzila.error ? (
          <Alert variant="danger">
            <p style={{ fontSize: "16px", margin: "0 0 20px 0" }}>
              {this.state.returnMoneyTranzila.error}
            </p>
          </Alert>
        ) : (
          false
        )}

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            className=""
            onClick={this.sendPayRow4CashReturn}
            size=""
            disabled={cardFlag && !this.state.loading ? false : true}
            variant={cardFlag && !this.state.loading ? "primary" : "secondary"}
          >
            שליחה
          </Button>
        </div>
      </div>
    );
  }
}
