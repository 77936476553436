import React, { Component } from "react";
import InputMet from "./InputMet";
import { Button, InputGroup, FormControl, Alert } from "react-bootstrap";
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import loader from "../../../../img/preLoader.gif";
import { toast } from "react-toastify";

export default class ReturnMoneyCreditCardValues extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);

    this.state = {
      loading: false,
      cardCash: this.props.item.superTotal,
      cardNumber: "",
      cardValidityMonth: "",
      cardValidityYear: "",
      cardCvv: "",
      cardTz: "",
      returnMoneyTranzila: false,
      text: "",
    };
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend = {},
    stateNameResponse = false
  ) => {
    let setStateName;

    if (stateNameResponse) {
      setStateName = stateNameResponse;
    } else {
      setStateName = "data";
    }

    let getData = getDataFromApi(url, objectToSend, controller);
    getData
      .then(function (response) {
        return response;
      })
      .then((getData) =>
        this.setState({ [setStateName]: getData, loading: false }, function () {
          if (stateNameResponse === "returnMoneyTranzila") {
            //this.modalShow(true);
            console.log(this.state.returnMoneyTranzila);

            if (
              this.state.returnMoneyTranzila &&
              this.state.returnMoneyTranzila.success
            ) {
              this.props.returnMoneyTranzila();
            } else {
              toast.error("שגיאה ביצירת חשבונית זיכוי");
            }
          }
        })
      );
  };

  sendPayRow4CashReturn = () => {
    this.setState({ loading: true });

    this.sendtoAdmin(
      "returnCreditCardMoney",
      "Pays",
      {
        invoiceId: this.props.item.id,
        clientId: this.props.item.clientId,
        ccno: this.state.cardNumber,
        expdate: this.state.cardValidityMonth + this.state.cardValidityYear,
        myid: this.state.cardTz,
        mycvv: this.state.cardCvv,
        cardCash: this.state.cardCash,
        text: this.state.text,
      },
      "returnMoneyTranzila"
    );
  };

  submit = (event) => {
    //console.log(event);

    this.setState(event, function () {
      //console.log(this.state)
    });
  };

  handleSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, function () {
      //console.log(this.state)
    });
  };

  render() {
    //console.log(this.state);

    let cardFlag =
      this.state.cardNumber &&
      this.state.cardValidityMonth &&
      this.state.cardValidityYear &&
      this.state.cardCvv &&
      this.state.cardTz &&
      this.state.cardCash &&
      this.state.text
        ? true
        : false;

    const month = [];
    const year = [];

    let number;

    for (var i = 1; i <= 12; i++) {
      if (i >= 0 && i < 10) {
        number = "0" + i;
      } else {
        number = i;
      }
      month.push(
        <option value={number} key={i}>
          {number}
        </option>
      );
    }

    let currentYear = new Date().getFullYear().toString().substr(-2);

    currentYear = parseInt(currentYear);
    //console.log(currentYear);

    for (i = currentYear; i <= currentYear + 10; i++) {
      if (i >= 0 && i < 10) {
        number = "0" + i;
      } else {
        number = i;
      }
      year.push(
        <option value={number} key={i}>
          20{number}
        </option>
      );
    }

    return (
      <div>
        <div className="row returnMoneyCardValues">
          <img
            src={loader}
            alt="loader"
            className={
              !this.state.loading
                ? "loader"
                : "loader active animate__animated animate__fadeIn"
            }
          />

          <h2 className="blueH2">פרטי כרטיס אשראי לקבלת זיכוי</h2>

          <div className="col-md-6 divBoostrap col-sm-12">
            <InputMet
              text="מספר כרטיס"
              inputType={"number"}
              as={false}
              value={this.state.cardNumber}
              placeholder=""
              name="cardNumber"
              submit={this.submit}
            />
          </div>

          <div className="col-md-3 divBoostrap col-sm-12">
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel ">
                <InputGroup.Text>תוקף שנה</InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="inputPrepend customSelect"
                onChange={this.handleSelect}
                value={this.state.cardValidityYear}
                name="cardValidityYear"
              >
                <option value="">בחר...</option>

                {year}
              </FormControl>
            </InputGroup>
          </div>

          <div className="col-md-3 divBoostrap col-sm-12">
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel ">
                <InputGroup.Text>תוקף חודש</InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="inputPrepend customSelect"
                onChange={this.handleSelect}
                value={this.state.cardValidityMonth}
                name="cardValidityMonth"
              >
                <option value="">בחר...</option>

                {month}
              </FormControl>
            </InputGroup>
          </div>

          <div className="col-md-4 divBoostrap col-sm-12">
            <InputMet
              text="סכום"
              inputType={"number"}
              as={false}
              value={this.state.cardCash}
              placeholder=""
              name="cardCash"
              submit={this.submit}
            />
          </div>

          <div className="col-md-4 divBoostrap col-sm-12">
            <InputMet
              text="CVV"
              inputType={"number"}
              as={false}
              value={this.state.cardCvv}
              placeholder=""
              name="cardCvv"
              submit={this.submit}
            />
          </div>

          <div className="col-md-4 divBoostrap col-sm-12">
            <InputMet
              text="ת.ז"
              inputType={"number"}
              as={false}
              value={this.state.cardTz}
              placeholder=""
              name="cardTz"
              submit={this.submit}
            />
          </div>

          <div className="col-md-12 divBoostrap col-sm-12">
            <InputMet
              text="תיאור זיכוי"
              inputType={"text"}
              as={false}
              value={this.state.text}
              placeholder=""
              name="text"
              submit={this.submit}
            />
          </div>

          {/* <Button className=""  onClick={ () => this.addPay('card') } size=""  >תשלום באשראי</Button> */}
        </div>

        {this.state.returnMoneyTranzila &&
        this.state.returnMoneyTranzila.error ? (
          <Alert variant="danger">
            <p style={{ fontSize: "16px", margin: "0 0 20px 0" }}>
              {this.state.returnMoneyTranzila.error}
            </p>
          </Alert>
        ) : (
          false
        )}

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            className=""
            onClick={this.sendPayRow4CashReturn}
            size=""
            disabled={cardFlag && !this.state.loading ? false : true}
            variant={cardFlag && !this.state.loading ? "primary" : "secondary"}
          >
            שליחה
          </Button>
        </div>
      </div>
    );
  }
}
