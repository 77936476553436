import React, { useEffect, useState } from 'react'

import { map,range,isEmpty,find } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function Placement(props) {

    const { setMoreDataPdf, sendForm, responseData } = props

    //console.log('props', props);

    let userJ = JSON.parse(localStorage.getItem('user-info'));
    let user = userJ.data;
    /* console.log(user);  */

    const inputsBuilder = [

        {
            'name' : 'branchAddress',
            'label' : 'רחוב ומספר',
            'defaultValue': '',
            
        },
        {
            'name' : 'branchCity',
            'label' : 'עיר',
            'defaultValue': '',
            
        },

        {
            'name' : 'branchPhone',
            'label' : 'מספר טלפון',
            'defaultValue': '',
            
        },

        {
            'name' : 'branchFax',
            'label' : 'מספר פקס',
            'defaultValue': '',
            
        },

        {
            'name' : 'branchEmergencyPhone',
            'label' : 'מספר טלפון כשהמשרד סגור',
            'defaultValue': '',
            
        },

        {
            'name' : 'citizenshipEng',
            'label' : 'ארץ מוצא',
            'defaultValue': '',
            
        },


        {
            'name' : 'branchAddressEng',
            'label' : 'רחוב ומספר סניף',
            'defaultValue': '',
            
        },

        

        {
            'name' : 'nameClientEng',
            'label' : 'שם המעסיק',
            'defaultValue': '',
            
        },

        {
            'name' : 'addressClientEng',
            'label' : 'רחוב המעסיק',
            'defaultValue': '',
            
        },

        {
            'name' : 'cityClientEng',
            'label' : 'עיר המעסיק',
            'defaultValue': '',
            
        },


        {
            'name' : 'apropName',
            'label' : 'שם',
            'defaultValue': '',
            
        },
        {
            'name' : 'apropTz',
            'label' : 'ת.ז',
            'defaultValue': '',
            
        },
        {
            'name' : 'apropAddress',
            'label' : 'כתובות',
            'defaultValue': '',
            
        },
        {
            'name' : 'apropPhone',
            'label' : 'טלפון',
            'defaultValue': '',
            
        },
        {
            'name' : 'apropKirva',
            'label' : 'קירבה למטופל',
            'defaultValue': '',
            
        },

        
        
        {
            'name' : 'apropNameEng',
            'label' : 'שם',
            'defaultValue': '',
            
        },
        
        {
            'name' : 'apropAddressEng',
            'label' : 'כתובות',
            'defaultValue': '',
            
        },
        
        {
            'name' : 'apropKirvaEng',
            'label' : 'קירבה למטופל',
            'defaultValue': '',
            
        },

        {
            'name' : 'userName',
            'label' : 'שם מורשה החתימה',
            'defaultValue': user.name,
            
        },

        {
            'name' : 'userNameEng',
            'label' : 'שם מורשה החתימה באנגלית',
            'defaultValue': '',
            
        },
        
        
        
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder,responseData));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    

    useEffect(() => {
        
        
            setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);

    const [branchesList, setBranchesList] = useState([]);
    const [selectedBranch, setSelectedBranch ] = useState('');

    useEffect(() => {

        if(!isEmpty(responseData?.branchesList)) {
            setBranchesList(responseData?.branchesList);
        }

      
    }, [responseData])
    
    const changeBranch = (e) => { 

        setSelectedBranch(e.target.value);
        const branch = find(branchesList, { id: e.target.value });

        //console.log('branch', branch);

        if(branch?.name) {

            setFormData({
                ...formData,
                branchAddress : branch.addressHe,
                branchCity: branch.cityHe,
                branchPhone: branch.phone,
                branchEmergencyPhone: branch.phoneEmrg,
                branchAddressEng: branch.addressEn,
                cityClientEng: branch.cityEn,
            })

        }
        
    }
    

  return (
    <div>

        <h3 style={{margin: '0 0 10px 0'}}>פרטי הסניף</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >


        { !isEmpty(branchesList) && <Grid item md={12}  >

            <FormControl

                as="select"
                //name={inputsBuilder[3].name}
                onChange={ changeBranch }
                value={ selectedBranch }

            >
                <option value="">בחירת סניף</option>

                {map(branchesList, item => {
                    if (true) {
                        return < option value ={item.id } key ={ item.id }> 
                                    { item.name } 
                                </ option >
                    } else return false})}
            </FormControl>

        </Grid> }
            
        {range(0, 5).map((current, index, range) => {

            return <Grid item md={4} key={current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        <h3 style={{margin: '30px 0 10px 0'}}>נא להשלים פרטים באנגלית:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

        {range(6, 10).map((current, index, range) => {

            return <Grid item md={4} key={current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>


        <h3 style={{margin: '30px 0 10px 0'}}>במקרים בהם יש קרוב משפחה/אפוטרופוס</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

        {range(10, 15).map((current, index, range) => {

            return <Grid item md={4} key={current}>

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        <h3 style={{margin: '30px 0 10px 0'}}>במקרים בהם יש קרוב משפחה/אפוטרופוס באנגלית</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

        {range(15, 18).map((current, index, range) => {

            return <Grid item md={4} key={current}>

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}
        </Grid>

        <h3 style={{margin: '30px 0 10px 0'}}>שם מורשה</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

        {range(18, 20).map((current, index, range) => {

            return <Grid item md={4} key={current}>

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}
        </Grid>

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder,savedData) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = savedData[item.name] ?  savedData[item.name] : item.defaultValue
        
    })
    
    return objReturn;

}

