import React, { Component } from "react";
import { logout } from "./../../logout";
import { Navbar, Nav, Button } from "react-bootstrap";
import { ConstantsNames } from "./../../Urls";

import { NavLink } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */

import bulletMainMenu from "./../../../img/icons/bulletMainMenu.png";

import menuHomeIcon from "./../../../img/icons/menuIcons/home.svg";
import menuClientsIcon from "./../../../img/icons/menuIcons/clients.svg";
import menuWorkersIcon from "./../../../img/icons/menuIcons/workers.svg";
//import menuReportsIcon from "./../../../img/icons/menuIcons/reports.svg";
import menuMatchIcon from "./../../../img/icons/menuIcons/match.svg";
import menuConfigIcon from "./../../../img/icons/menuIcons/config.svg";
import addUser from "./../../../img/icons/addUser.svg";

export default class NavMenu extends Component {
  render() {
    let PagesNames = this.props.PagesNames; //On Site Connector
    let clientData = this.props.info.SiteDataitems.SystemData;
    let currentPage = this.props.page;

    let userJ = JSON.parse(localStorage.getItem("user-info"));
    let user = userJ.data;

    return (
      <div className="header-wrapper">
        <div className="site-header">
          <Navbar
            className="mobileNavbar"
            fixed="top"
            expand="lg"
            bg="dark"
            variant="dark"
          >
            <Navbar.Toggle className="" aria-controls="basic-navbar-nav" />

            <NavLink
              className="navbar-brand"
              activeClassName={
                currentPage === PagesNames.home.method ? "is-active" : ""
              }
              role="menuitem"
              onClick={() => scroll.scrollTo(0)}
              to={PagesNames.home.url}
            >
              <img
                src={ConstantsNames.pic + clientData.logoClientMenu}
                alt={clientData.FirmName}
                className="logoClient"
              />
            </NavLink>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto rtl">
                {user.userCredential !== "socialWorker" ? (
                  <div>
                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.home.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.home.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={menuHomeIcon}
                        alt="בולט"
                      />
                      <span>{PagesNames.home.namePage}</span>
                    </NavLink>

                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.clients.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.clients.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={menuClientsIcon}
                        alt="בולט"
                      />
                      <span>{PagesNames.clients.namePage}</span>
                    </NavLink>

                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.workers.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.workers.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={menuWorkersIcon}
                        alt="בולט"
                      />
                      <span>{PagesNames.workers.namePage}</span>
                    </NavLink>

                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.reports.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.reports.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={bulletMainMenu}
                        alt="בולט"
                      />
                      <span>{PagesNames.reports.namePage}</span>
                    </NavLink>

                    {/* <NavLink
                      activeClassName={
                        currentPage === PagesNames.documents.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.documents.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={menuReportsIcon}
                        alt="בולט"
                      />
                      <span>{PagesNames.documents.namePage}</span>
                    </NavLink> */}

                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.match.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.match.url}
                    >
                      <img
                        className="bulletMainMenu"
                        style={{ marginLeft: "13px" }}
                        src={menuMatchIcon}
                        alt="בולט"
                      />
                      <span>{PagesNames.match.namePage}</span>
                    </NavLink>

                    {/* <NavLink 
                        activeClassName={currentPage === PagesNames.finance.method ? 'is-active' : ''}
                        role="menuitem" onClick={() => scroll.scrollTo(0)}
                        to={PagesNames.finance.url} >
                          <img style={{marginLeft : '13px'}} className='bulletMainMenu' src={bulletMainMenu} alt='בולט' />
                          <span>{PagesNames.finance.namePage}</span>
                    </NavLink> */}

                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.tasks.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.tasks.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={bulletMainMenu}
                        alt="בולט"
                      />
                      <span>{PagesNames.tasks.namePage}</span>
                    </NavLink>

                    {user.userCredential === "superAdmin" ||
                    user.userCredential === "admin" ? (
                      <NavLink
                        activeClassName={
                          currentPage === PagesNames.users.method
                            ? "is-active"
                            : ""
                        }
                        role="menuitem"
                        onClick={() => scroll.scrollTo(0)}
                        to={PagesNames.users.url}
                      >
                        <img
                          className="bulletMainMenu"
                          src={bulletMainMenu}
                          alt="בולט"
                        />
                        <span>{PagesNames.users.namePage}</span>
                      </NavLink>
                    ) : (
                      false
                    )}

                    {user.userCredential === "superAdmin" ? (
                      <NavLink
                        activeClassName={
                          currentPage === PagesNames.config.method
                            ? "is-active"
                            : ""
                        }
                        role="menuitem"
                        onClick={() => scroll.scrollTo(0)}
                        to={PagesNames.config.url}
                      >
                        <img
                          className="bulletMainMenu"
                          src={menuConfigIcon}
                          alt="בולט"
                        />
                        <span>{PagesNames.config.namePage}</span>
                      </NavLink>
                    ) : (
                      false
                    )}

                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                      <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}
                  </div>
                ) : (
                  false
                )}

                <NavLink
                  activeClassName={
                    currentPage === PagesNames.homeVisits.method
                      ? "is-active"
                      : ""
                  }
                  role="menuitem"
                  onClick={() => scroll.scrollTo(0)}
                  to={PagesNames.homeVisits.url}
                >
                  <img
                    style={{ marginLeft: "13px" }}
                    className="bulletMainMenu"
                    src={bulletMainMenu}
                    alt="בולט"
                  />
                  <span>{PagesNames.homeVisits.namePage}</span>
                </NavLink>
                {/* {user.userCredential === "socialWorker" && (
                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.homeVisits.method
                        ? "is-active"
                        : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.homeVisits1.url}
                  >
                    <img
                      style={{ marginLeft: "13px" }}
                      className="bulletMainMenu"
                      src={addUser}
                      alt="בולט"
                    />
                    <span>הוספת ביקור חדש</span>
                  </NavLink>
                )} */}
              </Nav>
            </Navbar.Collapse>

            {localStorage.getItem("user-info").length > 0 ? (
              <Button
                className="logOutBtn"
                onClick={logout}
                variant="secondary"
                size="sm"
              >
                <i className="fas fa-sign-out-alt" />
              </Button>
            ) : (
              ""
            )}
          </Navbar>
        </div>
      </div>
    );
  }
}
