import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import getDataFromApi from '../../../DinamicForm/newApi/getDataFromApi';
import { toast } from 'react-toastify' //npm i react-toastify

import loader from "../../../../img/preLoader.gif"

export default function ExtensionWorkPermit(props) {

    const { clientId } = props

    const [loading, setLoading] = useState(false);
    //const [responseData, setResponseData] = useState(false);


    const handleClick = () => { 

        //console.log('handleClick');
        const send = {

            clientId: clientId,
           
        }

        console.log(send);
        sendtoApi('downloadExtensionWorkPermit', 'HomeVisits', send, setLoading);

    }


  return (
    <div className='extensionWorkPermit'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        <Button
            className="addBtn changeBtn"
            onClick={ handleClick }
            variant="secondary"
            size="sm"
            >
            הורדת קובץ להארכת רשיון עבודה לעובד זר
            <i className="fas fa-download" />
            
        </Button>

    </div>
  )
}


function sendtoApi(url, controller, objectToSend, setLoading ) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {
        
        setLoading(false);

        if (getData?.ok) {

            window.open(getData.ok, '_blank')
            //toast.success(getData.ok);

          
        } else if (getData?.error) {

            toast.error(getData.error);

        } else if (getData?.warning) {


            toast.warning(getData.warning);

        }  else {
            toast.error('שגיאה');
        }

    })

    getData.catch((error) => {
        setLoading(false);
        console.log(error)
    })

    /* getData.finally(() => {
        //scroll.scrollToBottom();
        setLoading(false)
    }) */
}