import React, { Fragment } from "react";
import { useState } from "react";
import { find, filter, isEmpty } from "lodash";

import Form from "react-bootstrap/Form";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useEffect } from "react";
import getDataFromApi from "../../../DinamicForm/getDataFromApi";
import { toast } from "react-toastify";
import loader from "../../../../img/preLoader.gif";

export default function DigFormsPDFMail(props) {

  const { options, id, is_worker } = props;

  const [sendList, setSendList] = useState([]);
  const [mailAdress, setMailAdress] = useState("");
  const [loading, setLoading] = useState(false);

  const [areErrors, setAreErrors] = useState(true);

  //console.log('sendList', sendList);
  //console.log('areErrors', areErrors);

  const sendMail = () => {
    const send = {
      id: id,
      is_worker: is_worker,
      sendList: sendList,
      mailAdress: mailAdress,
    };

    //console.log(send);
    sendtoApi("getMultiPdfMail", "DigformsPDF", send, setLoading);

    //console.log('sendMail', sendMail);
  };

  useEffect(() => {
    if (!mailAdress || !mailAdress.includes("@") || isEmpty(sendList)) {
      setAreErrors(true);
    } else {
      setAreErrors(false);
    }
  }, [mailAdress, sendList]);

  return (
    <div className="digFormsPDFMail">
      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      <h3>שליחה מרובה של מסמכים במייל:</h3>

      <div className="checkBoxCont">
        <InputGroup className="customInput">
          <InputGroup.Prepend className="inputPrependLabel">
            <InputGroup.Text id="basic-addon3">כתובת מייל</InputGroup.Text>
          </InputGroup.Prepend>

          <FormControl
            isInvalid={!mailAdress || !mailAdress.includes("@")}
            className="inputPrepend"
            onChange={(event) => setMailAdress(event.target.value)}
            value={mailAdress}
          />
        </InputGroup>

        {!is_worker && <Fragment><h4 className="listName">היתר העסקה</h4>
        <ShowOption group='1' options = {options} setSendList = {setSendList} sendList={sendList}/>
        </Fragment> }

        <h4 className="listName">אשרות עבודה</h4>
        <ShowOption group='2' options = {options} setSendList = {setSendList} sendList={sendList}/>

        <h4 className="listName">רישום לתאגיד</h4>
        <ShowOption group='3' options = {options} setSendList = {setSendList} sendList={sendList}/>

        {!is_worker && <Fragment><h4 className="listName">הזמנת עו"ז מחו"ל</h4>
        <ShowOption group='4' options = {options} setSendList = {setSendList} sendList={sendList}/>
        </Fragment> }

        {!is_worker && <Fragment><h4 className="listName">ועדה הומניטרית</h4>
        <ShowOption group='5' options = {options} setSendList = {setSendList} sendList={sendList}/>
        </Fragment> }

        <div className="btnCont">
          <Button
            onClick={sendMail}
            className={`${
              areErrors ? "" : "animate__animated animate__bounceIn"
            }`}
            disabled={areErrors}
            variant={`${areErrors ? "secondary" : "primary"}`}
            size=""
          >
            <span>שליחה</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

function ShowOption(props) {

  const { group, options,setSendList,sendList } = props

  return (<ul>
    {options.map((item) => {
      if (item.name !== "בחר טופס" && item?.group === group ) {
        return (
          <Item key={item.formCode} item={item} setSendList={setSendList} sendList={sendList} />
        );
      } else return false;
    })}
  </ul>);

}


function Item(props) {
  const { sendList, item, setSendList } = props;

  const itemExist = find(sendList, { formCode: item.formCode });

  const handler = (item) => {
    if (!itemExist) {
      let items = sendList;
      setSendList(items.concat(item));
    } else {
      const filterList = filter(sendList, function (sendList) {
        return sendList.formCode !== item.formCode;
      });

      //console.log('filterList', filterList);

      setSendList(filterList);
    }
  };

  const onChange = () => {};

  return (
    <li key={item.formCode} onClick={() => handler(item)}>
      <Form.Check
        onChange={onChange}
        checked={itemExist ? true : false}
        value={item.name}
        type="checkbox"
        id={item.formCode}
        label={item.name}
      />
    </li>
  );
}

function sendtoApi(url, controller, objectToSend, setLoading) {
  setLoading(true);

  //console.log(url);

  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    if (getData.error) {
      toast.error(getData.error, {
        toastId: "responseError",
      });
    }
    if (getData.ok) {
      toast.success("נשלח בהצלחה");
      console.log("getData", getData);

      /* window.open(
              RestUrls.baseApiUrl1 + "wsign/complete_createPdf.php" + getData.url,
              "_blank"
            ); */
    } else {
      toast.error("שגיאה", {
        toastId: "genericError",
      });
    }
  });

  getData.catch((error) => {
    console.log(error);
  });

  getData.finally(() => {
    setLoading(false);
  });
}
