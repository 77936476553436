import React, { Fragment, useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";

import { toast } from "react-toastify"; //npm i react-toastify
import getDataFromApi from "../../../DinamicForm/newApi/getDataFromApi";
import loader from "../../../../img/preLoader.gif";

import { RestUrls } from "../../../Urls";
import DigFormsPDFMail from "./DigFormsPDFMail";

import {
  optionsClients,
  optionsWorkers,
  optionsDigitalWorkers,
  optionsDigitalClients,
} from "./constantsDigForms";

import { Button, Modal } from "react-bootstrap";
import MoreDataPdf from "./pdf4Sign/MoreDataPdf";

import { map } from "lodash"; //https://lodash.com/docs/4.17.15#map  //find

export default function DigformsPDF(props) {
  const { clientId, workerId, showSendOnlyMails, reloadDocuments } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let options = [];
  let optionsDigital = [];

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(false);

  const [formData, setFormData] = useState({
    formCode: "בחר טופס",
  });

  const onChange = (e) => {
    setResponseData(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //  console.log(clientId, "client Id");

  if (clientId) {
    options = optionsClients;
    optionsDigital = optionsDigitalClients;
  } else {
    options = optionsWorkers;
    optionsDigital = optionsDigitalWorkers;
  }

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log("FORM CODEE", formData.formCode);
    //console.log('change', options[0].formCode);

    if (
      formData.formCode !== options[0].formCode &&
      !loading &&
      !responseData
    ) {
      let isForSign = "0";
      let formCode = formData.formCode;

      if (formData.formCode.includes("SIGN-")) {
        isForSign = "1";
        formCode = formData.formCode.replace("SIGN-", "");
      }

      if (formData.formCode === "renewVisaDownloadOnly") {
        window.open(
          RestUrls.baseApiUrl1 +
            "wsign/complete_forms/templates/renewVisaDownloadOnly.pdf",
          "_blank"
        );
        setFormData({ formCode: "בחר טופס" });
        return;
      } else if (formData.formCode === "obligatory_deduction_cDownloadOnly") {
        window.open(
          RestUrls.baseApiUrl1 + "wsign/complete_forms/templates/c.pdf",
          "_blank"
        );
        setFormData({ formCode: "בחר טופס" });
        return;
      } else if (formData.formCode === "c-2_cDownloadOnly") {
        window.open(
          RestUrls.baseApiUrl1 + "wsign/complete_forms/templates/c-2.pdf",
          "_blank"
        );
        setFormData({ formCode: "בחר טופס" });
        return;
      }

      const send = {
        id: clientId ? clientId : workerId,
        is_worker: workerId ? true : false,
        formCode: formCode,
        isForSign: isForSign,
      };

      if (isMoreDataForm(formData.formCode)) {
        setFormData({ formCode: "בחר טופס" }); //reset selectBox
        setShowModal({
          formCode: formData.formCode,
          sendFormMoreDadaPDF: sendFormMoreDadaPDF,
          send: send,
        });
        //toast.warning('POP UP');
      } else {
        //console.log('Sending...');

        //console.log('Send',send);

        if (send?.formCode === "בחר טופס") {
          //setFormData({formCode: "בחר טופס"});
          return;
        }

        sendtoApi(
          "getPdflink",
          "DigformsPDF",
          send,
          setLoading,
          setResponseData,
          setFormData,
          reloadDocuments
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, clientId, workerId, options, responseData, loading]);

  const sendFormMoreDadaPDF = (moreData) => {
    let isForSign = "0";
    let formCode = formData.formCode;

    if (formData.formCode.includes("SIGN-")) {
      isForSign = "1";
      formCode = formData.formCode.replace("SIGN-", "");
    }

    const send = {
      id: clientId ? clientId : workerId,
      is_worker: workerId ? true : false,
      formCode: formCode,
      isForSign: isForSign,
      moreData: moreData,
    };

    //console.log('send', send);
    setShowModal(false);

    sendtoApi(
      "getPdflink",
      "DigformsPDF",
      send,
      setLoading,
      setResponseData,
      setFormData,
      reloadDocuments
    );
  };

  return (
    <div className="contSelect" style={{ padding: "40px 20%" }}>
      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      {/* <h2 className='blueH2'>בחר</h2> */}
      {!showSendOnlyMails && (
        <InputGroup className="customInput">
          <InputGroup.Prepend className="inputPrependLabel ">
            <InputGroup.Text>בחרו פעולה</InputGroup.Text>
          </InputGroup.Prepend>

          <FormControl
            as="select"
            /* 'inputPrepend customSelect' */
            className="inputPrepend customSelect"
            onChange={onChange}
            value={formData.formCode}
            name="formCode"
          >
            <option value="בחר טופס">בחר טופס</option>
            {optionsDigital && optionsDigital[0] && (
              <Fragment>
                {clientId && (
                  <optgroup label="היתר העסקה">
                    {getOptionsGroup(optionsDigital, "1")}
                  </optgroup>
                )}

                <optgroup label="אשרות עבודה">
                  {getOptionsGroup(optionsDigital, "2")}
                </optgroup>

                <optgroup label="רישום לתאגיד">
                  {getOptionsGroup(optionsDigital, "3")}
                </optgroup>

                {clientId && (
                  <optgroup label='הזמנת עו"ז מחו"ל'>
                    {getOptionsGroup(optionsDigital, "4")}
                  </optgroup>
                )}

                {clientId && (
                  <optgroup label="ועדה הומניטרית">
                    {getOptionsGroup(optionsDigital, "5")}
                  </optgroup>
                )}
              </Fragment>
            )}

            {/* <optgroup label="טפסים להדפסה">
            {options.map((item) => {
              if (true) {
                return (
                  <option value={item.formCode} key={item.formCode}>
                    {item.name}
                  </option>
                );
              } else return false;
            })}
          </optgroup> */}
          </FormControl>
        </InputGroup>
      )}

      {showSendOnlyMails && (
        <Fragment>
          <InputGroup className="customInput">
            <InputGroup.Prepend className="inputPrependLabel ">
              <InputGroup.Text>בחירת טופס להדפסה</InputGroup.Text>
            </InputGroup.Prepend>

            <FormControl
              as="select"
              /* 'inputPrepend customSelect' */
              className="inputPrepend customSelect"
              onChange={onChange}
              value={formData.formCode}
              name="formCode"
            >
              <option value="בחר טופס">בחר טופס</option>

              {clientId && (
                <optgroup label="היתר העסקה">
                  {getOptionsGroup(options, "1")}
                </optgroup>
              )}

              <optgroup label="אשרות עבודה">
                {getOptionsGroup(options, "2")}
              </optgroup>

              <optgroup label="רישום לתאגיד">
                {getOptionsGroup(options, "3")}
              </optgroup>

              {clientId && (
                <optgroup label='הזמנת עו"ז מחו"ל'>
                  {getOptionsGroup(options, "4")}
                </optgroup>
              )}

              {clientId && (
                <optgroup label="ועדה הומניטרית">
                  {getOptionsGroup(options, "5")}
                </optgroup>
              )}
            </FormControl>
          </InputGroup>

          <DigFormsPDFMail
            options={options}
            id={clientId ? clientId : workerId}
            is_worker={workerId ? true : false}
          />
        </Fragment>
      )}

      <Modal
        className="animate__animated animate__fadeInDown animate__faster "
        animation={false}
        onHide={() => setShowModal(false)}
        show={showModal ? true : false}
        size="lg"
        //dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <span style={{ width: "100%", textAlign: "center" }}>
              טפסים דיגיטלים
            </span>
          </Modal.Title>
          <Button onClick={() => setShowModal(false)} variant="secondary">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <MoreDataPdf showModal={showModal} setShowModal={setShowModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

function getOptionsGroup(optionsDigital, group) {
  //const { optionsDigital } = props
  //console.log('optionsDigital',optionsDigital);

  if (!optionsDigital || !group) {
    return false;
  }

  let options = [];

  map(optionsDigital, (item) => {
    if (item?.group === group) {
      options.push(
        <option value={item.formCode} key={item.formCode}>
          {item.name}
        </option>
      );
    }
  });

  //console.log('options', options);
  return options;
}

function sendtoApi(
  url,
  controller,
  objectToSend,
  setLoading,
  setResponseData,
  setFormData,
  reloadDocuments = false
) {
  setLoading(true);

  //console.log(url);

  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    if (getData.error) {
      toast.error(getData.error, {
        toastId: "responseError",
      });
      setFormData({
        formCode: "בחר טופס",
      });
      return;
    }
    if (getData.ok && getData.url) {
      if (objectToSend.isForSign === "1") {
        toast.success("המסמך מוכן לחתימה דיגיטלית", {
          toastId: "customId",
        });

        if (reloadDocuments) {
          reloadDocuments();
        }

        setFormData({ formCode: "בחר טופס" });
      } else {
        //toast.success(getData.ok);
        window.open(
          RestUrls.baseApiUrl1 + "wsign/complete_createPdf.php" + getData.url,
          "_blank"
        );
        setFormData({
          formCode: "בחר טופס",
        });
      }
    } else {
      toast.error("שגיאה", {
        toastId: "genericError",
      });
    }
  });

  getData.catch((error) => {
    console.log(error);
  });

  getData.finally(() => {
    setLoading(false);
    setResponseData(true);
  });
}

function isMoreDataForm(formCode) {
  const formCodeSwitch = formCode.replace("SIGN-", "");

  switch (formCodeSwitch) {
    case "yeter2": //"נספח ד להיתר העסקה"
      return true;

    case "holidayPermit": //"נספח ד להיתר העסקה"
      return true;

    case "annexA": //"נספח ד להיתר העסקה"
      return true;

    case "enter2": //"נספח ד להיתר העסקה"
      return true;

    case "commitment": //"נספח ד להיתר העסקה"
      return true;

    case "patientEmploymentA": //"נספח ד להיתר העסקה"
      return true;

    case "patientEmploymentA_UZB": //"נספח ד להיתר העסקה"
      return true;

    case "familyEmploymentB": //"נספח ד להיתר העסקה"
      return true;

    case "locNew": //"נספח ד להיתר העסקה"
      return true;

    case "patientAgreement": //"נספח ד להיתר העסקה"
      return true;

    case "rishumLishka": //"נספח ד להיתר העסקה"
      return true;

    case "placement": //"נספח ד להיתר העסקה"
      return true;

    case "placement_ru": //"נספח ד להיתר העסקה"
      return true;

    case "registration2": //"נספח ד להיתר העסקה"
      return true;

    case "clientReport": //"נספח ד להיתר העסקה"
      return true;

    case "anotherFirm": //"נספח ד להיתר העסקה"
      return true;

    case "anotherFirm2": //"נספח ד להיתר העסקה"
      return true;

    case "humanitarit":
      return true;

    case "socialOpinion":
      return true;

    case "specialVisaWork":
      return true;

    case "specialVisaWorkRuss":
      return true;

    case "waiverOfConfidentialityC":
      return true;

    case "waiverOfConfidentialityC_A":
      return true;

    case "reportSpecialCase":
      return true;

    case "familyPermit":
      return true;

    default:
      return false;
  }
}
