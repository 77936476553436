import React, { useEffect, useState } from 'react'

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function FamilyEmploymentB(props) {

    const { setMoreDataPdf, sendForm } = props

   /*  let userJ = JSON.parse(localStorage.getItem('user-info'));
    let user = userJ.data;
    //console.log(user); */

    const inputsBuilder = [
        
        
        {
            'name' : 'nameRuss',
            'label' : 'שם המעסיק',
            'defaultValue': '',
            
        },

        {
            'name' : 'addressRuss',
            'label' : 'כתובת',
            'defaultValue': '',
            
        },

        {
            'name' : 'citizenshipRuss',
            'label' : 'אזרחות',
            'defaultValue': '',
            
        },

        {
            'name' : 'nameWorkerRuss',
            'label' : 'שם העובד',
            'defaultValue': '',
            
        },

        {
            'name' : 'addressEng',
            'label' : 'כתובת',
            'defaultValue': '',
            
        },

        {
            'name' : 'citizenshipEng',
            'label' : 'אזרחות',
            'defaultValue': '',
            
        },

        {
            'name' : 'dateStart',
            'label' : 'תאריך תחילת העסקה',
            'defaultValue': '',
            
        },

        {
            'name' : 'dateEnd',
            'label' : 'תאריך סיום העסקה',
            'defaultValue': '',
            
        },

        {
            'name' : 'payMoney',
            'label' : 'שכר ברוטו',
            'defaultValue': '',
            
        },

        {
            'name' : 'nameEng',
            'label' : 'שם המעסיק',
            'defaultValue': '',
            
        },
        
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);

  return (
    <div>

        {/* <h3>פרטים ברוסית</h3> */}
      {/*   <Grid className='line' container spacing={2}  alignItems="center" direction="row" >
            
            <Grid item md={6} >

                <header>
                    <h4>{inputsBuilder[0].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[0].name}
                    value={formData[inputsBuilder[0].name]}
                />
            </Grid>

            <Grid item md={6} >

                <header>
                    <h4>{inputsBuilder[1].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[1].name}
                    value={formData[inputsBuilder[1].name]}
                />
            </Grid>

            <Grid item md={6} >

                <header>
                    <h4>{inputsBuilder[2].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[2].name}
                    value={formData[inputsBuilder[2].name]}
                />
            </Grid>

            <Grid item md={6} >

                <header>
                    <h4>{inputsBuilder[3].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[3].name}
                    value={formData[inputsBuilder[3].name]}
                />
            </Grid>

            


        </Grid> */}
        <h3 style={{margin: '0px 0 10px 0'}} >פרטים באנגלית</h3>
        
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[9].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[9].name}
                    value={formData[inputsBuilder[9].name]}
                />
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[4].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[4].name}
                    value={formData[inputsBuilder[4].name]}
                />
            </Grid>

            {/* <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[5].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[5].name}
                    value={formData[inputsBuilder[5].name]}
                />
            </Grid> */}
            
        </Grid>

        <h3 style={{margin: '30px 0 10px 0'}}>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[6].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[6].name}
                    value={formData[inputsBuilder[6].name]}
                />
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[7].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[7].name}
                    value={formData[inputsBuilder[7].name]}
                />
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[8].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[8].name}
                    value={formData[inputsBuilder[8].name]}
                />
            </Grid>

        </Grid>

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = item.defaultValue
        
    })
    
    return objReturn;

}