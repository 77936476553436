import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { RestUrls } from "../Urls";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
registerLocale("he", he);

export default function ModalStatus(props) {
  const { setShowModal, showModal, newStatusValue, placementData } = props;
  const [isLoading, setIsLoading] = useState(false);

  //console.log('props', props);

  const uptateInputFieldS = async () => {
    let id = newStatusValue.inputIdParam;
    console.log(newStatusValue, "within func");
    const API = RestUrls.baseApiUrl + props.methodName + props.updateGetAction;
    let user = props.user;
    let userPass = {
      userId: user.id,
      userName: user.username,
      userCredential: user.userCredential,
      token: user.token,
      siteCode: RestUrls.Code,
      inputId: id,
      inputName: newStatusValue.inputName,
      inputValue: newStatusValue.inputValue,
      formType: props.data.type,
      tableUpdate: props.tableUpdate ? props.tableUpdate : "",
    };

    const res = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(userPass),
    });
    const dataResponse = await res.json();
    console.log(dataResponse);
    if (dataResponse.data === "OK") {
      window.location.reload();
      setIsLoading(false);
    }
    if (dataResponse === "unauthorized") {
      localStorage.setItem("user-info", "");
      window.location.reload();
      setIsLoading(false);
    }
  };

  if( !placementData?.data?.ForeignWorkerName ) {

    return (
      <Modal
        className="newTaskModal ModalChangeHistory animate__animated animate__fadeInDown animate__faster "
        animation={false}
        onHide={() => setShowModal(false)}
        show={showModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <span style={{ width: "100%", textAlign: "center" }}>
              {" "}
              האם אתה בטוח שברצונך
              <br />
              לשנות את סטטוס הלקוח?
            </span>
          </Modal.Title>
          <Button onClick={() => setShowModal(false)} variant="secondary">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="inputsCont"></div>
  
          <div className="btnContt">
            <Button
              onClick={async () => {
                setIsLoading(true);
                uptateInputFieldS();
                // setChangeStatus(true);
                setShowModal(false);
              }}
              disabled={isLoading}
              size=""
              variant="success"
            >
              {isLoading ? "טוען…" : "כן"}
            </Button>
            <Button
              onClick={() => setShowModal(false)}
              disabled={isLoading}
              size=""
              variant="danger"
            >
              {isLoading ? "טוען…" : "לא"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );


  } else {

    return (
      <Modal
        className="newTaskModal ModalChangeHistory animate__animated animate__fadeInDown animate__faster "
        animation={false}
        onHide={() => setShowModal(false)}
        show={showModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <span style={{ width: "100%", textAlign: "center" }}>יש לסגור את השיבוץ</span>
          </Modal.Title>
          <Button onClick={() => setShowModal(false)} variant="secondary">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div sryle = {{ textAlign : 'center' }} >
            <Button
              onClick={() => setShowModal(false)}
              disabled={isLoading}
              size=""
              variant="danger"
            >אישור</Button>
          </div>
        </Modal.Body>
      </Modal>
    );

    

  }

  
}
