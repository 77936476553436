import React, { Component } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { FormControl } from 'react-bootstrap';

import { RestUrls } from "./../../../Urls"

// https://www.npmjs.com/package/react-search-autocomplete

export default class SearchAutocomplete4Workers extends Component {
    


    constructor(props) {

        super(props);

        this.state = {
            searchItems : []
        };

    }


    /* handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
      }*/

      handleOnSelect = (item) => {
        // the item selected
        //console.log(item);
        this.props.submit(item);
      } 
    
      handleOnFocus = () => {
          this.loadData(this.props.DBItems);
      }

    async loadData (url) {

        // 'uploadFiles?getFiles=1'

        const API =  RestUrls.baseApiUrl + url;

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            //console.log(data);
            this.setState({ searchItems : data.searchDataMenu });
        }
        
      
    }

    render() {

      if(this.props.disabled) {

        return (

          <FormControl
              className='inputPrepend selectPrepend'
              placeholder={this.props.placeholder}
              disabled={true}
            />

            
        )


      } else {

        return (
            <ReactSearchAutocomplete
                    className="form-control"
                    fuseOptions={{ keys: this.props.keys }}
                    /* fuseOptions={{ keys: ["name", "description"] }} */
                    placeholder = {this.props.placeholder}
                    items={this.state.searchItems}
                    onFocus={this.handleOnFocus}
                    onSelect={this.handleOnSelect}
                    /* onSearch={handleOnSearch}
                    autoFocus */
            />
        )

      }
    }
}
