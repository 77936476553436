/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { getAllUrlParams } from "./../../../UrlParameters";

import { InputGroup, FormControl } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";

import convertDate from "./../../../convertDateToDisplay";

import "./HomeVisitsNewVisit.css";

import ReportHome1 from "./ReportHome1";
import HomeVisitsPrint from "./HomeVisitsPrint";

//import ReportHomeClali from './ReportHomeClali'

import SearchAutocompleteAndDbFocusClients from "./SearchAutocompleteAndDbFocusClients";

import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";
import ReportBeforeAsama from "./ReportBeforeAsama";

export default function HomeVisitsNewVisit(props) {
  //console.log(props);

  registerLocale("he", he);

  let urlParams = localStorage.getItem("currentPathSearch")
    ? localStorage.getItem("currentPathSearch")
    : false;
  let urlQuery = urlParams ? getAllUrlParams(urlParams) : false;
  let today = new Date();

  let userJ = JSON.parse(localStorage.getItem("user-info"));
  let user = userJ.data;
  const [clientId, setClientId] = useState(
    urlQuery?.clientid ? urlQuery.clientid : null
  );
  const [isNewVisit, setIsNewVisit] = React.useState(false);
  const [visitType, setVisitType] = useState("");

  React.useEffect(() => {
    let params = localStorage.getItem("currentPathSearch")
      ? localStorage.getItem("currentPathSearch")
      : false;
    let queryParams = params ? getAllUrlParams(params) : false;
    if (queryParams) {
      setVisitType(
        queryParams?.reporthome1 === "2"
          ? "ביקור לפני השמה"
          : queryParams?.reporthome1 == "1"
          ? "ביקור חצי שנתי"
          : ""
      );
      setIsNewVisit(queryParams?.reporthome1 == "1");
    }
  }, []);

  console.log("KK", visitType);
  const [visitDate, setVisitDate] = useState(
    convertDate2(new Date(today.toString().replace(/-/g, "/")))
  );

  const [newVisitStep2Data, setNewVisitStep2Data] = useState(false);

  const [chosensocialWorker, setChosensocialWorker] = useState(
    user.userCredential === "socialWorker" ? user.id : ""
  );
  useEffect(() => {
    return () => {
      // window.location.reload(false);
      setChosensocialWorker("");
      setNewVisitStep2Data(false);
      setVisitType("");
      setClientId(null);
    };
  }, []);

  //useEffect(() => {}, []);
  //console.log(clientId);

  const handleSearchSelected = (item) => {
    setClientId(item.id);
    window.history.pushState(
      "ביקור בית",
      "הוספת ביקור בית",
      "?clientId=" + item.id
    );
  };

  //For datePicker
  const handleChangeDate = (date, nameInput) => {
    const newDate = convertDate2(date);
    setVisitDate(newDate);
  };

  // INPUT
  const handleChange = (event) => {
    //console.log(event.target.name);

    if (event.target.name === "visitType") {
      setVisitType(event.target.value);
    } else if (event.target.name === "chosenSocialWorker") {
      setChosensocialWorker(event.target.value);
    }
  };

  const choises = [
    {
      value: "ביקור לפני השמה",
      show: "ביקור לפני השמה",
    },
    {
      value: "ביקור אחרי השמה (30 יום אחרי)",
      show: "ביקור אחרי השמה (30 יום אחרי)",
    },
    {
      value: "ביקור חצי שנתי",
      show: "לאחר השמה/שוטף",
    },
    /* {
            value: 'אחר',
            show: 'אחר'
        } */
  ];

  const urlParamsPrint = getAllUrlParams(window.location.search);
  if (urlParamsPrint && urlParamsPrint.print) {
    return <HomeVisitsPrint rowId={urlParamsPrint.print} />;
  }

  return (
    <div className="homeVisitsPage HomeVisitsNewVisit">
      <section className="headerSec">
        <NavLink
          className="bounce"
          onClick={() => scroll.scrollTo(0)}
          to={clientId ? `/ביקורי_בית?id=${clientId}` : "/ביקורי_בית"}
        >
          <button className="goBackCustomBtn bounce">« חזור</button>
        </NavLink>

        <h2 className="titStyle">הוספת ביקור בית חדש</h2>
      </section>

      <div className="insertNewVisit">
        <div className="row justify-content-center selectorContent">
          {!clientId && (
            <div className="col-lg-6 text-center selectClientsWorkers">
              <div className="row formContainer">
                <div className="col-md-12 divBoostrap col-sm-12">
                  <div className="SearchAutocomplete" style={{ width: "100%" }}>
                    {user.userCredential !== "socialWorker" ||
                    (user.userCredential === "socialWorker" &&
                      parseInt(props.pageData.countClients) >= 1) ? (
                      <SearchAutocompleteAndDbFocusClients
                        keys={["name"]}
                        coordinatorId={user.id}
                        placeholder="חיפוש לקוחות"
                        submit={handleSearchSelected}
                        DBItems={"homeVisits/getclients?searchData=1"}
                      />
                    ) : (
                      <h2 style={{ color: "red" }}>אין לקוחות משוייכים</h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {clientId && !newVisitStep2Data && (
            <div className="col-lg-12 text-center selectClientsWorkers">
              <div className="row formContainer">
                <div
                  className={
                    !user.userCredential !== "socialWorker"
                      ? ""
                      : "col-md-4 divBoostrap col-sm-12"
                  }
                >
                  {user.userCredential !== "socialWorker" ? (
                    <div className="col-md-12 divBoostrap col-sm-12">
                      <InputGroup className="customInput customSelect">
                        <InputGroup.Prepend className="inputPrependLabel ">
                          <InputGroup.Text>שיוך לעובד סוציאלי</InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                          as="select"
                          className="inputPrepend customSelect"
                          onChange={handleChange}
                          name="chosenSocialWorker"
                          value={chosensocialWorker}
                        >
                          <option value="">בחר...</option>

                          {props.pageData.socialWorkersList.map((item) => {
                            if (true) {
                              /* currentState={this.state} */
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            } else return false;
                          })}
                        </FormControl>
                      </InputGroup>
                    </div>
                  ) : (
                    false
                  )}
                </div>

                <div
                  className={
                    clientId && !newVisitStep2Data
                      ? "col-md-6 divBoostrap col-sm-12"
                      : "col-md-4 divBoostrap col-sm-12"
                  }
                >
                  <InputGroup className="customInput dateInput">
                    <div className="dateTimeCont">
                      <div className="customPrepend">תאריך</div>

                      <DatePicker
                        showYearDropdown={true}
                        selected={
                          new Date(visitDate.toString().replace(/-/g, "/"))
                        }
                        dateFormat="dd/MM/yy"
                        className="inputPrepend form-control"
                        name="created_at"
                        locale="he"
                        onChange={(date) =>
                          handleChangeDate(date, "created_at")
                        }
                      />
                    </div>
                  </InputGroup>
                </div>

                <div
                  className={
                    clientId && !newVisitStep2Data
                      ? "col-md-6 divBoostrap col-sm-12"
                      : "col-md-4 divBoostrap col-sm-12"
                  }
                >
                  <InputGroup className="customInput customSelect">
                    <InputGroup.Prepend className="inputPrependLabel ">
                      <InputGroup.Text>{"סוג ביקור"}</InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      as="select"
                      className="inputPrepend customSelect"
                      onChange={handleChange}
                      name={"visitType"}
                      value={visitType}
                      disabled={urlQuery?.reporthome1 === "2"}
                    >
                      <option value="">בחר סוג ביקור</option>
                      {choises.map((item) => {
                        if (true) {
                          /* currentState={this.state} */
                          return (
                            <option value={item.value} key={item.value}>
                              {item.show}
                            </option>
                          );
                        } else return false;
                      })}
                    </FormControl>
                  </InputGroup>
                </div>

                {/* <div className="clear">
                                <Button className="sendForm" onClick={()=>console.log('sending')} size="lg" variant="success" >הוספה</Button>
                            </div> */}
              </div>
            </div>
          )}

          {newVisitStep2Data && (
            <div className="dataInfo">
              {console.log(newVisitStep2Data)}
              <h3>{newVisitStep2Data.fullNameClient} </h3>
              <p>
                {newVisitStep2Data.visitType} -{" "}
                {convertDate(newVisitStep2Data.visitDate)}{" "}
              </p>
            </div>
          )}
        </div>

        {visitType && (
          <div className="forms">
            {clientId &&
              chosensocialWorker &&
              (visitType === "ביקור חצי שנתי" ||
                visitType === "ביקור אחרי השמה (30 יום אחרי)" ||
                isNewVisit) && (
                <ReportHome1
                  visitType={visitType}
                  clientId={clientId}
                  visitDate={visitDate}
                  chosensocialWorker={chosensocialWorker}
                  savedData={false} // FOR RETRIEVE DATA
                  setNewVisitStep2Data={setNewVisitStep2Data}
                />
              )}
          </div>
        )}

        {visitType && (
          <div className="forms">
            {clientId &&
              chosensocialWorker &&
              visitType === "ביקור לפני השמה" && (
                <ReportBeforeAsama
                  savedData={false}
                  clientId={clientId}
                  visitDate={visitDate}
                  socialWorkersData={props?.pageData?.socialWorkersList}
                  chosensocialWorker={chosensocialWorker}
                />
              )}
          </div>
        )}

        {/* {visitType && <div className='forms'>

                {clientId &&
                    chosensocialWorker &&
                    (
                        visitType !== 'ביקור חצי שנתי' &&
                        visitType !== 'ביקור אחרי השמה (30 יום אחרי)'
                    )
                    && < ReportHomeClali
                        seniorId={clientId}
                        visitType={visitType}
                        clientId={clientId}
                        visitDate={visitDate}
                        chosensocialWorker={chosensocialWorker}

                    />}

            </div>} */}
      </div>
    </div>
  );
}

function convertDate2(dateValue) {
  let current_datetime = new Date(dateValue.toString().replace(/-/g, "/")); // IPHONE FIX

  /* 2021-03-25 */

  let formatted_date =
    current_datetime.getFullYear() +
    "-" +
    (current_datetime.getMonth() + 1) +
    "-" +
    current_datetime.getDate();

  return formatted_date;
}
