import React, { Fragment, useState } from 'react'
import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi from '../../../DinamicForm/newApi/getDataFromApi'
import loader from './../../../../img/preLoader.gif'

import { Button } from 'react-bootstrap';

export default function KevaList(props) {

    const { kevaPays, reloadInvoices } = props;

    //console.log(kevaPays);

    const [showSureDelete, setShowSureDelete] = useState(false)

    const [loading, setLoading] = useState(false);


    const areUsure = () => {

        setShowSureDelete(true);
    }


    const removeKeva = () => {


        //setShowSureDelete(true);
        
        sendtoApi('removeKeva', 'Pays', {kevaPayId : kevaPays.id}, setLoading, setShowSureDelete, reloadInvoices )
    }


    const repeatKevaPay = () => {

      console.log('sending....');
      sendtoApi('repeatKevaPay', 'Pays', {kevaPayId : kevaPays.id}, setLoading,  setShowSureDelete, reloadInvoices )

     }

  return (
    <>
        <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />

        <ul className="invoicesList">

            {kevaPays.is_kevaPayBank === '0' && <>
              <li><strong>תשלום האחרון</strong> <span className={kevaPays.notMonthpay ? 'payError' : ''} >{kevaPays.lastPayShow}</span></li>
              <li><strong>סכום</strong> <span>{kevaPays.cardCash} ש"ח</span></li>
            </> }

            { kevaPays.is_kevaPayBank === '1' && <>
              <li><strong>הוראת קבע בנקאית</strong></li>
              <li><strong>סכום</strong> <span>{parseFloat(kevaPays.superTotalInvoice).toFixed(2)} ש"ח</span></li>
            </> }


            <li> {!showSureDelete ? <Button onClick={ areUsure } variant="secondary" ><i className="far fa-trash-alt"></i> מחיקה הוראת קבע &raquo;</Button> : 
            
                <>
                    <strong style={{marginLeft : '10px'}} >בטוח למחוק?</strong>
                    <span style={{marginLeft : '10px'}} ><Button onClick={ removeKeva } variant="danger" ><i className="far fa-trash-alt"></i> כן למחוק</Button></span>
                    <span style={{marginLeft : '10px'}} ><Button onClick={ ()=> setShowSureDelete(false) } variant="secondary" >ביטול</Button></span>
                
                </>} </li>
            
        </ul>

        

        { (kevaPays.notMonthpay) && <ul style={{margin: '20px 0 0 0'}} className="invoicesList">
            
            {kevaPays?.lastErrorCardPay?.id && <Fragment>

              <li><strong>שגיאה אחרונה בה"ק: </strong> <span className={'payError'} >{ kevaPays?.lastErrorCardPay?.lastErrorDateShow }</span></li>
              <li>{ kevaPays?.lastErrorCardPay?.responseMessage }</li>

            </Fragment> }

            <li><Button onClick={ repeatKevaPay } variant="secondary" >ניסיון תשלום חוזר בה"ק &raquo;</Button></li>

        </ul>}

        
    </>
  )
}



function sendtoApi(url, controller, objectToSend, setLoading, setShowSureDelete, reloadInvoices) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

      if (getData.ok) {

        setShowSureDelete(false);
        toast.success(getData.ok);
        reloadInvoices();

      } else if (getData.error) {

        setShowSureDelete(false);
        toast.error(getData.error)
      }

    })

    getData.catch((error) => {
      console.log(error)
    })

    getData.finally(() => {
      setLoading(false)
    })
  }