export const optionsClients = [
  {
    formCode: "בחר טופס", //DONT MOVE!!!
    name: "בחר טופס",
  },

  {
    formCode: "waiverOfConfidentialityC_A", //1
    name: "ויתור סודיות",
    group: "1",
  },

  {
    formCode: "commitment", //2
    name: "כתב התחייבות מעסיק + לשכה",
    group: "4",
  },

  {
    formCode: "dependencyTest", // 3
    name: "בקשה למבחן תלות",
    group: "1",
  },

  {
    formCode: "yeter", //4
    name: "נספח ה'- קבלת היתר אשפוז",
    group: "1",
  },

  {
    formCode: "yeter2", //5
    name: "טופס ד' - קטין",
    group: "1",
  },

  {
    formCode: "kitzurYeter", //6
    name: "בקשה לקיצור היתר העסקה",
    group: "3",
  },

  {
    formCode: "yeter3", //7
    name: "נספח ו' טופס בקשה להארכת היתר נוסף",
    group: "1", //הזמנת עו"ז מחו"ל
  },

  {
    formCode: "holidayPermit", //8
    name: 'הסכמת מעסיק ליציאה לחופש של העו"ז',
    group: "2",
  },

  {
    formCode: "renewVisaDownloadOnly", //9
    name: 'נוהל חידוש ויזה לעו"ז',
    group: "2",
  },

  {
    formCode: "enter2", //13
    name: "טופס בקשה למתן אשרת כניסה לישראל",
    group: "4",
  },

  {
    formCode: "patientEmploymentA", //16
    name: 'חוזה עבודה מעסיק - עו"ז עברית אנגלית רוסית - א',
    group: "3",
  },

  {
    formCode: "patientEmploymentA_UZB", //16
    name: 'חוזה מעסיק מטפל עברית רוסית אוזבקית -מטופל מעסיק (A)',
    group: "3",
  },

  {
    formCode: "familyEmploymentB", //17
    name: 'חוזה עבודה מעסיק - עו"ז עברית אנגלית רוסית - ב',
    group: "3",
  },
  {
    formCode: "patientAgreement", //21
    name: "חוזה א.ש.- מעסיק",
    group: "3",
  },
  {
    formCode: "rishumLishka", //22
    name: "הצהרה של מעסיק המבקש להירשם בלשכה",
    group: "3",
  },
  {
    formCode: "clientReport", //28
    name: "דיווח לביטוח לאומי",
    group: "3",
  },
  {
    formCode: "anotherFirm", //29
    name: "מעבר לשכה - הצהרת מעסיק",
    group: "3",
  },
  {
    formCode: "socialWorkerAgreement", //11
    name: 'אישור עו"ס תאגיד',
    group: "4",
  },
  {
    formCode: "annexA", //12
    name: "נספח A",
    group: "4",
  },
  {
    formCode: "noEnglish", //14
    name: "אי ידיעת השפה האנגלית",
    group: "4",
  },
  {
    formCode: "employerCommitmentB", //15
    name: "התחייבות מעסיק",
    group: "4",
  },

  {
    formCode: "c-2_cDownloadOnly", //18
    name: "נספח C",
    group: "4",
  },

  {
    formCode: "obligatory_deduction_cDownloadOnly", //19
    name: "נספח C - אנגלית רוסית",
    group: "4",
  },
  {
    formCode: "locNew", //20
    name: "כתב התחייבות עובד זר לתשלומי הלשכה LOC",
    group: "4",
  },
  {
    formCode: "humanitarit", //31
    name: "טופס בקשה לועדה הומניטרית",
    group: "5",
  },
  {
    formCode: "socialOpinion", //32
    name: 'חוו"ד עו"ס',
    group: "5",
  },
  {
    formCode: "specialVisaWork", //33
    name: 'תצהיר עו"ד - אנגלית',
    group: "5",
  },
  {
    formCode: "specialVisaWorkRuss", //34
    name: 'תצהיר עו"ד - רוסית',
    group: "5",
  },
  {
    formCode: "waiverOfConfidentialityC", //35
    name: "ויתור סודיות ועדה",
    group: "5",
  },
  {
    formCode: "reportSpecialCase", //36
    name: "דיווח אירוע חריג",
    group: "5",
  },

  /* {
        formCode: "bilateral",
        name: "צרכים מיוחדים",
        group: '1' 
      }, */
];

export const optionsWorkers = [
  {
    formCode: "בחר טופס", //DONT MOVE!!!
    name: "בחר טופס",
  },
  {
    formCode: "workPermitRenew", //37
    name: "טופס הארכת רישיון עבודה",
    group: "2",
  },
  {
    formCode: "familyPermit", //38
    name: "אישור משפחה",
    group: "2",
  },
  {
    formCode: "registration", //23
    name: "הצהרה של עובד זר המבקש להירשם בלשכה (עברית / אנגלית)",
    group: "3",
  },

  {
    formCode: "registration2", //23-2
    name: "הצהרה של עובד זר המבקש להירשם בלשכה (עברית / רוסית)",
    group: "3",
  },

  {
    formCode: "placement", //24
    name: "מכתב השמה עברית / אנגלית",
    group: "3",
  },

  {
    formCode: "placement_ru", //25
    name: "מכתב השמה - רוסית",
    group: "3",
  },

  {
    formCode: "healthDeclarationEnglish", //26
    name: "טופס הצהרת בריאות באנגלית",
    group: "3",
  },
  {
    formCode: "healthDeclarationRussian", //27
    name: "טופס הצהרת בריאות ברוסית",
    group: "3",
  },

  {
    formCode: "anotherFirm2", //30
    name: "מעבר לשכה - הצהרת עוז",
    group: "3",
  },

  {
    formCode: "matanAshratKnisaSugB",
    name: "כניסה לישראל מסוג ב/ו סיעודי",
  },
  {
    formCode: "bidud",
    name: "הצהרת בידוד ללשכה",
  },

  {
    formCode: "enter",
    name: "הצהרת מעסיק לכניסת עובד לישראל",
  },
];

//add sing place at  > מערכת, הגדרות, הוספת מסמכים לחתימה
//edit: W:\Clients\WorkersFlow\As\wsign\complete_forms\workers\registration.php
//line: 15,16, 131,  > 139 ID STEP 1

export const optionsDigitalWorkers = [
  {
    formCode: "SIGN-workPermitRenew", //37
    name: "טופס הארכת רישיון עבודה",
    group: "2",
  },
  {
    formCode: "SIGN-familyPermit", //38
    name: "אישור משפחה",
    group: "2",
  },
  {
    formCode: "SIGN-registration", //23
    name: "הצהרה של עובד זר המבקש להירשם בלשכה",
    group: "3",
  },

  {
    formCode: "SIGN-registration2", //23-2
    name: "הצהרה של עובד זר המבקש להירשם בלשכה (עברית / רוסית)",
    group: "3",
  },
  {
    formCode: "SIGN-placement", //24
    name: "מכתב השמה עברית / אנגלית",
    group: "3",
  },
  {
    formCode: "SIGN-placement_ru", //25
    name: "מכתב השמה - רוסית",
    group: "3",
  },

  {
    formCode: "SIGN-healthDeclarationEnglish", //26
    name: "טופס הצהרת בריאות באנגלית",
    group: "3",
  },
  {
    formCode: "SIGN-healthDeclarationRussian", //27
    name: "טופס הצהרת בריאות ברוסית",
    group: "3",
  },
];

export const optionsDigitalClients = [
  {
    formCode: "SIGN-waiverOfConfidentialityC_A", //1
    name: "ויתור סודיות",
    group: "1", //הזמנת עו"ז מחו"ל
  },

  {
    formCode: "SIGN-commitment", //2
    name: "כתב התחייבות מעסיק + לשכה",
    group: "1",
  },

  {
    formCode: "SIGN-yeter", //4
    name: "נספח ה'- קבלת היתר אשפוז ",
    group: "1",
  },

  {
    formCode: "SIGN-yeter2", //5
    name: "טופס ד' - קטין",
    group: "1",
  },

  {
    formCode: "SIGN-kitzurYeter", //6
    name: "בקשה לקיצור היתר העסקה",
    group: "1",
  },

  {
    formCode: "SIGN-yeter3", //7
    name: "טופס ו להיתר העסקה",
    group: "1",
  },

  {
    formCode: "SIGN-holidayPermit", //8
    name: 'הסכמת מעסיק ליציאה לחופש של העו"ז',
    group: "2",
  },

  {
    formCode: "SIGN-enter2", //13
    name: "טופס בקשה למתן אשרת כניסה לישראל",
    group: "4",
  },

  {
    formCode: "SIGN-patientEmploymentA", //16
    name: 'חוזה עבודה מעסיק - עו"ז עברית אנגלית רוסית - א',
    group: "3",
  },

  {
    formCode: "SIGN-patientEmploymentA_UZB", //16
    name: 'חוזה מעסיק מטפל עברית רוסית אוזבקית -מטופל מעסיק (A)',
    group: "3",
  },

  {
    formCode: "SIGN-familyEmploymentB", //17
    name: 'חוזה עבודה מעסיק - עו"ז עברית אנגלית רוסית - ב',
    group: "3",
  },

  {
    formCode: "SIGN-patientAgreement", //21
    name: "הסכם מטופל-א.ש.וואן",
    group: "3",
  },

  {
    formCode: "SIGN-rishumLishka", //22
    name: "הצהרה של מעסיק המבקש להירשם בלשכה",
    group: "3",
  },

  {
    formCode: "SIGN-clientReport", //28
    name: "דיווח לביטוח לאומי",
    group: "3",
  },

  {
    formCode: "SIGN-anotherFirm", //29
    name: "מעבר לשכה - הצהרת מעסיק",
    group: "3",
  },

  {
    formCode: "SIGN-socialWorkerAgreement", //11
    name: 'אישור עו"ס תאגיד',
    group: "4",
  },

  {
    formCode: "SIGN-annexA", //12
    name: "נספח A",
    group: "4",
  },

  {
    formCode: "SIGN-noEnglish", //14
    name: "אי ידיעת השפה האנגלית",
    group: "4",
  },

  {
    formCode: "SIGN-employerCommitmentB", //15
    name: "התחייבות מעסיק",
    group: "4",
  },

  {
    formCode: "SIGN-locNew", //20
    name: "כתב התחייבות עובד זר לתשלומי הלשכה LOC",
    group: "4",
  },

  {
    formCode: "SIGN-humanitarit", //31
    name: "טופס בקשה לועדה הומניטרית",
    group: "5",
  },

  {
    formCode: "SIGN-socialOpinion", //32
    name: 'חוו"ד עו"ס',
    group: "5",
  },

  {
    formCode: "SIGN-waiverOfConfidentialityC", //35
    name: "ויתור סודיות ועדה",
    group: "5", //הזמנת עו"ז מחו"ל
  },

  {
    formCode: "SIGN-reportSpecialCase", //36
    name: "דיווח אירוע חריג",
    group: "5",
  },
];
