import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import loader from "../../../../img/preLoader.gif"

export default class BuiltOnlyMasInvoice extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            invoiceId : this.props.invoiceId,
            invoiceBuilded : false,
            loading: false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData, loading: false },function(){

            if( stateNameResponse === 'invoiceBuilded') {

                if(getData.buildTranzilaError) {
                    alert('שגיאה');
                } else {
                    this.props.closeInvoice();
                }
                

            }

        }));
    }


    buildMyInvoice = () => {

        console.log('buildMyInvoiceMasOnly');
        this.setState({ loading : true });

        //console.log(this.state);
        let receiptInvoice;

        if(this.props.editInvoice) {

            receiptInvoice = '1';

        } else {
            receiptInvoice = '0';
        }

        console.log(receiptInvoice);

        this.sendtoAdmin('buildMyInvoice','pays', { invoiceId : this.state.invoiceId,  receiptInvoice : receiptInvoice },'invoiceBuilded');

    }

    componentDidMount() {
        if( !this.state.invoiceBuilded ) {
            this.buildMyInvoice();
        }
        
    }
    



    render() {
        return (
            <div className='BuiltOnlyMasInvoice'>
                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
                <Button className='animate__animated animate__heartBeat' onClick={ this.buildMyInvoice } size='lg' variant="success" >יצירת חשבונית</Button>
            </div>
        )
    }
}
