import React, { Component } from "react";
import convertDate from "./../../../convertDateToDisplay";
import BtnDropdownDownload from "./BtnDropdownDownload";
import { Button } from "react-bootstrap";
//import BtnMoneyReturnCard from './BtnMoneyReturnCard'

import MoneyReturnNew from "./MoneyReturnNew";

function toNumberAndFix(number) {
  let newNumber = parseFloat(number);
  return newNumber.toFixed(2);
}

export default class ShowLinksInvoices extends Component {
  reloadInvoices = () => {
    if (this.props.reloadInvoices) {
      this.props.reloadInvoices();
    }
  };

  render() {
    let item = this.props.item;

    //console.log(this.props);
    //console.log('item', item);

    let flagPaysMas;
    let buttonText;

    if (item.invoiceType === "חשבונית מס" && !item.pdfTranzilaFileReceipt) {
      flagPaysMas = true;
      buttonText = "ביצוע תשלום";
    } else {
      flagPaysMas = false;
    }

    let itemId = item.id;
    let superTotal = item.superTotal;

    let isImported = item?.is_ASimported === "1" ? true : false;
    isImported = item?.isKevaImportsAs === "1" ? true : false;

    isImported = item?.isFreeAsama === "1" ? true : false;

    return (
      <li>
        {/* receipt_125.pdf */}
        <strong>
          {item.invoiceType} - {convertDate(item.invoiceDate)} -{" "}
          {item.transactionType}{" "}
        </strong>
        <span> | </span>
        <span>סכום: {toNumberAndFix(item.superTotal)} ₪</span>
        {item?.paymentMessageResponse?.length > 0 ? (
          <div
            style={{
              marginTop: 5,
            }}
          >
            <span
              style={{
                color: "red",
              }}
            >
              סיבת כשל: {item.paymentMessageResponse}
            </span>
          </div>
        ) : !isImported ? (
          <div className="buttonsCont">
            <BtnDropdownDownload item={item} type="pdfInvoice" />
            {/* {item.pdfTranzilaFileReceipt ? (
              <BtnDropdownDownload item={item} type="pdfReceipt" />
            ) : (
              false
            )} */}
            <MoneyReturnNew
              item={item}
              reloadInvoices={() => this.reloadInvoices()}
            />
            {/* { item.are_card_pays ? <BtnMoneyReturnCard item={item} reloadInvoices = { () => this.reloadInvoices() } /> : false } */}
            {flagPaysMas && this.props.editInvoiceId ? (
              <Button
                className="doPaysForReceipt"
                size="sm"
                variant="dark"
                onClick={() => this.props.editInvoiceId(itemId, superTotal)}
              >
                {buttonText}
              </Button>
            ) : (
              false
            )}
          </div>
        ) : null}
      </li>
    );
  }
}
