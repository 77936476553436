import React, { Component } from 'react'
import { ConstantsNames } from "./../../../Urls"
import { Button } from 'react-bootstrap';
import ModalSendMailDocs from './ModalSendMailDocs';
import ModalSendMailFiles from '../AllFiles/ModalSendMailFiles';

export default class ShareDocument extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            linkedDocsId : this.props.item.id,
            token : this.props.item.token,
            modalShow : false,
            modalShow2 : false, //send signed document,

            item : false,
            mail : this.props.mail
        }
    }
    
    modalShow = (value) => {
        this.setState({ modalShow : value })
    }

    modalShow2 = (value) => {


        const item = {

            data  : {
                fileName :  this.props.item.fileName,
                documentName : this.props.item.name
            }
            
        }

        this.setState({ item: item, modalShow2 : value })    
        
    }

    render() {

        //console.log('item',this.props.item);

        let item = this.props.item;

        let start = ConstantsNames.base_url + '/wsign/getdoc.php?token=';
        let start1 = ConstantsNames.base_url + '/wsign/signed_docs/';

        let hrefSign = start + item.token + '&clientId=' + item.clientId + '&workerId=' + item.workerId + '&WdocId=' + item.id;
        let hrefShow = start1 + item.fileName;
        let aText = item.fileName ? <span>צפיה בקובץ</span> : <span>לחתום עכשיו</span>;
        let href = item.fileName ? hrefShow : hrefSign;
        

        return (
            <div>
                <Button href={ href } className="" target="_blank" rel="noopener noreferrer" size="sm" variant={item.fileName ? 'success' : 'danger' }  >{aText}</Button>
                {item.fileName ? <Button onClick={()=>this.modalShow2(true)} size="sm" variant="primary" style={{margin : '0 10px 0 0'}} >שליחת קובץ במייל</Button> : false }
                { this.state?.modalShow2 && <ModalSendMailFiles  mail = {this.props?.mail} is_wsign = {true} currentState={this.state} modalShow={(value)=> this.modalShow2(value)} /> }

                
                {!item.fileName ? <Button onClick={()=>this.modalShow(true)} size="sm" variant="primary" style={{margin : '0 10px 0 0'}} >שליחה קישור במייל / SMS</Button> : false }
                {!item.fileName ? <ModalSendMailDocs currentState={this.state} modalShow={(value)=> this.modalShow(value)} /> : false }

            </div>
        )
    }
}
