import React, { useState } from 'react'

import Grid from '@mui/material/Grid';
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { sendtoAdmin } from '../../../../DinamicForm/newApi/getDataFromApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function WorkerType(props) {

    const { dataParam, setLoading, isAnotherFirmImport, setIsAnotherFirmImport } = props;

    //isAnotherFirmImport = {isAnotherFirmImport} setIsAnotherFirmImport = { setIsAnotherFirmImport }

    const [response, setResponse] = useState(false);

    //console.log('dataParam', dataParam);

    const types = {
        our : 'ייבוא עצמי',
        import : 'מלשכה אחרת'
    }

    const wType = isAnotherFirmImport ?  types?.import : types?.our;

    const [ workerType, setWorkerType ] = useState(wType);
    const [ firmName, setFirmName ] = useState(dataParam?.importFirmName);

    const sendForm = () => { 



        const sendData = {

            workerId : dataParam?.id,
            firmName : workerType === types?.our ? '' : firmName,

        }

        console.log('sendData', sendData);
        sendtoAdmin('updateWorkerType', 'financeWorkers', sendData, setResponse, setLoading);

    }

    useEffect(() => {

        if(workerType === types?.import && !isAnotherFirmImport ) {

            setIsAnotherFirmImport(true);

        } else if(workerType === types?.our && isAnotherFirmImport ) {

            setIsAnotherFirmImport(false);

        }

      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workerType])
    

    useEffect(() => {

        if(response?.ok) {

            toast.success(response?.ok);

        }

      
    }, [response])
    

  return (
    <div className='workerType'>
        <Grid className='lineForm' container spacing={2}  direction="row" justifyContent='center' > {/*  */}
        
            <Grid item md={5} >

                <div className='selectInput'>
                    <InputGroup className="customInput">
                        <InputGroup.Prepend className="inputPrependLabel ">
                        <InputGroup.Text>סוג עובד/ת</InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                        as="select"
                        /* 'inputPrepend customSelect' */
                        className="inputPrepend customSelect"
                        onChange={ (e) =>  setWorkerType(e.target.value) }
                        value={ workerType }
                        //name=""
                        >
                        <option value="">בחירה</option>

                        <option value={types?.import}>{types?.import}</option>
                        <option value={types?.our}>{types?.our}</option>
                        
                        </FormControl>
                    </InputGroup>
                </div>

            </Grid>

            {workerType === types?.import && <Grid item md={5} >

                <InputGroup className="customInput">
                    
                    <InputGroup.Prepend className="inputPrependLabel">
                    <InputGroup.Text id="basic-addon3">שם לשכת אחרת</InputGroup.Text>
                    </InputGroup.Prepend>

                <FormControl
                    className="inputPrepend"
                    onChange={ (e)=> setFirmName(e.target.value) }
                    //name={}
                    value={ firmName }
                />
                </InputGroup>

            </Grid>}

            <Grid item md={2} >
                <Button onClick = { sendForm } disabled={ (!firmName && workerType === types?.import) ? true : false } variant="success" style={{ width: '100%' }} >עידכון</Button>
            </Grid>

        </Grid>

    </div>
  )
}
