import React, { Component } from 'react'
import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
import { Button } from 'react-bootstrap';
import {delleteRow } from "./../../../DinamicForm/delleteRow"

export default class TdContacts extends Component {


    handlerDelleteRow = (url,rowId) => {
        delleteRow(url,rowId);
        this.props.submit(rowId);
        //setTimeout(() => this.props.submit(), 50);
    }

    render() {

        let  itemData = this.props.itemData;

        return (
            <div className="trTable contentTds">
                {this.props.contactsDataForm.map(item => { if (true) {
                        /* currentState={this.state} */
                        return <InputFormAdmin
                                    platform={this.props.platform}
                                    prepend = {false}
                                    user={this.props.user}
                                    methodName='foreignWorkers'
                                    updateGetAction='?update=2'
                                    data={item}
                                    rowsOption4Select = {false}
                                    dataParam={itemData}
                                    key={item.name} />
                    } else return false}) }
                    <div>
                    <Button className="addBtn deletteBtn" onClick={() => this.handlerDelleteRow('foreignWorkers?delette=recommend',this.props.itemData.id)} variant="secondary" size="sm"><i className="far fa-trash-alt" /></Button>
                    </div>
            </div>
            
        )
    }
}
