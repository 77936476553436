import React, { Component } from 'react'
import { RestUrls } from "../../../Urls";
import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
//import ReportIt from "./ReportIt" 
//import Contacts from "./Contacts" 
import { Button, Modal, Accordion, Card } from 'react-bootstrap';

import { sendToAdmin } from "./../../../DinamicForm/sendToAdmin"
//import { addSection } from "./../../../DinamicForm/addSection"

//import FilesWorker from "./FilesWorker"
import LinksWorker from "./LinksWorker"
import RecommendsWorker from "./RecommendsWorker"

import OpenTaskModalBody from './../HomePage/Tasks/OpenTaskModalBody'
import WsignIndex from './../Wsign/WsignIndex';

import DigformsPDF from './../Clients/DigformsPDF'
import Loading from '../../../-HELPERS-/Loading';
import SyncMain from '../Config/SyncMain';
//import DocumentsAS from '../Clients/DocumentsAS';

import FinanceWorkerIndex from './financeWorker/FinanceWorkerIndex';
import OurFilesPanel from '../Clients/OurFilesPanel';
import { checkDisabledWorkersFields } from '../../../DinamicForm/checkDisabledFields';
//import convertDate from './../../../convertDateToDisplay'

import { find,concat } from 'lodash';
import { Fragment } from 'react';
import CommentsIndex from '../Clients/comments/CommentsIndex';

export default class WorkerScreen extends Component {

    constructor(props) {

        super(props);
        this.state = {            
            dataPage : [],
            isLoaded: false,
            loadPanelData : []
        }

    }

    async componentDidMount() {

        localStorage.setItem('searchInner','');
        localStorage.setItem('currentPathSearch','');

        const API =  RestUrls.baseApiUrl + 'foreignWorkers/'+ this.props.workerId;

        //console.log(API);

        let user = this.props.user;
        

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        //console.log(data);
       
        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            this.setState(
                {
                  dataPage: data,
                  isLoaded: true
                }
            )
        }
  
        //console.log(data);
    }

    changeArrayRecommends = () => {
        setTimeout(() => this.componentDidMount(), 500);
    }

    editHandler = (selectedClientId) => {
        this.props.cancelId(selectedClientId);
    }

    insertRecommendsWorker = (url) => {
        sendToAdmin(url);
        setTimeout(() => this.componentDidMount(), 100);
    }

    newTask = (value) => {
        //console.log('IN TASK');
        this.setState({ modalShow : value })
    }

    onChangeAdmin = () => {
        console.log('changed');
        
        setTimeout(() => this.setState({savedMessage: true}), 1000);
        setTimeout(() => this.setState({savedMessage:false}), 4000);
    }

    render() {
        if (!this.state.isLoaded) {
            return <Loading open={true} noBackdrop = {true} />
        }

        else {

            //formRecommendsWorker
            let urlInsertRecommend = 'foreignWorkers/' + this.props.workerId + '?insertRecommend=1';
            let WorkerData = this.state.dataPage.dataParam;

            return (
                <div className="clientScreen workerScreen">
                    <header className="newTask">
                        <button style={{marginLeft: 0}} className='newTaskBtn' onClick ={()=>this.newTask(true)} >משימה חדשה</button>
                        {/* <button className='newTaskBtn' onClick ={()=>console.log('NOT SET')} >בדיקה מול משרד הפנים</button> */}
                    </header>

                    {this.state.savedMessage ? <div className="savedWindow animate__animated animate__bounceIn animate__slow">השינוים נשמרו בהצלחה</div> : false }

                    <button className='goBackCustomBtn bounce' onClick ={()=>this.editHandler(false)} >&laquo; חזור</button> <h2 className="titStyle firstH2">מסך עובד: {WorkerData.name} {WorkerData.surname}</h2>
                    
                    {(WorkerData && WorkerData.passport) && <SyncMain workerTz={WorkerData.passport} metapelCode = {WorkerData?.metapel_code} /> }

                    <div className="row formContainerAdmin">
                        {this.state.dataPage.form.map(item => { if (true) {
                            /* currentState={this.state} */
                            return <InputFormAdmin
                                        platform={this.props.platform}
                                        onChange={this.onChangeAdmin}
                                        Prepend={true}
                                        user={this.props.user}
                                        methodName='foreignWorkers'
                                        updateGetAction='?update=1'
                                        data={item}
                                        is_disabled = { checkDisabledWorkersFields(this.props.workerId,item) }
                                        rowsOption4Select = {this.state.dataPage.rowsOption4Select}
                                        dataParam={this.state.dataPage.dataParam}
                                        key={item.name} />
                        } else return false}) }
                    </div>

                    <div className="accordionSec">

                        {/* mismachim */}
                        <Accordion>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="111" onClick={ () => !this.state.loadDumentsAs && this.setState({ loadDumentsAs : true })} >
                                    <h2 className="titStyle ">מסמכים</h2>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="111">
                                <Card.Body>
                                    {/* {console.log(this.state.dataPage.dataParam)} */}
                                    {/* <DocumentsAS tz_passport={this.state.dataPage.dataParam.passport} entity_type='3' /> */}
                                    { this.state.loadDumentsAs &&  < OurFilesPanel mail = {WorkerData?.email} workerId={ WorkerData.id } user = {this.props.user} /> }
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* heshbonot */}
                        <Accordion>
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1112" onClick={ () => !this.state.loadHesh && this.setState({ loadHesh : true })} >
                                    <h2 className="titStyle ">הנהלת חשבונות</h2>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1112">
                                <Card.Body>
                                    {/* {console.log(this.state.dataPage.dataParam)} */}
                                    { this.state.loadHesh && <Fragment>

                                            < FinanceWorkerIndex linkedClients = {this.state.dataPage.linkedClients} dataParam = { this.state.dataPage.dataParam } />
                                            
                                            <div className="sectionComments">
                                                <h3>הערות</h3>
                                                < CommentsIndex action='finance' seniorId = { false } workerId = { this.props.workerId } />
                                            </div>

                                        </Fragment> }
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* historia */}
                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        <h2 className="titStyle ">היסטורית שיבוצים</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <section className="linkedWorker">
                                            <h2 className="titStyle">היסטורית שיבוצים</h2>
                                            <LinksWorker linkedClients = {this.state.dataPage.linkedClients} />
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        <h2 className="titStyle ">המלצות</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <section className="RecommendsWorker">
                                            <h2 className="titStyle">המלצות</h2>
                                            {this.state.dataPage.recommendsWorker.length > 0 ? <RecommendsWorker changeArrayRecommends={this.changeArrayRecommends} platform={this.props.platform} dataPage={this.state.dataPage} user={this.props.user} /> : ''}
                                            <div className='clear' >
                                                <Button  className={this.state.dataPage.recommendsWorker.length > 0 ? "addBtn" : "addBtn addSection"} onClick={() => this.insertRecommendsWorker(urlInsertRecommend)} variant="secondary" size="sm">הוספת שורה <i className="fas fa-plus" /></Button>
                                            </div>
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                         {/* hatima */}
                         <Accordion   >
                            <Card>
                                <Card.Header>

                                    <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                        <h2 className="titStyle ">חתימה דיגיטלית של מסמכים</h2>
                                    </Accordion.Toggle>

                                </Card.Header>
                                <Accordion.Collapse eventKey="10">

                                <Card.Body>
                                    <WsignIndex clientId={false} workerId={WorkerData.id} />
                                </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* digitalim */}
                        <Accordion >
                            <Card>
                                <Card.Header>

                                    <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                        <h2 className="titStyle ">טפסים דיגיטלים</h2>
                                    </Accordion.Toggle>

                                </Card.Header>
                                <Accordion.Collapse eventKey="10">

                                    <Card.Body>
                                        <DigformsPDF showSendOnlyMails={true} clientId={false} workerId={WorkerData.id}  />
                                        
                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* comments */}
                        <Accordion>
                        <Card>
                            <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="3"
                                //loadPannel
                                onClick={()=> this.setState({loadPanelData: concat(this.state.loadPanelData,'comments')})}>
                                <h2 className="titStyle ">הערות</h2>
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                { checkShow(this.state.loadPanelData,'comments') && 
                                < CommentsIndex action='panel' seniorId = { false } workerId = { this.props.workerId } />
                                }
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </Accordion>

                    </div>
                    


                    {/* https://react-bootstrap.netlify.app/components/modal/ */}
                    <Modal
                        className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                        animation={false}
                        onHide={() => this.newTask(false)}
                        /* size="sm" // בגודל קטן!!! */
                        /* size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalShow}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <span>הוספת משימה חדשה</span>
                            </Modal.Title>
                            <Button onClick={() => this.newTask(false)} variant="secondary" >X</Button>
                        </Modal.Header>
                        <Modal.Body>
                            <OpenTaskModalBody currenId='' workerId={this.state.dataPage.dataParam.id} optionsTaskTypes={this.state.dataPage.taskTypes} optionsStatusTasks={this.state.dataPage.tasksStatus} />
                        </Modal.Body>
                    </Modal>

                </div>
            )
        }
    }
}

function checkShow(loadPanelData, panelName) {

    return find(loadPanelData, function(o) { return o === panelName }) ? true : false;
  
  }   