import React, { Fragment } from 'react'
import { map,isEmpty } from "lodash";
import { sendtoAdmin } from '../../../../DinamicForm/newApi/getDataFromApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import NewInvoice from './NewInvoice';

//import { RestUrls } from "./../../../../Urls"
import MoneyReturnWorker from './MoneyReturnWorker';

import { Button, Dropdown } from 'react-bootstrap';
import { ConstantsNames } from "./../../../../Urls";
import ModalSendMailWorker from './ModalSendMailWorker';

export default function TransactionsList(props) {

    const { workerTransactions } = props

    if(isEmpty(workerTransactions)) {return false;}

    return (
        <div className='transactionsList'>

            <h3>עסקאות קיימות</h3>

            <ul>
                    {   map(workerTransactions, item => {
                        
                        return < ShowMyTransaction key={item.id} item = { item } {...props} />
                    
                        })
                    }
                
            </ul>

        </div>
    )
}

function ShowMyTransaction(props) {


    const { item, setLoading,dataParam, setWorkerTransactions, linkedClient, reload } = props;
    const [responseData, setResponseData ] = useState(false);

    //console.log('item', item);

    const deleteMe = () => { 


        const send = {
            rowId : item.id,
            workerId : dataParam?.id
        };

        sendtoAdmin('deleteTransactions', 'financeWorkers', send, setResponseData, setLoading);
        //console.log('deleteRow: ', item.id );

    }

    useEffect(() => {
    
        if(responseData?.ok) {

            setWorkerTransactions(responseData?.workerTransactions);
            toast.success(responseData?.ok);

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])

    //const href = RestUrls.baseApiUrl1 + 'assets/files/invoices/';

    const reloadInvoices = () => { 

        reload();
        //console.log('reloadInvoices...');

    }

    


    return(
        <li>

            <h4>
                <span className='rText'>{item?.name}</span>
                {/* <span className={`lprice ${ item?.isPayed ? 'green' : 'red' }`}>
                    { (!item?.isPayed && !isEmpty(item?.invoices) ) && <span>(₪{item?.moneyCount})</span> }   
                    <span>₪{item?.money}</span>
                </span> */}
                
            </h4>

            <div className='myContent'>
                
                {isEmpty(item?.invoices) && <div className='delBtn'>
                    <button onClick={ deleteMe }>מחיקת עסקה</button>
                </div> }

                <div className='invoices'>

                    <NewInvoice item = {item} setWorkerTransactions = {setWorkerTransactions}  linkedClient = { linkedClient } />
                    
                    { map(item?.invoices, invoice => { return (<div className='invoice' key={invoice.id} style = {{margin: '0 0 10px 0'}} >


                    <span>- </span>
                    <span>{invoice.created_atShow}</span>
                    <strong><small>₪</small>{ parseFloat(invoice.money).toFixed(2) }</strong>

                    < DropdownHesh item = {invoice} />
                    

                    {/* <a href={ href + invoice?.pdfTranzilaFile} target='_blank' rel="noreferrer">לצפייה בחשבונית &raquo;</a> */}

                    { invoice.pdfTranzilaFile && < MoneyReturnWorker item = {invoice} workerData = {item} reloadInvoices = { reloadInvoices } /> } 

                    </div>) }) }

                </div>

            </div>

        </li>
    )


}

function DropdownHesh(props) {
    
    const {item} = props

    const [dropdownShow, setDropdownShow] = useState(false);
    const [showMailModal, setShowMailModal] = useState(false);

    //console.log('item', item);

    let pdfDownload;

    var md5 = require('md5');

    if(item.pdfTranzilaFile) {
        pdfDownload = ConstantsNames.base_url + '/pays/downloadInvoicesWorkers?file=' + md5(item.pdfTranzilaFile);
    }

    const sendMailHandle = () => { 

        setDropdownShow(!dropdownShow);
        setShowMailModal(true);

        //console.log('sendMail...');

     }

     const  currentState = {

        modalShow : showMailModal,
        invoiceId : item.id
     }

    return(
        <Fragment>

            <Dropdown className='DropdownButtonsDownload' show={ dropdownShow } >
                {/* id="dropdown-basic" */}
                <Dropdown.Toggle onClick={()=>setDropdownShow(!dropdownShow) } variant="secondary" size="sm"  id="dropdown-autoclose-true">
                    חשבונית
                </Dropdown.Toggle>

                <Dropdown.Menu >
                    <Button onClick={()=>setDropdownShow(!dropdownShow)} style={{width : '100%' }} href={ pdfDownload + '&view=1' } className="" target="_blank" rel="noopener noreferrer" size="sm" variant="light" >צפייה בחשבונית</Button>
                    <Button onClick={()=>setDropdownShow(!dropdownShow)} style={{width : '100%' }} href={  pdfDownload } className="" target="_blank" rel="noopener noreferrer" size="sm" variant="light" >הורדת חשבונית</Button>
                    <Button style={{fontSize : '16px', width : '100%' }} onClick={ sendMailHandle } size="sm" variant="light" >שליחת חשבונית במייל</Button>
                </Dropdown.Menu>
            </Dropdown>

            <ModalSendMailWorker currentState={ currentState } modalShow={(value)=> setShowMailModal(value)} />

        </Fragment>
        
    )


}