export function checkDisabledClientsFields(clientId,field) {

    //console.log('field >', field);

    /* if(clientId === '92' ) {
        return false;
    } */

    if(!field?.name) {
        return false;
    }

    switch (field.name) {

        case 'seniorStatus': return false;

        case 'closeReason': return false;        
        
        case 'license_exist': return false;

        case 'pastWorkers': return false;
        case 'vahada': return false;

        case 'license_number': return false;

        case 'place_classification': return false;

        case 'lang_other': return false;
        case 'lang_hebrew': return false;
        case 'lang_english': return false;
        case 'lang_arab': return false;
        case 'maritalStatus': return false;

        default:
            return true;
            
    }
 
}


export function checkDisabledWorkersFields(workerId,field) {

    //console.log('field >', field);

    /* if(workerId === '92' ) {
        return false;
    } */

    if(!field?.name) {
        return false;
    }

    switch (field.name) {

        case 'workerStatus': return false;
        case 'dateInscription': return false;
        case 'nameSpouse': return false;
        case 'visa': return false;
        case 'partnerPlace': return false;


        case 'set_salary': return false;
        case 'restDay': return false;
        case 'work_shavat': return false;
        case 'favorite_place': return false;
        case 'favorite_sex': return false;
        

        default:
            return true;
            
    }
    


}