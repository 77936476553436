import React from "react";
import { Fragment } from "react";
import MultiSelectorRakazOz from "./MultiSelectorRakazOz";
import { useState } from "react";
import { useEffect } from "react";

import loader from "../../../../../img/preLoader.gif";

import getDataFromApi from "../../../../DinamicForm/newApi/getDataFromApi";
import { isEmpty } from "lodash"; //find, map,
import { toast } from "react-toastify";

export default function LinkPanelIndex(props) {
  const { seniorId, onSelectSocialWorker } = props;

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);

  useEffect(() => {
    const sendData = {
      seniorId: seniorId,
    };

    sendtoApi(
      "getOptionsLinkPanel",
      "first",
      sendData,
      setLoading,
      setResponse
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (response?.socialWorkerData) {
      props?.onSelectSocialWorker?.(response?.socialWorkerData?.[0]);
    }
  }, [response?.socialWorkerData]);

  //options

  return (
    <Fragment>
      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      {!isEmpty(response) && (
        <Fragment>
          <MultiSelectorRakazOz
            options={response.coordinators}
            action="coordinator"
            seniorId={seniorId}
            data={response.coordinatorsData}
          />
          <MultiSelectorRakazOz
            onSelectSocialWorker={onSelectSocialWorker}
            options={response.oz}
            action="oz"
            seniorId={seniorId}
            data={response.socialWorkerData}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

function sendtoApi(url, controller, objectToSend, setLoading, setResponseData) {
  setLoading(true);

  //console.log(url);

  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    if (getData.error) {
      toast.error(getData.error, {
        toastId: "responseError",
      });
    }

    if (getData.ok) {
      setResponseData(getData?.data);
    } else {
      toast.error("שגיאה", {
        toastId: "genericError",
      });
    }
  });

  getData.catch((error) => {
    console.log(error);
  });

  getData.finally(() => {
    setLoading(false);
  });
}
