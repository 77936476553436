import React, { useState } from 'react'
import AnnexA from './AnnexA';
import HolidayPermit from './HolidayPermit';
import Yeter2 from './Yeter2';
import loader from "../../../../../img/preLoader.gif";
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import getDataFromApi from '../../../../DinamicForm/newApi/getDataFromApi';
import Enter2 from './Enter2';
import Commitment from './Commitment';
import PatientEmploymentA from './PatientEmploymentA';
import FamilyEmploymentB from './FamilyEmploymentB';
import LocNew from './LocNew';
import PatientAgreement from './PatientAgreement';
import RishumLishka from './RishumLishka';
import Placement from './Placement';
import Registration2 from './Registration2';
import ClientReport from './ClientReport';
import AnotherFirm from './AnotherFirm';
import AnotherFirmB from './AnotherFirmB';
import Humanitarit from './Humanitarit';
import SocialOpinion from './SocialOpinion';
import SpecialVisaWork from './SpecialVisaWork';
import WaiverOfConfidentialityC from './WaiverOfConfidentialityC';
import ReportSpecialCase from './ReportSpecialCase';
import FamilyPermit from './FamilyPermit';



export default function MoreDataPdf(props) {

    const { showModal } = props;
    const [ moreDataPdf, setMoreDataPdf ] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

    const sendForm = () => { 

        showModal.sendFormMoreDadaPDF(moreDataPdf);

    }

    useEffect(() => {

        const formCodeSwitch = showModal?.formCode.replace("SIGN-", "");

        setResponseData(false);

        if( formCodeSwitch === 'annexA' ||
            formCodeSwitch === 'placement' ||
            formCodeSwitch === 'placement_ru' ||
            formCodeSwitch === 'clientReport' ||
            formCodeSwitch === 'humanitarit' ||
            formCodeSwitch === 'socialOpinion' ||
            formCodeSwitch === 'specialVisaWork' ||
            formCodeSwitch === 'specialVisaWorkRuss' ||
            formCodeSwitch === 'reportSpecialCase'
          ) {

            sendtoApi(
                "getMoreDataPdf",
                "DigformsPDF",
                showModal.send,
                setLoading,
                setResponseData,
              );

        } else {

            setResponseData(true);

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, ['showModal'])
    

  return (
    <div className='moreDataPdf'>
        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
        { responseData && getFormMoreData(showModal?.formCode, setMoreDataPdf, sendForm, responseData ) }
    </div>
  )
}


function getFormMoreData(showModal, setMoreDataPdf, sendForm, responseData ) {

    const formCodeSwitch = showModal.replace("SIGN-", "");

    switch (formCodeSwitch) {

        case 'yeter2':
            return < Yeter2  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'holidayPermit':
            return < HolidayPermit  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'annexA':
            return < AnnexA  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData } />

        case 'enter2':
            return < Enter2  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm }  />
    
        case 'commitment':
            return < Commitment  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm }  />

        case 'patientEmploymentA':
            return < PatientEmploymentA  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'patientEmploymentA_UZB':
            return < PatientEmploymentA  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'familyEmploymentB':
            return < FamilyEmploymentB setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'locNew':
            return < LocNew  setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'patientAgreement':
          return < PatientAgreement setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'rishumLishka':
          return < RishumLishka setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'placement':
          return < Placement setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData } />

        case 'placement_ru':
          return < Placement setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData } />
          
        case 'registration2':
          return < Registration2 setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'clientReport':
          return < ClientReport setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData }/>

        case 'anotherFirm':
          return < AnotherFirm setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'anotherFirm2':
          return < AnotherFirmB setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />
          
        case 'humanitarit':
          return < Humanitarit setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData }/>

        case 'socialOpinion':
          return < SocialOpinion setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData }/>
          
        case 'specialVisaWork':
          return < SpecialVisaWork setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData }/>
          
        case 'specialVisaWorkRuss':
          return < SpecialVisaWork setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData }/>

        case 'waiverOfConfidentialityC':
          return < WaiverOfConfidentialityC setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />

        case 'waiverOfConfidentialityC_A':
          return < WaiverOfConfidentialityC setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />
        
        case 'reportSpecialCase':
          return < ReportSpecialCase setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } responseData = { responseData }/>

        
        case 'familyPermit':
          return < FamilyPermit setMoreDataPdf = { setMoreDataPdf }  sendForm = { sendForm } />


        default:
            return <h2 style={{color: 'red'}}>שגיאה</h2>;

    }

}

function sendtoApi(
    url,
    controller,
    objectToSend,
    setLoading,
    setResponseData    
  ) {

    setLoading(true);
  
    //console.log(url);
  
    const getData = getDataFromApi(url, objectToSend, controller, "all");
  
    getData.then((getData) => {

      if (getData.error) {
        toast.error(getData.error, {
          toastId: "responseError",
        });
        
      }

      if (getData.ok) {

        setResponseData(getData?.formData);
        
      } else {

        toast.error("שגיאה", {
          toastId: "genericError",
        });

      }

    });
  
    getData.catch((error) => {
      console.log(error);
    });
  
    getData.finally(() => {
      setLoading(false);
      setResponseData(true);
    });
  }

