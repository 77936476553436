import React, { Component } from 'react'
import { InputGroup, FormControl, ProgressBar } from 'react-bootstrap';

import FilesHandle from './FilesHandle';

import { FormValidator } from "../../../forms/FormValidator";
import { ValidationMessage } from "../../../forms/ValidationMessage";
import { ValidateForm } from "../../../forms/wholeFormValidation";
import { RestUrls } from "../../../Urls";

import axios from 'axios';
import Dropzone from 'react-dropzone';


export default class AllFiles extends Component {

    constructor(props) {

        super(props);

        this.state = {
            clientId : this?.props?.clientId ?  this?.props?.clientId : '',
            workerId : this?.props?.workerId ?  this?.props?.workerId : '',
            show : this?.props?.show ? true : false,
            dataFiles : [],
            sentForm : false, // show / hide form
            inputFile: '',
            fileCategory: '',
            uploadError: '',
            name : '',   //File Upload
            uploadProgress : null, //File Upload
            selectedFile: null,  //File Upload
        };

        this.rules = {
            name: { required: true, minlength: 3 },
            inputFile: { required: true },
        }
        
    }


    componentDidMount() {

        if(this.props.show ) {

            this.loadData();

        }

    }


    //Input Text 
    updateFormValue = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        //console.log(event.target.value);
    }

    //File Upload
    fileSelectedHandler = event => {
        if(event.target.files[0] && event.target.files[0].name && event.target.files[0] ) {
            this.setState({
                selectedFile : event.target.files[0],
                inputFile : event.target.files[0].name
            })
        }
        
    }
    

    //File Upload
    fileUploadHandler = () => {

        // let userJ = JSON.parse(localStorage.getItem('user-info'));
        // let user = userJ.data;

        const fd = new FormData();

        // fd.append('userId',  user.id);
        // fd.append('userCredential',  user.userCredential);
        // fd.append('token',  user.token);
        fd.append('siteCode',  RestUrls.Code);

        fd.append('workerId',  this.state.workerId);
        fd.append('seniorId',  this.state.clientId);

        fd.append('name',  this.state.name);
        fd.append('category',  this.state.fileCategory);
        fd.append('filename', this.state.selectedFile, this.state.selectedFile.name);

        console.log(fd);

        axios.post( RestUrls.filesMethod, fd, {
            onUploadProgress: progressEvent => {
                this.setState({ uploadProgress : Math.round(progressEvent.loaded / progressEvent.total * 100 ) });
                //console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100 ) + '%')
            }
        })
            .then( res => {

                //console.log(res);
                //console.log(res.data);


                if(res.data === 'unauthorized') {
                    localStorage.setItem('user-info','');
                    window.location.reload();

                } else {

                    this.setState({
                        sentForm : true, //SENT,
                        uploadError : res.data.uploadError ? res.data.uploadError : '',
                        inputFile: '',
                        fileCategory: '',
                        name : '',   //File Upload
                        uploadProgress : null, //File Upload
                        selectedFile: null,  //File Uploadת
                        allFilesWorker: [],
                    });

                    this.loadData();
                }
            });
    }

    handleMoreFiles = () => {
        this.setState({
            sentForm : false, //SENT,
            uploadError : '',
            inputFile: '',
            uploadProgress : null,
            name : '',   //File Upload
            selectedFile: null,  //File Uploadת
        });
    }


    

    editHandlerSelect = (event) => {
        this.setState({ clientId : '',   workerId :'', [event.target.name] : event.target.value}, function () {
            this.loadData();
        });
        
        //console.log(event.target.value);
        //this.uptateInputField (event.target.name, event.target.value);
    }


    // FOR FILE CATEGORIES
    editHandlerSelectDefault = (event) => {
        this.setState({[event.target.name] : event.target.value}, function () {
            //console.log(this.state);
        });
    }

    //new files
    async loadData () {

        //console.log('loading...');

        const API =  RestUrls.baseApiUrl + 'uploadFiles?getFiles=1';
        let user = this.props.infoSite.user.data;
        //console.log(this.state);
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code,
                workerId : this.state.workerId,
                seniorId : this.state.clientId,
                category : '',
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            //console.log(data);
            this.setState({ dataFiles : data, show : true });
        }
        
        
        /* this.setState(
            {
              allFilesWorker: data.allFilesWorker
            }
        ) */
    }


    render() {


    let pageData = this.props?.pageData ? this.props?.pageData : false;


    //SELECT

        return (
            <div className="AllFilesPage animate__animated animate__fadeIn">


                {!this.props.show && <h2 className="titStyle">מסמכים</h2>}

                {!this.props?.show && <div className="row justify-content-center selectorContent">
                        <div className="col-lg-4 text-center selectClientsWorkers">
                        
                            <InputGroup >
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1"><i className="fas fa-user boostrapFaIcons"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                as="select"
                                name="clientId"
                                value={this.state.clientId}
                                onChange={this.editHandlerSelect}
                                className='boostrapInvertcornersLeft' //Sebas
                                >
                                    <option>בחר לקוח...</option>
                                    {pageData.clientsList.map(item => { if (true) {
                                            /* currentState={this.state} */
                                            return < option value ={item.id} key ={ item.id }> 
                                                        {item.name} {item.surname}
                                                    </ option >
                                        } else return false}) }
                                </FormControl>
                            </InputGroup>
                        </div>

                        <div className="col-lg-4 text-center">
                            <InputGroup >
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1"><i className="fas fa-briefcase boostrapFaIcons"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                name="workerId"
                                as="select"
                                value={this.state.workerId}
                                className='boostrapInvertcornersLeft' //Sebas
                                onChange={this.editHandlerSelect}
                                >
                                    <option>בחר עובד...</option>
                                    {pageData.foreignWorkersList.map(item => { if (true) {
                                            /* currentState={this.state} */
                                            return < option value ={item.id } key ={ item.id }> 
                                                        {item.name} {item.surname}
                                                    </ option >
                                        } else return false}) }
                                </FormControl>
                            </InputGroup>
                        </div>
                </div> }


                    <section className="FilesWrapper animate__animated animate__fadeIn">

                            { this.state.show ?
                                <div>
                                    <Dropzone noClick onDrop={acceptedFiles => {
                                        
                                        console.log(acceptedFiles)
                                        if(acceptedFiles[0] && acceptedFiles[0].name) {
                                            this.setState({
                                                selectedFile : acceptedFiles[0],
                                                inputFile : acceptedFiles[0].name,
                                                name: acceptedFiles[0].name?.split?.('.')?.[0]
                                            })
                                        }
                                        }}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                <div className="filesWorker" style={{marginTop : '40px', padding: "10px", border: isDragActive ? "1px dashed red":"0px dashed red"}} {...getRootProps()}>
                                    <div className="formFileSection">
                                    {/* !this.state.sentForm */}
                                    { !this.state.sentForm || this.state.uploadError ? 
                                    <div>
                                        
                                        <h3>העלאת קובץ חדש:</h3>
                                    
                                    <p className="fileTypesText">קבצים מסוג: jpg / pdf / png / tif עד 20 מגה.</p>
                
                                        <FormValidator data={ this.state } rules={ this.rules } btnText='שלחו' buttonSendAction={this.fileUploadHandler} validateForm={ ValidateForm }>
                                        
                                        <ValidationMessage field="form" />
                                        <div className="row">
                                            <div className="col-lg-5 informDiv">
                
                                                <input
                                                    style={{display: 'none'}}
                                                    type="file"
                                                    onChange={this.fileSelectedHandler}
                                                    ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                                    {...getInputProps()}
                                                    />
                                                <InputGroup>
                
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="basic-addon1"><i style={{fontSize : '14px'}} className="fas fa-file-signature boostrapFaIcons"/></InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl
                                                        className='boostrapInvertcornersLeft' //Sebas
                                                        placeholder="שם"
                                                        name="name" // FOR VALIDATOR
                                                        aria-label="שם"
                                                        onChange={(e)=>this.updateFormValue(e)}
                                                        value={this.state.name}
                                                        aria-describedby=""
                                                        />
                                                </InputGroup>
                                                <p className='validatorInputError'><ValidationMessage style={{margin: '0'}} field="name" /></p>
                                            </div>

                                            <div className="col-lg-5 informDiv">
                                                <InputGroup >
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="basic-addon1"><i  style={{fontSize : '14px'}} className="fas fa-search boostrapFaIcons"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl
                                                    name="fileCategory"
                                                    as="select"
                                                    value={this.state.files_category}
                                                    className='boostrapInvertcornersLeft' //Sebas
                                                    onChange={this.editHandlerSelectDefault}
                                                    >
                                                        <option>בחר קטגוריה...</option>
                                                        {pageData.files_categories.map(item => { if (true) {
                                                                /* currentState={this.state} */
                                                                return < option value ={item.id } key ={ item.id }> 
                                                                            {item.name}
                                                                        </ option >
                                                            } else return false}) }
                                                    </FormControl>
                                                </InputGroup>
                                            </div>
                
                                            <div className="col-lg-2 informDiv">
                                                <input
                                                    style={{display: 'none'}}
                                                    type="file"
                                                    name='inputFile' //For Validation
                                                    onChange={this.fileSelectedHandler}
                                                    ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                                    />
                                                <button className='btn btn-primary filePickUpBtn' style={{width : '100%', margin: '0px 0 0px 0' }} onClick={() => this.fileInput.click()} >בחר קובץ</button>
                                                <p className='validatorInputError'><ValidationMessage field="inputFile" /></p>
                                            </div>
                                        </div>
                                        </FormValidator></div> : <div>
                                                                    <p className="okUpload animate__animated animate__bounce">נשלח בהצלחה.</p>
                                                                    <button className="btn btn-primary" onClick={this.handleMoreFiles}>להעלות קובץ נוסף &raquo;</button>
                                                                </div>}
                
                                        { this.state.inputFile ? this.state.inputFile : false }
                                        {this.state.uploadError ? <p className="errorUpload"> {this.state.uploadError} </p>: ''}
                                        
                                        { this.state.uploadProgress ? <ProgressBar now={this.state.uploadProgress} /> : false }
                                    </div>
                                </div>
                                )}</Dropzone>

                                < FilesHandle loadData = { () => this.loadData() } dataFiles = {this.state.dataFiles} mail = {this.props.mail} filesOwner= {this.state.clientId ? 'client' : 'worker'} clientId = {this.state.clientId} workerId = {this.state.workerId} user = {this.props.infoSite.user.data} />
                            </div> : false }

                    </section>


                </div>
            
        )
    }
}
