import React, { useState, useEffect } from "react";
import { FormControl, Button } from "react-bootstrap";
import loader from "../../../../img/preLoader.gif";

import { toast } from "react-toastify"; //npm i react-toastify
import getDataFromApi from "../../../DinamicForm/newApi/getDataFromApi";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */

import SignatureCanvas from "react-signature-canvas";

import convertDate from "./../../../convertDateToDisplay";

import { ConstantsNames } from "./../../../Urls";

export default function ReportHome1SaveData(props) {
  const { responseData, formSavedData, idFormData, visitDate } = props;
  /* visitType, clientId, chosensocialWorker, visitDate, */

  //console.log(responseData);

  const fieldsForm = responseData.formRows;

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(
    defaultValueForm(fieldsForm, formSavedData)
  );

  const [isSaved, setIsSaved] = useState(false);
  const [user, setUser] = React.useState({});

  const [trimmedDataURL, setTrimmedDataURL] = useState(null);

  const [trimmedDataURL2, setTrimmedDataURL2] = useState(null);

  const submit = () => {
    //console.log(formData);
    //setStep(step + 1);

    const send = {
      idFormData: idFormData,
      formData: formData,
      visitDate: visitDate,
      sign: trimmedDataURL,
      sign2: trimmedDataURL2,
    };

    sendtoApi("updateFormData", "HomeVisits", send, setLoading, setIsSaved);
  };

  React.useEffect(() => {
    try {
      let userJ = JSON.parse(localStorage.getItem("user-info"));
      let user = userJ.data;
      setUser(user)
    } catch(e) {

    }
  }, [])

  const onChange = (e) => {
    //console.log(e.target.name, e.target.value);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    scroll.scrollTo(0, {
      smooth: false,
      delay: 0,
      duration: 0,
      spyThrottle: 0,
    });
  }, []);

  const endSign = () => {
    setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    //console.log('end');
  };

  const endSign2 = () => {
    setTrimmedDataURL2(sigPad2.getTrimmedCanvas().toDataURL("image/png"));
    //console.log('end');
  };

  const onBegin = () => {
    //console.log('onBegin');
  };

  let sigPad = trimmedDataURL;

  let sigPad2 = trimmedDataURL2;

  const clear = () => {
    setTrimmedDataURL(null);
    sigPad.clear(sigPad);
  };

  const clear2 = () => {
    setTrimmedDataURL2(null);
    sigPad2.clear(sigPad);
  };

  /* const trim = () => {

        setTrimmedDataURL(
            sigPad.getTrimmedCanvas().toDataURL('image/png')
        );
    } */

  /* useEffect(() => {

        console.log(trimmedDataURL);

    }, [trimmedDataURL]); */

  const isDone = (e) => {
    const send = {
      idFormData: idFormData,
      formData: formData,
      sign: trimmedDataURL,
      sign2: trimmedDataURL2,
      isDone: "1",
    };

    sendtoApi("updateFormData", "HomeVisits", send, setLoading, setIsSaved);
  };

  //console.log('isSaved', isSaved);

  return (
    <div className="ReportHomeSaveData">
      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      {/* <h1>{idFormData}</h1> */}

      <section className="sectionForm">
        <h2>המעסיק</h2>

        {fieldsForm.map((item) => {
          if (parseInt(item.sort) < 910 && parseInt(item.sort) > 790) {
            /* currentState={this.state} */
            return (
              <div className="line" key={item.sort}>
                <header>
                  <h3>{item.explain}</h3>
                  <p>{item.options.choices}</p>
                </header>

                <FormControl
                  disabled={responseData?.is_done === "1"}
                  as="textarea"
                  onChange={onChange}
                  name={item.name}
                  value={formData[item.name]}
                />
              </div>
            );
          } else return false;
        })}
      </section>

      <section className="sectionForm">
        <h2>דיווח על העובד הזר</h2>

        {fieldsForm.map((item) => {
          if (parseInt(item.sort) < 790 && parseInt(item.sort) > 710) {
            /* currentState={this.state} */
            return (
              <div className="line" key={item.sort}>
                <header>
                  <h3>{item.explain}</h3>
                  <p>{item.options.choices}</p>
                </header>

                <FormControl
                  disabled={responseData?.is_done === "1"}
                  as="textarea"
                  onChange={onChange}
                  name={item.name}
                  value={formData[item.name]}
                />
              </div>
            );
          } else return false;
        })}
      </section>

      <section className="sectionForm" style={{ marginBottom: "5px" }}>
        <h2>
          תנאי העסקת העו"ז <small>(על העו"ס לבדוק את תנאי העסקת העו"ז)</small>
        </h2>

        {fieldsForm.map((item) => {
          if (parseInt(item.sort) < 720 && parseInt(item.sort) > 600) {
            /* currentState={this.state} */
            return (
              <div className="line" key={item.sort}>
                <header>
                  <h3>{item.explain}</h3>
                  <p>{item.options.choices}</p>
                </header>

                <FormControl
                  disabled={responseData?.is_done === "1"}
                  as="textarea"
                  onChange={onChange}
                  name={item.name}
                  value={formData[item.name]}
                />
              </div>
            );
          } else return false;
        })}
      </section>

      <p className="smallText">
        * יש להסביר למעסיק או לנציג מטעמו כי עליו לשלם את שכר העובד לחשבון בנק
        שנפתח על שמו של העובד בלבד.
      </p>

      <section className="sectionForm" style={{ marginTop: "30px" }}>
        <h2>
          תכנית טיפול{" "}
          <small>
            (על העובד הסוציאלי לשקול תכנית טיפולית תואמת לאור הנתונים אשר נצפו
            במהלך המפגש.)
          </small>
        </h2>

        {fieldsForm.map((item) => {
          if (parseInt(item.sort) < 610 && parseInt(item.sort) > 490) {
            /* currentState={this.state} */
            return (
              <div className="line" key={item.sort}>
                <header>
                  <h3>{item.explain}</h3>
                  <p>{item.options.choices}</p>
                </header>

                <FormControl
                  disabled={responseData?.is_done === "1"}
                  as="textarea"
                  onChange={onChange}
                  name={item.name}
                  value={formData[item.name]}
                />
              </div>
            );
          } else return false;
        })}
      </section>

      <section className="lastSec">
        <h2>
          הנני לאשר כי קראתי את הדו"ח הנ”ל והנני לאשר כי הביקור התבצע בהתאם
          לנהלים ולסטנדרטים המקצועיים המקובלים
        </h2>

        <div className="contLast clear">
          <div className="line">
            <header>
              <h3 style={{ fontSize: "20px" }}>תאריך הביקור</h3>
              <p></p>
            </header>

            {/*  */}

            <FormControl
              /* as="textarea"  */
              disabled={true}
              value={convertDate(visitDate)}
            />
          </div>

          <div className="signCont">
            <h3>חתימה ע"ס</h3>

            {console.log("responseData >", responseData)}

            {!responseData?.signData ? (
              <>
                <SignatureCanvas
                  ref={(ref) => {
                    sigPad = ref;
                  }}
                  // Pre fill sign:
                  //ref={(ref) => { ref.fromDataURL(trimmedDataURL) }}
                  onEnd={endSign}
                  onBegin={onBegin}
                  backgroundColor="white"
                  penColor="black"
                  canvasProps={{
                    width: 320,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <div>
                  <button onClick={clear}>מחיקת חתימה</button>
                  <div>
                    <p style={{ margin: "10px 0 0 0" }}>
                      {responseData?.socialWorkers?.name}
                    </p>
                    מ.ר: {responseData?.socialWorkers?.licenseNumber}
                  </div>
                </div>
              </>
            ) : (
              <div>
                <img
                  className="singResp"
                  src={responseData.signData}
                  alt="חתימה"
                />
                <div>
                  <p style={{ margin: "10px 0 0 0" }}>
                    {responseData?.socialWorkers?.name}
                  </p>
                  מ.ר: {responseData?.socialWorkers?.licenseNumber}
                </div>
              </div>
            )}
          </div>

          <div className="signCont sign2">
            <h3>לקוח/בן משפחה</h3>

            {!responseData?.signData2 ? (
              <>
                <SignatureCanvas
                  ref={(ref) => {
                    sigPad2 = ref;
                  }}
                  // Pre fill sign:
                  //ref={(ref) => { ref.fromDataURL(trimmedDataURL) }}
                  onEnd={endSign2}
                  //onBegin={onBegin}
                  backgroundColor="white"
                  penColor="black"
                  canvasProps={{
                    width: 320,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
                <div>
                  <button onClick={clear2}>מחיקת חתימה</button>
                </div>
              </>
            ) : (
              <div>
                <img
                  className="singResp"
                  src={responseData.signData2}
                  alt="חתימה"
                />
              </div>
            )}
          </div>

          {responseData?.signs?.fileName && (
            <div className="signCont">
              {" "}
              {/* style={{marginTop: '50px',display: 'block'}} */}
              <h3>
                חתימה עובד/ת סוציאלי/ת אחראי/ת dddddd
                {responseData.signs.fileName}
              </h3>
              {
                <img
                  className="singResp"
                  src={`${ConstantsNames.files}signs4Excell/${responseData.signs.fileName}`}
                  alt="חתימה"
                />
              }
            </div>
          )}
        </div>

        {(isSaved || formSavedData) && (
          <div className="goBackLink">
            <a
              className="goBackCustomBtn bounce"
              href={"/ביקורי_בית?id=" + responseData.clientId}
              target="_self"
            >
              &laquo; חזרה לכל הביקורים
            </a>
          </div>
        )}
      </section>

      {responseData?.is_done !== "1" && (
        <section className="markAsDone">
          <button onClick={isDone}>סיום ביקור</button>
        </section>
      )}

      {/* {!formSavedData && */}
      {(responseData?.is_done !== "1" || (
        (user?.userCredential === "superAdmin" ||
        user?.userCredential === "admin" ||
        user?.userCredential === "coordinator")
      )) && <Button
        className="bounce1 sendBtn"
        onClick={submit}
        size=""
        variant="success"
      >
        שמירה
      </Button>}
    </div>
  );
}

function defaultValueForm(fieldsForm, formSavedData) {
  let fields = {};

  //console.log('fieldsForm', fieldsForm);
  //console.log('formSavedData', formSavedData);

  if (formSavedData) {
    fieldsForm.map(
      (item) =>
        (fields[item.name] = formSavedData[item.name]
          ? formSavedData[item.name]
          : "")
    );
  } else {
    fieldsForm.map((item) => (fields[item.name] = ""));
  }

  //console.log(fields);

  return fields;
}

function sendtoApi(url, controller, objectToSend, setLoading, setIsSaved) {
  setLoading(true);
  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    //console.log('getData', getData);

    if (getData?.is_done) {
      window.open("/ביקורי_בית", "_self");
    } else if (getData.ok) {
      toast.success(getData.ok);
      setIsSaved(true);
    } else if (getData.warning) {
      toast.warning(getData.warning);
    } else {
      toast.error("שגיאה");
    }
  });

  getData.catch((error) => {
    console.log(error);
  });

  getData.finally(() => {
    //scroll.scrollToBottom();
    setLoading(false);
  });
}
