import React from 'react'
import { Fragment } from 'react'
import { Button, Modal } from "react-bootstrap";
import { map } from 'lodash'; //find, map,
import { useState } from 'react';
import { toast } from 'react-toastify';
import getDataFromApi from '../../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../../img/preLoader.gif";

export default function ShowComments(props) {

    const { commentsLog,  action,seniorId,workerId, setCommentsLog } = props

    const [allCommntsModal, setAllCommntsModal] = useState(false);

    let counterComments = 0;

    const [ loading, setLoading] = useState(false);

    const deletteMe = (item) => { 

        const sendData = {

            action : action,
            seniorId : seniorId,
            workerId : workerId,
            rowId : item.id

        }

        sendtoApi(
            "deletteComment",
            "first",
            sendData,
            setLoading,
            setCommentsLog,
            
        );

    }

  return (
    <Fragment>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        <div className="tableComments SeniorContacts">
            
            <div className="seniorsTableContact">

                <div className="trTable titlesTr">
                    <div className="tdTable">תאריך</div>
                    <div className="tdTable">שם פותח</div>
                    <div className="tdTable">תוכן ההערה</div>
                </div>

                <div className="trTable contentTds">
                    <div className="vSpace"></div>
                </div>

                { map(  commentsLog, item => {
                    
                    counterComments++;

                    if (counterComments < 3) {
                    
                        return (
                            <div key={item.id} className="trTable contentTds" >
                                <div className="col-md-2 divBoostrap col-sm-12 date">
                                    <Button style={{padding : '5px 10px', margin: '0 0 0 0px'}} className="addBtn deletteBtn" onClick={() => deletteMe(item)} variant="secondary" size="sm"><i className="far fa-trash-alt" /></Button>
                                    <p>{item.created}</p>
                                </div>
                                <div className="col-md-2 divBoostrap col-sm-12 name">
                                    <p>{item.open_name}</p>
                                </div>
                                <div className="col-md-2 divBoostrap col-sm-12 name">
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        );
                    };

                })}
            </div>
        

            {commentsLog.length > 2 && (
                <Button
                    className="allCommntsBtn"
                    onClick={() => setAllCommntsModal(true)}
                    variant="light"
                >
                    לכל ההערות
                </Button>
                )}

            <Modal
                className="animate__animated animate__fadeInDown animate__faster modalAllComments"
                animation={false}
                onHide={ () => setAllCommntsModal(false)}
                show={ allCommntsModal }
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                <Modal.Title id="example-custom-modal-styling-title">
                    <h2 className="titStyle">הערות לקוח</h2>
                </Modal.Title>
                <Button
                    onClick={ () => setAllCommntsModal(false) }
                    variant="secondary"
                >
                    X
                </Button>
                </Modal.Header>

                <Modal.Body>
                <ul>
                    { map(  commentsLog, item => {
                    if (true) {
                        
                        return (
                        <li key={item.id} className="trTable contentTds">
                            <p>
                            <strong>תאריך: </strong>
                            {item.created}{' '}
                            <strong>שם פותח: </strong>
                            <Button style={{padding : '5px 10px', margin: '0 0 0 0px'}} className="addBtn deletteBtn" onClick={() => deletteMe(item)} variant="secondary" size="sm"><i className="far fa-trash-alt" /></Button>
                            {item.open_name}
                            </p>
                            <p>
                            <strong>הערה: </strong>
                            {item.name}
                            </p>
                        </li>
                        );
                    } else return false;
                    })}
                </ul>
                </Modal.Body>

            </Modal>
                
        </div>

        
    </Fragment>
  )
}

function sendtoApi(
    url,
    controller,
    objectToSend,
    setLoading,
    setResponseData,
  ) {

    setLoading(true);
  
    //console.log(url);
  
    const getData = getDataFromApi(url, objectToSend, controller, "all");
  
    getData.then((getData) => {

      setLoading(false);

      if (getData.error) {
        toast.error(getData.error, {
          toastId: "responseError",
        });
        
      }

      if (getData.ok) {

        toast.success(getData.ok);

        if (getData?.data) {
            setResponseData(getData?.data);
        } else {
            setResponseData([]);
        }
        
      } else {

        toast.error("שגיאה", {
          toastId: "genericError",
        });

      }

    });
  
    getData.catch((error) => {
      console.log(error);
    });
  

  }