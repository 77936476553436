import React, { useEffect, useState } from 'react'

import { map,range } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function Humanitarit(props) {

    const { setMoreDataPdf, sendForm,  responseData } = props


    const inputsBuilder = [

        {
            'name' : 'client1',
            'label' : 'סה"כ עו"ז שהועסקו על ידי המטופל',
            'defaultValue': '',
            
        },
        {
            'name' : 'client2',
            'label' : 'סיבות סיום העסקה',
            'defaultValue': '',
            
        },
        {
            'name' : 'demandant1',
            'label' : 'שם',
            'defaultValue': '',
            
        },
        {
            'name' : 'demandant2',
            'label' : 'ת"ז',
            'defaultValue': '',
            
        },
        {
            'name' : 'demandant3',
            'label' : 'קרבה למטופל',
            'defaultValue': '',
            
        },
        {
            'name' : 'demandant4',
            'label' : 'כתובת',
            'defaultValue': '',
            
        },
        {
            'name' : 'demandant5',
            'label' : 'טלפון נייד',
            'defaultValue': '',
            
        },
        {
            'name' : 'demandant6',
            'label' : 'טלפון נוסף',
            'defaultValue': '',
            
        },

        {
            'name' : 'worker1',
            'label' : 'נתינות',
            'defaultValue': '',
            
        },

        {
            'name' : 'worker2',
            'label' : 'סה"כ מעסיקים בעבר',
            'defaultValue': '',
            
        },

        {
            'name' : 'worker3',
            'label' : 'סיבות סיום העסקה',
            'defaultValue': '',
            
        },


        {
            'name' : 'doc1',
            'label' : 'חוות דעת...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
       
        {
            'name' : 'doc2',
            'label' : 'מכתב בחתימת הרופא...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc3',
            'label' : 'טופס ויתור על סודיות',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc4',
            'label' : 'המטופל הינו חסוי...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc5',
            'label' : 'תצהיר העובד הזק...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc6',
            'label' : 'מכתב הסבר מנותק...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        
        
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder, responseData));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    

    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);
    

  return (
    <div>

        <h3>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            
        {range(0, 2).map((current, index, range) => {

            return <Grid item md={6}  key = {current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        <br/>
        <h3>פרטי מגיש הבקשה</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            
        {range(2, 8).map((current, index, range) => {

            return <Grid item md={4}  key = {current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        <br/>
        <h3>פרטי עובד הזר</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            
        {range(8, 11).map((current, index, range) => {

            return <Grid item md={4}  key = {current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        <br/>
        <h3>מסמכים שיש לצרף לבקשה</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            
        {range(11, 17).map((current, index, range) => {

            return <Grid item md={6} key = {current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[current].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

                </Grid>
            })}

        </Grid>

        
        

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder,savedData) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = savedData[item.name] ?  savedData[item.name] : item.defaultValue
        
    })
    
    return objReturn;

}

