import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import { InputGroup, FormControl, Button } from "react-bootstrap";
import { toast } from "react-toastify"; //npm i react-toastify
import getDataFromApi from "../../../DinamicForm/newApi/getDataFromApi";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import Loading from "../../../-HELPERS-/Loading";
registerLocale("he", he);

function convertDate(dateValue) {
  let current_datetime = new Date(dateValue.toString().replace(/-/g, "/")); // IPHONE FIX

  /* 2021-03-25 */

  let formatted_date =
    current_datetime.getFullYear() +
    "-" +
    (current_datetime.getMonth() + 1) +
    "-" +
    current_datetime.getDate();

  return formatted_date;
}

export default function SyncMain(props) {
  const { workerTz, clientTz, screen, metapelCode } = props;

  let today = new Date();
  let current_datetime = new Date(today.toString().replace(/-/g, "/"));

  const [response, setRequest] = useState("");
  const [loading, setLoading] = useState(false);

  const [dateSync, setDateSync] = useState(current_datetime);

  const [tz, setTz] = useState(clientTz ? clientTz : "");
  const [metapelCodeInput, setMetapelCodeInput] = useState(
    metapelCode ? metapelCode : ""
  );

  const [responseHash, setResponseHash] = useState(false);

  const dateSyncSend = () => {
    const send = {
      dateSync: convertDate(dateSync),
    };

    sendtoApi("syncDate", "AsApi", send, setLoading, setRequest);
  };

  const seniorSync = () => {
    const send = {
      tz: tz,
    };

    //console.log(send);
    sendtoApi("syncClient", "AsApi", send, setLoading, setRequest, props);
  };

  const workerSync = () => {
    const send = {
      metapelCode: metapelCodeInput,
      //   metapelCode: metapelCode ? metapelCode : "",
    };

    //console.log(send);
    sendtoApi("syncWorker", "AsApi", send, setLoading, setRequest, props);
  };

  //console.log('response',response);
  const checkNoCreatedInvoice = () => {
    const send = {
      frontRequest: "1",
    };

    sendtoApi(
      "checkNoCreatedInvoice",
      "pays",
      send,
      setLoading,
      setResponseHash
    );
  };

  //console.log('responseHash', responseHash);

  return (
    <>
      {screen && <Loading open={loading} noBackdrop={true} />}

      {clientTz && (
        <div className="syncCont">
          <Loading open={loading} noBackdrop={true} />
          <Button
            variant="success"
            disabled={!tz}
            size="sm"
            onClick={seniorSync}
          >
            סינכרון
          </Button>
        </div>
      )}

      {workerTz && (
        <div className="syncCont">
          <Loading open={loading} noBackdrop={true} />
          <Button
            variant="success"
            disabled={!metapelCodeInput}
            size="sm"
            onClick={workerSync}
          >
            סינכרון
          </Button>
        </div>
      )}

      {!workerTz && !clientTz && (
        <div className="SyncMain">
          <div className="syncDate">
            {!screen && (
              <>
                <h4>סינכרון לפי תאריך</h4>

                <Loading open={loading} noBackdrop={true} />

                {/* <Grid className='lineForm' container spacing={2}  alignItems="center"  justifyContent="center" direction="row" > */}
                <Grid
                  className="lineForm"
                  container
                  spacing={2}
                  direction="row"
                >
                  <Grid item md={4}>
                    <InputGroup className="customInput dateInput">
                      <div className="dateTimeCont">
                        <div className="customPrepend">בחירת תאריך</div>
                        <DatePicker
                          showYearDropdown={true}
                          selected={dateSync}
                          dateFormat="dd/MM/yy"
                          className="inputPrepend form-control"
                          name="dateSync"
                          locale="he"
                          onChange={(date) => setDateSync(date)}
                          /* disabled={this.props.is_disabled ? true : false} */
                        />
                      </div>
                    </InputGroup>
                  </Grid>

                  <Grid item md={4}>
                    <Button variant="success" size="" onClick={dateSyncSend}>
                      שליחה
                    </Button>
                  </Grid>

                  <Grid item md={4}>
                    {response && (
                      <ul>
                        <li>
                          <strong>הוספות:</strong>{" "}
                          <span>{response.inserts}</span>
                        </li>
                        <li>
                          <strong>שינוים:</strong>{" "}
                          <span>{response.updates}</span>
                        </li>
                      </ul>
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            {!screen && (
              <>
                <h4>סינכרון חשבוניות לפי תשלום מוצלח</h4>

                <Loading open={loading} noBackdrop={true} />

                {/* <Grid className='lineForm' container spacing={2}  alignItems="center"  justifyContent="center" direction="row" > */}
                <Grid
                  className="lineForm"
                  container
                  spacing={2}
                  direction="row"
                >
                  <Grid item md={4}>
                    <Button
                      variant="success"
                      size=""
                      onClick={checkNoCreatedInvoice}
                    >
                      שליחה
                    </Button>
                  </Grid>

                  <Grid item md={4}>
                    {responseHash?.count && (
                      <ul>
                        <li>
                          <strong>הוספות:</strong>{" "}
                          <span>{responseHash?.count}</span>
                        </li>
                      </ul>
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            {(!screen || screen === "client") && (
              <>
                <h4>סינכרון / הוספת לקוח/ה</h4>

                <Grid
                  className="lineForm"
                  container
                  spacing={2}
                  direction="row"
                >
                  <Grid item md={4}>
                    <InputGroup className="customInput">
                      <InputGroup.Prepend className="inputPrependLabel">
                        <InputGroup.Text id="basic-addon3">
                          ת.ז. לקוח
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                      <FormControl
                        className="inputPrepend"
                        onChange={(event) => setTz(event.target.value)}
                        /* name={dataForm.name}  */
                        value={tz}
                        disabled={false}
                      />
                    </InputGroup>
                  </Grid>

                  <Grid item md={4}>
                    <Button
                      variant="success"
                      disabled={!tz}
                      size=""
                      onClick={seniorSync}
                    >
                      שליחה
                    </Button>
                  </Grid>

                  {screen && (
                    <Grid item md={4}>
                      {response && (
                        <ul>
                          <li>
                            <strong>הוספות:</strong>{" "}
                            <span>{response.inserts}</span>
                          </li>
                          <li>
                            <strong>שינוים:</strong>{" "}
                            <span>{response.updates}</span>
                          </li>
                        </ul>
                      )}
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {(!screen || screen === "worker") && (
              <>
                <h4>סינכרון / הוספת עובד/ת</h4>

                <Grid
                  className="lineForm"
                  container
                  spacing={2}
                  direction="row"
                >
                  <Grid item md={4}>
                    <InputGroup className="customInput">
                      <InputGroup.Prepend className="inputPrependLabel">
                        <InputGroup.Text id="basic-addon3">
                          מספר מטפל
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                      <FormControl
                        className="inputPrepend"
                        onChange={(event) =>
                          setMetapelCodeInput(event.target.value)
                        }
                        /* name={dataForm.name}  */
                        value={metapelCodeInput}
                        disabled={false}
                      />
                    </InputGroup>
                  </Grid>

                  <Grid item md={4}>
                    <Button
                      variant="success"
                      disabled={!metapelCodeInput}
                      size=""
                      onClick={workerSync}
                    >
                      שליחה
                    </Button>
                  </Grid>

                  {screen && (
                    <Grid item md={4}>
                      {response && (
                        <ul>
                          <li>
                            <strong>הוספות:</strong>{" "}
                            <span>{response.inserts}</span>
                          </li>
                          <li>
                            <strong>שינוים:</strong>{" "}
                            <span>{response.updates}</span>
                          </li>
                        </ul>
                      )}
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function sendtoApi(
  url,
  controller,
  objectToSend,
  setLoading,
  setRequest,
  props = false
) {
  //console.log(props);

  setLoading(true);
  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    //console.log(getData);

    setRequest(getData.resultsOutput);

    if (getData?.ok) {
      //REFRESH
      if (props?.workerTz || props?.clientTz) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (getData.resultsOutput?.lastInsertId && url === "syncClient") {
        //312062060

        window.open("/לקוחות?id=" + getData.resultsOutput?.lastInsertId);
      } else if (getData.resultsOutput?.lastInsertId && url === "syncWorker") {
        //P5942944B
        window.open("/עובדים?id=" + getData.resultsOutput?.lastInsertId);
      }

      toast.success(getData.ok);
    } else if (getData?.count) {
      toast.success("סונכרן בהצלחה");
      setRequest(getData);
    } else if (getData?.warning) {
      toast.warning(getData.warning);
    } else {
      toast.error("שגיאה");
    }
  });

  getData.catch((error) => {
    console.log(error);
  });

  getData.finally(() => {
    setLoading(false);
  });
}
