import React, { Component } from 'react'
import { Accordion , Card , Button } from 'react-bootstrap';

import vPic from "./../../../../img/icons/v.png";
import xPic from "./../../../../img/icons/x.png";

export default class Match extends Component {
    render() {

        //let Button = <Button>sebas</Button>;
        let pageData = this.props.pageData;

        //console.log(pageData);

        return (

            <div className="matchPage">

                <h2 className="titStyle ">התאמה</h2>

                {pageData.matchList.length > 0 ? <section className="matchList">
                    <div className="tableList">
                        <div className="rowTable titles">
                            <div className="tdTable">לקוחות</div>
                            <div className="tdTable">עובדים</div>
                            <div className="tdTable">התאמות</div>
                            <div className="tdTable"></div>
                        </div>
                    </div>

                        <div className="dropdown">
                            <div className="tdTable">
                            
                            {pageData.matchList.map(item => { if (true) {
                                    return <div className="AccordionTd" key={item.worker.workerId + '_' + item.client.seniorId } >
                                    <Accordion>
                                        <Card>
                                            <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            
                                                <div className="tableList" >
                                                    <div className="rowTable">
                                                        <div className="tdTable">{item.client.nameClient}</div>
                                                        <div className="tdTable">{item.worker.workerName}</div>
                                                        <div className="tdTable">
                                                            <span>{item.worker.points}/4</span>
                                                            <a className="viewClienBtn" style={{margin : '0 10px 0 0'}} href={'לקוחות?id=' + item.client.seniorId}>
                                                                <span>לצפייה בלקוח  &raquo;</span>
                                                            </a>
                                                        </div>
                                                        <div className="tdTable"><i className="fas fa-chevron-down"></i></div>
                                                    </div>
                                                </div>

                                            </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="results">
                                                    <ul>
                                                        <li>
                                                            <img src={item.worker.from === 0 ? xPic : vPic } alt="icon" />
                                                            <span>ארץ מוצא</span>
                                                        </li>
                                                        <li>
                                                            <img src={item.worker.langRequest === 0 ? xPic : vPic } alt="icon" />
                                                            <span>שפה</span>
                                                        </li>
                                                        <li>
                                                            <img src={item.worker.sexMatch === 0 ? xPic : vPic } alt="icon" />
                                                            <span>מין</span>
                                                        </li>
                                                        <li>
                                                            <img src={item.worker.countryArea === 0 ? xPic : vPic } alt="icon" />
                                                            <span>מיקום בארץ</span>
                                                        </li>
                                                    </ul>
                                                    
                                                </div>
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div> } else return false}) }
                            </div>
                        </div>
                </section> : <h2>אין תוצאות</h2> }
                
            </div>
        )
    }
}
