import React, { Component } from 'react'
import { Button, Modal, Alert } from 'react-bootstrap';
import InputMet from './InputMet';
import getDataFromApi from "./../../../../DinamicForm/getDataFromApi";
import validator from "validator";

import loader from "../../../../../img/preLoader.gif"
import { toast } from 'react-toastify';

export default class ModalSendMailWorker extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            pdfMailResponse : false,
            mailAddresses : '',
            errors : false,
            loading: false
        }
    }

    // INPUT
    submit = (event) => {

        //console.log(event);

        this.setState(event,
            function(){
                //console.log(this.state);
            }
        );
        
    }

    

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData, loading : false }, function(){

            if(url === 'sendPdfMailWorker') {

                if(getData?.sendMail) {

                    toast.success('המייל נשלח בהצלחה');
                    this.props.modalShow(false);

                } else {

                    toast.error('שגיאה בשליחת המייל');

                }

                

            }

        }));
    }

    sendMail = () => {
        
        var addresses = this.state.mailAddresses.split(',');
        let errors = [];

        if (!this.state.mailAddresses) {
            errors.push('אין כתובות מייל לשליחה');
        } else {

            addresses.forEach(function(mail){

                if(!validator.isEmail(mail)) {
                    errors.push(mail);
                }
                
                /* else if (!validator.isEmpty(mail)) {
                    errors.push('אין כתובות מייל לשליחה');
                } */

            });

        };

        if(errors[0]) {

            this.setState({errors : errors})

        } else {

            this.setState({ errors : false,  loading : true })
            
            this.sendtoAdmin('sendPdfMailWorker','pays', {mails : this.state.mailAddresses, invoiceId : this.props.currentState.invoiceId },'pdfMailResponse');

        }
        
    }

    render() {
        return (
            <Modal
                className='animate__animated animate__fadeInDown animate__faster modalAllComments modalMails'
                animation={false}
                onHide={() => this.props.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.props.currentState.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h2 className="titStyle">שליחת מסמכים במייל</h2>
                    </Modal.Title>
                    <Button onClick={() => this.props.modalShow(false)} variant="secondary" >X</Button>
                </Modal.Header>
                <Modal.Body>

                    <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
                    
                        <div className="sendMailForm">

                            
                            <label>כתובת מייל לשליחה</label>
                            <InputMet className='' placeholder="mail2@mail.com,mail1@mail.com" text='למספר כתובות הפרידו בפסיקים' as={false} value={this.state.mailAddresses} name='mailAddresses' submit={this.submit} />

                            {this.state.errors[0] ? <Alert variant="danger">

                                <h2>שגיאה בכתובת מייל: </h2>

                                {this.state.errors.map(item => { if (true) {
                                        return <p key={item} style={{fontSize: '16px', margin: '0 0 20px 0'}}>{item}</p>
                                } else return false}) }

                            </Alert> : false }

                            <Button disabled={ this.state.loading ? true : false } onClick={this.sendMail} size="lg" variant="success" >שליחת מייל/ים</Button>

                        </div>
                </Modal.Body>
            </Modal>
        )
    }
}

