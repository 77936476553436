import React, { useEffect, useState } from 'react'

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';
import InputMet from '../../Finance/InputMet';

export default function HolidayPermit(props) {

    const { setMoreDataPdf, sendForm } = props

    let date = new Date();
    let current_datetime = new Date(date.toString().replace(/-/g, '/')); // IPHONE FIX

    const inputsBuilder = [
        {
            'name' : 'text1',
            'label' : '',
            'defaultValue': '',
            
        },
        {
            'name' : 'text2',
            'label' : '',
            'defaultValue': '',
            
        },
        {
            'name' : 'date1',
            'label' : 'י/תצא לחופשה מתאריך',
            'defaultValue': current_datetime,
            
        },
        {
            'name' : 'date2',
            'label' : 'עד תאריך',
            'defaultValue': current_datetime,
            
        },
        {
            'name' : 'text3',
            'label' : '',
            'defaultValue': '',
            
        },
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    const submitDate = (date) => { 

        const keys = Object.keys( date );
        const dateFieldName = keys[0];

        //console.log(key[0]);

        setFormData({
            ...formData,
            [dateFieldName]: date[dateFieldName],
        })


     }


    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);
    

  return (
    <div>

        <h3 style={{margin: '0 0 30px 0'}}>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            <Grid item md={12} >

                <header>
                    <h4>אני הח"מ</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[0].name}
                    value={formData[inputsBuilder[0].name]}
                />
            </Grid>

            <Grid item md={12} >

                <header>
                    <h4>ת.ז.</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[1].name}
                    value={formData[inputsBuilder[1].name]}
                />
            </Grid>

            <Grid item md={6} >
                <InputMet text={inputsBuilder[2].label} as={'dateInput'} value={ formData[inputsBuilder[2].name] } placeholder='' name={inputsBuilder[2].name} submit={submitDate} />
            </Grid>

            <Grid item md={6} >
                <InputMet text={inputsBuilder[3].label} as={'dateInput'} value={ formData[inputsBuilder[3].name] } placeholder='' name={inputsBuilder[3].name} submit={submitDate} />
            </Grid>

            <Grid item md={12} >

                <header>
                    <h4>טלפון</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[4].name}
                    value={formData[inputsBuilder[4].name]}
                />

            </Grid>

        </Grid>

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = item.defaultValue
        
    })
    
    return objReturn;

}

