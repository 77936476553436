import React, { Component } from 'react'

import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
import { Button,  Modal } from 'react-bootstrap';
import OpenTaskReportModalBody from './OpenTaskReportModalBody';
//import { InputGroup, FormControl } from "react-bootstrap";

function textFromId(id,choisesFromTable) {

    if(id) {
        let nameFromId = choisesFromTable.subjectReport.filter(function(desc) { 
            return desc.data.id === id.toString();
        });
    
        return nameFromId[0].data.name;
    } else {
        return false;
    }
    
}

export default class NewReport extends Component {


    
    constructor(props) {

        let today = new Date();
        let current_datetime = new Date(today.toString().replace(/-/g, '/'));  

        super(props);
        this.state = {            
            dateReport : current_datetime,
            subjectReport : false,
            //causeReport : ''
        }

    }

    //GET INPUT VALUES FROM ADMINiNPUTS
    submit = (state) => {
        this.setState(state);
    }

    newTask = (value) => {
        
        //console.log('IN TASK');
        this.setState({ modalShow : value})

        if(!value) {
            
            this.props.reloadHistory();

        }
        
    }

    editHandlerSelect = (event) => {
        
          this.setState({ [event.target.name] : event.target.value });
          
    };

    render() {

        return (
            <div className="newReport">

                <div className="contNewReport clear">
                    <div className="row formContainerAdmin">
                        {this.props.form.map(item => { if (true) {
                            /* currentState={this.state} */
                            return <InputFormAdmin
                                        platform=''
                                        Prepend={true}
                                        user=''
                                        methodName='clients'
                                        updateGetAction=''
                                        data={item}
                                        rowsOption4Select = {this.props.rowsOption4Select}
                                        dataParam={false}
                                        submit={this.submit }
                                        key={item.name} />
                        } else return false}) }
                    </div>

                    {/* {this.state.subjectReport === '17' && <div>

                        <div  className="col-xl-10 divBoostrap col-lg-12 divBoostrap col-sm-12 " style={{margin: '0 -10px 20px 0',float: 'right'}}>
                            
                            <InputGroup className="customInput">
                                <InputGroup.Prepend className="inputPrependLabel">
                                
                                <InputGroup.Text id="basic-addon3">סיבת סיום עבודה</InputGroup.Text>

                                </InputGroup.Prepend>
                                <FormControl
                                    as="select"

                                    className="inputPrepend selectPrepend"
                                    onChange={ this.editHandlerSelect }
                                    name='causeReport'
                                    value={this?.state?.causeReport}
                                    id="basic-url"
                                    aria-describedby="basic-addon3"
                                >
                                
                                    <option>בחר</option>
                                    <option value='פיטורין'>פיטורין</option>
                                    <option value='התפטרות'>התפטרות</option>
                                    <option value='פטירה'>פטירה</option>
                                    <option value='מעבר למוסד / בית אבות'>מעבר למוסד / בית אבות</option>
                                
                                </FormControl>
                            </InputGroup>

                        </div>
                        
                    </div>} */}

                    <Button className="addRecordBtn"  onClick ={()=>this.newTask(true)} disabled={this.state.subjectReport ? false : true } variant="primary" >פתיחת דיווח</Button>
                </div>

                {/* https://react-bootstrap.netlify.app/components/modal/ */}
                <Modal
                        className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                        animation={false}
                        onHide={() => this.newTask(false)}
                        /* size="sm" // בגודל קטן!!! */
                        /* size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalShow}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <span>דיווח למתש</span>
                            </Modal.Title>
                            <Button onClick={() => this.newTask(false)} variant="secondary" >X</Button>
                        </Modal.Header>
                        <Modal.Body>
                            <OpenTaskReportModalBody closeModal={() => this.newTask(false)} currenId= {this.props.clientId} idMatash={this.state.subjectReport} workerId={false} text={textFromId(this.state.subjectReport,this.props.rowsOption4Select)} careDate={this.state.dateReport} optionsTaskTypes={this.props.taskTypes} optionsStatusTasks={this.props.tasksStatus} />
                        </Modal.Body>
                    </Modal>
            </div>
        )
    }
}
