import React, { useEffect, useState } from 'react'

import { map,range } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function ClientReport(props) {

    const { setMoreDataPdf, sendForm,  responseData } = props


    const inputsBuilder = [

        {
            'name' : 'workerAddress',
            'label' : 'רחוב',
            'defaultValue': '',
            
        },


        {
            'name' : 'workerAddressHome',
            'label' : 'מספר בית',
            'defaultValue': '',
            
        },


        {
            'name' : 'workerAddressEnter',
            'label' : 'כניסה',
            'defaultValue': '',
            
        },


        {
            'name' : 'workerAddressDira',
            'label' : 'דירה',
            'defaultValue': '',
            
        },


        {
            'name' : 'workerAddressMikud',
            'label' : 'מיקוד',
            'defaultValue': '',
            
        },

        {
            'name' : 'days',
            'label' : 'ימי עבודה בשבוע',
            'defaultValue': '',
            
        },

        {
            'name' : 'hours',
            'label' : 'שעות עבודה ביום',
            'defaultValue': '',
            
        },

        {
            'name' : 'pay',
            'label' : 'סכום',
            'defaultValue': '',
            
        },

       
        
        
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder, responseData));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    

    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);
    

  return (
    <div>

        <h3>כתובת:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            
        {range(0, 5).map((current, index, range) => {

            return <Grid item md={4}  key = {current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        <br/>
        <h3>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            
        {range(5, 8).map((current, index, range) => {

            return <Grid item md={4}  key = {current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        
        

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder,savedData) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = savedData[item.name] ?  savedData[item.name] : item.defaultValue
        
    })
    
    return objReturn;

}

