import React, { useEffect, useState } from 'react'
import getDataFromApi from '../../../../DinamicForm/newApi/getDataFromApi';


import { isEmpty } from 'lodash'; //find, map,
import { toast } from 'react-toastify';
import ShowComments from './ShowComments';
import loader from "../../../../../img/preLoader.gif";
import InsertComment from './InsertComment';

export default function CommentsIndex(props) {

    const { action,seniorId,workerId } = props

    const [ loading, setLoading] = useState(false);
    const [ commentsLog, setCommentsLog ] = useState(false);

    useEffect(() => {

        getAllComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllComments = () => { 

        const sendData = {

            action : action,
            seniorId : seniorId,
            workerId : workerId

        }

        sendtoApi(
            "getComments",
            "first",
            sendData,
            setLoading,
            setCommentsLog,
          );

    }

    //console.log('commentsLog >', commentsLog);

  return (
    <section className="reportClient clear">

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        < InsertComment setCommentsLog = { setCommentsLog } {...props} />

        { !isEmpty(commentsLog) && < ShowComments {...props} commentsLog = {commentsLog} setCommentsLog = { setCommentsLog } /> }
                      
    </section>
  )

}

function sendtoApi(
    url,
    controller,
    objectToSend,
    setLoading,
    setResponseData    
  ) {

    setLoading(true);
  
    //console.log(url);
  
    const getData = getDataFromApi(url, objectToSend, controller, "all");
  
    getData.then((getData) => {

      if (getData.error) {
        toast.error(getData.error, {
          toastId: "responseError",
        });
        
      }

      if (getData.ok) {

        setResponseData(getData?.data);
        
      } else {

        toast.error("שגיאה", {
          toastId: "genericError",
        });

      }

    });
  
    getData.catch((error) => {
      console.log(error);
    });
  
    getData.finally(() => {
      setLoading(false);
    });

  }