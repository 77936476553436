import { RestUrls } from "./../../Urls";
import { toast } from 'react-toastify' //npm i react-toastify


export default async function getDataFromApi (url,objectToSend, controller, auth = 'all' ) {
    
    const API =  RestUrls.baseApiUrl1 + controller + '/' + url;


    let userJ = JSON.parse(localStorage.getItem('user-info'));
    let user = userJ.data;

    let userPass = {
        userId : user.id,
        userName : user.username,
        userCredential : user.userCredential,
        token : user.token,
        siteCode :  RestUrls.Code
    };

    let items = {...userPass, ...objectToSend}

    //console.log(JSON.stringify(items))

    try {

        
        const res = await fetch(API, {
            method: "POST",
            headers: {
            "Content-Type" : 'application/json',
            "Accept" : 'application/json'
            },
            body: JSON.stringify(items)
        });

        const data = await res.json();
        //console.log(data);

        if(data === 'unauthorized' || res.status === 403) {
            //403 forbidden
            //alert('yes');
            localStorage.setItem('userData','');
            window.open('/login','_self');
        } 

        else if(data) {

            let statusHeaders = {
                headerStatus : res.status
            };

            let responseData = {...statusHeaders, ...data}
            //console.log(res);

            //console.log(data);
            return responseData;
        }

    } catch (e) {

        toast.error('שגיאה בשרת');
        return {};
        
    }

}



export function sendtoAdmin(url,controller,objectToSend, setStateName, setLoading, auth = 'all', successCB = () => {} ) {

    setLoading(true);
    const getData = getDataFromApi(url,objectToSend,controller,auth);

    getData.then( (getData) => {

        //console.log(getData);
        setStateName(getData)
        successCB(getData);
    })

    getData.catch((error) => {
        console.log(error);
    })

    getData.finally(() => {
        setLoading(false);
    })

}