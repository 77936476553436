import React, { Component } from 'react'

import ContentWrapper  from './../Components/ContentWrapper';
import Nav  from './../Components/pages/desktop/Nav';
import NavRight from './../Components/pages/desktop/NavRight'
import NavInner from './../Components/pages/desktop/NavInner'

//import Footer from './../Components/pages/desktop/Footer';

//import Accessibility from './Components/Accessibility';

import './../css/animateNew.css'; /* https://daneden.github.io/animate.css/ */
// TEST SEBAS


//import Alert from './../Components/Alert.js';

export default class Desktop extends Component {

    

    render() {

        //let settingsData = this.props.info.SiteDataitems.settings;
        //console.log(this.props.modalTestSite);

        return (
            <div className={"body-container " + this.props.pageClass}>
                
                {/* <Accessibility  info={this.props.info} accessibility={this.props.accessibility} accesStates={this.props.accesStates}/> */}

                {/* hay mas cosas en SiteConector, getId y en el Alert */}
              {/*   {!this.state.modalTestSite && localStorage.getItem('cancelModalTestSite') !== 'yes' ? 
                    <Alert modalTestSite={true} submit={ this.cancelModalTestSite } title='האתר בהרצה!' message={settingsData.test_site_text} show={true} error={false} siteSettingsData={false} />
                    : false
                } */}
                
                {/* {console.log(this.props.info.user.data)} */}

                { this.props.info.media === 'mobile' ? <Nav page={this.props.page}s info={this.props.info} PagesNames={this.props.PagesNames} className="element" /> : false }
                
                <div className="content-wrapper twoColumns">
                    
                    { this.props.info.media === 'desktop' ? <NavRight page={this.props.page}s info={this.props.info} PagesNames={this.props.PagesNames} className="element" /> : false }

                    <div className="mainTable-wrapper">
                            
                        <NavInner user={this.props.info.user.data}  />
                        
                        <div className="mainContent">
                            <ContentWrapper PagesNames={this.props.PagesNames} page={this.props.page} info={this.props.info}  />
                        </div>
                    </div>
                </div>

                {/* <Footer {...this.props}/> */}

            </div>
        )
    }
}
