import React, { Component } from "react";
import convertDate from "./../../../convertDateToDisplay";

function toNumberAndFix(number) {
  let newNumber = parseFloat(number).toFixed(2);

  return newNumber;
}

export default class ShowInvoice extends Component {
  render() {
    let items = [
      {
        id: "1",
        name: "חידוש התקשרות",
      },
      {
        id: "2",
        name: "דמי השמה",
      },
      {
        id: "3",
        name: "פתיחת תיק",
      },
      {
        id: "4",
        name: "אחר",
      },
    ];

    let data = this.props.invoiceData.invoice;

    //console.log(data);

    return (
      <div className="ShowInvoice">
        <div className="top">
          <div className="dateShow">{convertDate(data.invoiceDate)}</div>
          <h2>
            <strong>{data.invoiceType}</strong>
          </h2>

          <header>
            <ul>
              <li>
                <strong>לכבוד, </strong> <span>{data.invoiceFor}</span>
              </li>
              <li>
                <strong>ת.ז/ח.פ/דרכון: </strong> <span>{data.invoiceTz}</span>
              </li>
              <li>
                <strong>כתובת: </strong> <span>{data.invoiceAddress}</span>
              </li>
              <li>
                <strong>טלפון: </strong> <span>{data.invoicePhone}</span>
              </li>
              <li>
                <strong>עבור לקוח: </strong> <span>{data.invoiceClient}</span>
              </li>
              <li>
                <strong>תקופת תשלום: </strong>{" "}
                <span>
                  {convertDate(data.invoiceDate1) +
                    " - " +
                    convertDate(data.invoiceDate2)}
                </span>
              </li>
            </ul>
          </header>

          <div className="details clear">
            <ul className="clear">
              {parseFloat(data.itemPrice1) > 0 ? (
                <li>
                  <strong>{items[0]["name"]}</strong>{" "}
                  <span>{toNumberAndFix(data.itemPrice1)} ₪</span>
                </li>
              ) : (
                false
              )}

              {parseFloat(data.itemPrice2) > 0 ? (
                <li>
                  <strong>{items[1]["name"]}</strong>{" "}
                  <span>{toNumberAndFix(data.itemPrice2)} ₪</span>
                </li>
              ) : (
                false
              )}

              {parseFloat(data.itemPrice3) > 0 ? (
                <li>
                  <strong>{items[2]["name"]}</strong>{" "}
                  <span>{toNumberAndFix(data.itemPrice3)} ₪</span>
                </li>
              ) : (
                false
              )}

              {parseFloat(data.itemPrice4) > 0 ? (
                <li>
                  <strong>{data.itemText4}</strong>{" "}
                  <span>{toNumberAndFix(data.itemPrice4)} ₪</span>
                </li>
              ) : (
                false
              )}
            </ul>
          </div>

          <div className="SumAll clear">
            <ul>
              <li>
                <span>סה"כ לפני מע"מ: </span>{" "}
                <span className="valueShow">
                  {toNumberAndFix(data.SumValue)} ₪
                </span>
              </li>

              <li>
                <span>הנחה: ({data.discount}%)</span>{" "}
                <span className="valueShow">
                  {toNumberAndFix(data.SumValue - data.discountValue)}
                </span>
              </li>

              <li className="">
                <span>סה"כ אחרי הנחה: </span>{" "}
                <span className="valueShow">
                  {toNumberAndFix(data.discountValue)} ₪
                </span>
              </li>

              <li className="">
                <span>מע"מ (17%): </span>{" "}
                <span className="valueShow">
                  {toNumberAndFix(data.taxesValue)} ₪
                </span>
              </li>

              <li className="superTotal">
                <strong>סה"כ לתשלום: </strong>{" "}
                <span className="valueShow">
                  {toNumberAndFix(data.superTotal)} ₪
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
