import React, { Component } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import convertDate from './../../../convertDateToDisplay'

export default class PlacementDataHistory extends Component {


    render() {

        let dataHistory = this.props.history;
        let serchData = this.props.placementSearch ? this.props.placementSearch : false;
        //console.log(namefromId);

        return (
            <div className="row">
                <div className='PlacementDataHistory'>
                    <div className="col-md-12">
                        <InputGroup className="customInput">
                            <InputGroup.Prepend className='inputPrependLabel'>
                            <InputGroup.Text id="basic-addon3">היסטוריית שיבוצים</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl  as="select" 
                                className='inputPrepend selectPrepend'
                                id="basic-url" aria-describedby="basic-addon3" >
                                    {dataHistory.map(item => { 
                                        
                                        //console.log('item', item);

                                        if (serchData) {

                                        let nameFromId = serchData.filter(function(desc) {
                                            return desc.data.id === item.data.ForeignWorkerName;
                                        });

                                        let text = '-';
                                        let m_text = '-';
                                        let displayText = "-";

                                        if(nameFromId[0]) {

                                            text = 'תאריך רישום: ' + convertDate(item.data.inscriptionDate) + ' - לתאריך: ' + convertDate(item.data.toDate)
                                        + ' - שם: ' + nameFromId[0].data.name + ' ' + nameFromId[0].data.surname + ' דרכון: ' + nameFromId[0].data.passport;
                             
                                            m_text = nameFromId[0].data.name + ' - ' + convertDate(item.data.toDate);
                                            displayText = this.props.platform === 'mobile' ?  m_text : text;

                                        }

                                        

                                        return  <option key ={ item.data.id }>{displayText}</option> } else return false}) }
                            </FormControl>
                        </InputGroup>
                    </div>
                </div>
            </div>
        )
    }
}

/* 
<ul  key={item.data.id}>
<li><span>תאריך רישום: </span> {convertDate(item.data.inscriptionDate)}</li>
<li><span>לתאריך: </span> {convertDate(item.data.toDate)}</li>
<li><span>שם: </span> {nameFromId[0].data.name}</li>
</ul>} else return false}) } */