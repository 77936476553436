import React, {useState} from 'react';
import { InputGroup, FormControl} from 'react-bootstrap';
import ModalChangeHistory from './ModalChangeHistory';

export default function HistoryLogs(props) {

    const { inputData, inputValue, dataParam } = props;

    //console.log('inputData', inputData);
    //console.log('inputData', inputData);

    const [inputValueS, setInputValueS] = useState(checkDefaultValue(inputData, inputValue));
    const [showModal, setShowModal] = useState(false);

    const showChangeModal = () => {

        setShowModal(true);
      
    };
    

  return <>

    <div className={ inputData.width.replace('md','xl') + ' divBoostrap col-lg-12 divBoostrap col-sm-12 ' + inputData.name }>

        <InputGroup className="customInput">
            <InputGroup.Prepend className='inputPrependLabel'>
                <InputGroup.Text id="basic-addon3">{inputData.explain}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                disabled={checkDisabled(inputData)}
                className='inputPrepend'
                placeholder={inputValueS}
                onClick={showChangeModal}
            />
        </InputGroup>

        {showModal && <ModalChangeHistory
                        dataParam = {dataParam}
                        setShowModal = {setShowModal}
                        inputData = {inputData}
                        inputValueDB = {inputValue}
                        setInputValueS = {setInputValueS}
                    />}

    </div>
  
  </>;
}

function checkDisabled(inputData) {

    switch (inputData?.name) {

        case 'visa_validity':
            return true;

        case 'passport_validity':
            return true;

        case 'license_toDate':
            return true;
    
        default:
            return false;

    }


}



function checkDefaultValue(inputData, inputValue) {


    /* if(inputData.name === 'visa_validity' || inputData.name === 'license_toDate' ) {
        //console.log(convertDateShow(inputValue));
    } */

    return convertDateShow(inputValue);

    

}


function convertDateShow (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    /* 2021-03-25 */

    let formatted_date = 
                        current_datetime.getDate() + '/' +
                        (current_datetime.getMonth() + 1) + "/" +
                        current_datetime.getFullYear();
                        
    return formatted_date;
}

