import React, { Fragment, useEffect, useState } from 'react'

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function Yeter2(props) {

    const { setMoreDataPdf, sendForm } = props

    const inputsBuilder = [
        {
            'name' : 'selectedOption',
            'label' : 'בחירה',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'מחמת נכותו, נבצר מהמטופל הקטין להשתתף...'
                    },
                    {
                        'id' : '2',
                        'name' : 'המטופל הקטין מבקר במוסד...'
                    },
                    {
                        'id' : '3',
                        'name' : 'הימצאותו שך המטופל הקטין...'
                    },
                    {
                        'id' : '4',
                        'name' : 'על אף הימצאותו שך המטופל...'
                    }                    
                ],
        },
        {
            'name' : 'text1',
            'label' : '',
            'defaultValue': '',
            
        },
        {
            'name' : 'text2',
            'label' : '',
            'defaultValue': '',
            
        },
        {
            'name' : 'text3',
            'label' : '',
            'defaultValue': '',
            
        },
        {
            'name' : 'text4',
            'label' : '',
            'defaultValue': '',
            
        },
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);

  return (
    <div>

        <h3>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            <Grid item md={12} >

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[0].name}
                    value={formData[inputsBuilder[0].name]}
                >
                    <option value="">בחרו...</option>

                    {map(inputsBuilder[0].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>
            </Grid>

            { formData.selectedOption === '2' && <Fragment>
                
                <Grid item md={12} >

                    <header>
                        <h4>שם מוסד החינוכי</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[1].name}
                        value={formData[inputsBuilder[1].name]}
                    />
                </Grid>

                <Grid item md={12} >

                    <header>
                        <h4>כתובת מוסד החינוכי</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[2].name}
                        value={formData[inputsBuilder[2].name]}
                    />
                </Grid>

                <Grid item md={12} >

                    <header>
                        <h4>טלפון מוסד החינוכי</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[3].name}
                        value={formData[inputsBuilder[3].name]}
                    />
                </Grid>

                <Grid item md={12} >

                    <header>
                        <h4>שעות הלימוד</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[4].name}
                        value={formData[inputsBuilder[4].name]}
                    />

                </Grid>

            </Fragment> }

            { formData.selectedOption === '4' && <Fragment>
                
                <Grid item md={12} >

                    <header>
                        <h4>שם מוסד החינוכי</h4>
                    </header>

                    <FormControl onChange={onChange}
                        as="textarea"
                        name={inputsBuilder[1].name}
                        value={formData[inputsBuilder[1].name]}
                    />
                </Grid>

            </Fragment> }

        </Grid>

        { formData.selectedOption && <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div> }
    </div>
  )

}

function defaultValueForm(inputsBuilder) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = item.defaultValue
        
    })
    
    return objReturn;

}