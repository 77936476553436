import React from 'react';

import { InputGroup, FormControl } from 'react-bootstrap';
import DatePicker from "react-datepicker";
export default function InputShowDateEditable(props) {

    const { name, date, onChange, disabled } = props;
    

  return    <InputGroup className="customInput customSelect">
                {!!name && <InputGroup.Prepend className='inputPrependLabel '>
                    <InputGroup.Text>{name}</InputGroup.Text>
                </InputGroup.Prepend>}
                
               {date &&  <DatePicker
                        showYearDropdown={true}
                        selected={
                          new Date(date.toString().replace(/-/g, "/"))
                        }
                        dateFormat="dd/MM/yy"
                        className="form-control"
                        name="created_at"
                        locale="he"
                        onChange={(date) =>
                            onChange(date)
                        }
                        disabled={disabled}
                      />}
            </InputGroup>;
}
