import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import convertDate from './../../../convertDateToDisplay'
import ReportChangeMana from './ReportChangeMana'
import { Button } from 'react-bootstrap';
import { ConstantsNames } from "./../../../Urls";
import { useState } from 'react';
import { toast } from 'react-toastify';
import loader from "../../../../img/preLoader.gif";

import Loading from "../../../-HELPERS-/Loading";

export default class ReportHistory extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            reportsHistory : false,
            savedMessage : false,
            loading: false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        this.setState({loading : true});

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            this.setState({loading : false});

            //console.log(getData);
            /* if(url === 'reportBuilder') {
                
            } */

        }));
    }


    loadData = () => {
        this.sendtoAdmin('getReportHistory','Reports',{ seniorId : this.props.clientId },'reportsHistory');
    }
    

    componentDidMount() {
        
        if(!this.state.reportsHistory) {
            this.loadData();
        }

    }
    
    componentDidUpdate() {

        if(this.props.reloadHistory) {
            this.loadData();
            this.props.changed();
        }

    }

    onChangeAdmin = () => {
        //console.log('changed');
        
        setTimeout(() => this.setState({savedMessage: true}), 50);
        setTimeout(() => this.setState({savedMessage:false}), 6000);
    }


    

    render() {
        
        return (
            <div>

                { this.state.loading && <Loading open={true} noBackdrop={true} /> }

                {this.state.savedMessage ? <div className="savedWindow animate__animated animate__bounceIn animate__slow">השינוים נשמרו בהצלחה</div> : false }

                {this.state.reportsHistory && this.state.reportsHistory.reportHistory[0] ? <div className="tableDefault">

                    <div className="trTable titlesTr">
                        <div className="tdTable">מס' דרכון</div>
                        <div className="tdTable">שם מלא</div>
                        <div className="tdTable">תיאור דיווח</div>
                        <div className="tdTable">סיבה</div>
                        <div className="tdTable">תאריך דיווח</div>
                        <div className="tdTable">סטטוס</div>
                        <div className="tdTable">מספר מנה</div>
                        <div className="tdTable">קובץ</div>
                    </div>
                    <div className="trTable contentTds spaceTr" >
                        <div className="vSpace"></div>
                    </div>

                    {this.state.reportsHistory.reportHistory.map(item => { if (true) {

                            var md5 = require('md5');
                            let txtDownload = ConstantsNames.base_url + '/Reports/downloadTXT?file=' + md5(item.fileMatash);

                            return <div className="trTable contentTds" key={item.id}>
                                    <div><span>{item.workerPassport}</span></div>
                                    <div><span>{item.workerName}</span></div>
                                    <div><span>{item.subjectReport}</span></div>
                                    <div><span>{item?.causeReport}</span></div>
                                    <div><span>{convertDate(item.dateReport)}</span></div>
                                    {/* + ' - ' + item.report.length */}
                                        
                                    <ReportChangeMana display='select' disabled = { item.manaCode ? true : false } savedMessage = {()=>this.onChangeAdmin()} item={item} />

                                    <div className="reportButtons">
                                        
                                        <Button style={{padding : '0', fontSize : '20px'}} variant='' size="sm" href={txtDownload} target="_blank" rel="noopener noreferrer" ><i className="fas fa-file-download"></i></Button>
                                        < DeleteReport item = { item } reload = { () =>  this.loadData() } />

                                    </div>
                                    
                                </div>
                        } else return false}) }
                    
                </div> : false }
            </div>
        )
    }
}


function DeleteReport(props) {

    const { item,reload } = props
    const [showDelete, setShowDelete] = useState(false)
    const [loading, setLoading] = useState(false);

    const deleteMe = () => { 


        const send = {
            rowId: item.id,
        };
      
          //console.log(send);
          sendtoApi("deleteRow", "Reports", send, setLoading, reload);


     }

    return(
        
        <div>

            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

            {!showDelete &&  <span>
                <i className="far fa-trash-alt delete" onClick={() => setShowDelete(true) }></i>
            </span> }

            { showDelete && <div className='sureDelete'>
                <p>בטוח למחוק?</p>
                <div className='btns'>
                    <span onClick={() => deleteMe() } >כן</span>
                    <span onClick={ () => setShowDelete(false) } >ביטול</span>
                </div>
            </div>}

        </div>
    )

}


function sendtoApi(url, controller, objectToSend, setLoading, reload) {

    setLoading(true);
  
    //console.log(url);
  
    const getData = getDataFromApi(url, objectToSend, controller, "all");
  
    getData.then((getData) => {

      setLoading(false);

      if (getData.error) {
        toast.error(getData.error, {
          toastId: "responseError",
        });
      }

      if (getData.ok) {

        toast.success("נמחק בהצלחה");
        reload();

        //console.log("getData", getData);
        
      } else {

        toast.error("שגיאה", {
          toastId: "genericError",
        });
      }

    });
  
    getData.catch((error) => {
      console.log(error);
    });
  
    
  }