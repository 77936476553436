import React from 'react'
//import { Button } from 'react-bootstrap';
//import { toast } from 'react-toastify';
//import getDataFromApi from '../../../../DinamicForm/newApi/getDataFromApi';
import { useState } from 'react';
import loader from "../../../../../img/preLoader.gif";

export default function TurnOnOffCronjob(props) {

    const {data } = props //, reload

    console.log('data', data);

    const [ loading ] = useState(false); //, setLoading

    /* const sendApprove = (action) => { 

        if(action?.id) {

            const sendData = {

                rowId : action?.id
    
            }
    
            sendtoApi("cancelCronJobApprove","pays",sendData,setLoading,reload);

        } else if(action === 'ok') {

            const sendData = {}
    
            sendtoApi("addCronJobApprove","pays",sendData,setLoading,reload);

        }

        

     } */

  return (
    <div className='turnOnOffCronjob'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        {/* <div className='headerCron'>
            <strong>סטטוס: </strong>
            {data?.isApproved?.result ? <h2 className='ok'>קיים אישור היום לבצע הוראות קבע</h2> :
            <h2 className='error'>לא קיים אישור היום לבצע הוראות קבע</h2> }
        </div> */}

        {/* {data?.isApproved?.result ? <div className='showDataApprove'>
            <ul>
                <li>
                    <strong>קיים אישור בתאריך ושעה: </strong><span>{data?.isApproved.created_atShow}</span>
                </li>
                <li>
                    <strong>שם משתמש: </strong><span>{data?.isApproved.userName}</span>
                </li>
                <li>
                    <Button onClick={ () => sendApprove(data.isApproved) } variant='danger' size="" >ביטול אישור</Button> 
                </li>
            </ul>    
        </div> : <div className='showDataApprove'>
            
            <ul>
                <li>
                    <Button onClick={ () => sendApprove('ok') } variant='success' size="lg" >אני מאשר/ת לבצע חיוב של הוראות קבע שברשימה למטה</Button> 
                </li>
            </ul>
            
        </div>} */}
        

    </div>
  )
}


/* function sendtoApi(url,controller,objectToSend,setLoading,reload) {

    setLoading(true);
  
    //console.log(url);
  
    const getData = getDataFromApi(url, objectToSend, controller, "all");
  
    getData.then((getData) => {

      setLoading(false);

      if (getData.error) {
        toast.error(getData.error, {
          toastId: "responseError",
        });
        
      }

      else if (getData.ok) {

        toast.success(getData.ok);
        reload();
        
      } else {

        toast.error("שגיאה", {
          toastId: "genericError",
        });

      }

    });
  
    getData.catch((error) => {
      setLoading(false);
      console.log(error);
    });
  
    

  } */