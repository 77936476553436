import React, { Component } from "react";
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import { InputGroup, FormControl } from "react-bootstrap";
//import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */

//import { ConstantsNames } from "./../../../Urls"

export default class Wsign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents4Sign: false,
      selectedDocument4Sign: "",
      link4Document: false,
    };
  }

  loadData = (
    url,
    controller,
    objectToSend = {},
    stateNameResponse = false
  ) => {
    let setStateName;

    if (stateNameResponse) {
      setStateName = stateNameResponse;
    } else {
      setStateName = "data";
    }

    let getData = getDataFromApi(url, objectToSend, controller);
    getData
      .then(function (response) {
        return response;
      })
      .then((getData) =>
        this.setState({ [setStateName]: getData }, function () {
          if (stateNameResponse === "selectedDocument4Sign") {
            if (getData.insertId) {
              this.props.changeScreen("סטטוס מסמכים", getData.insertId);
            }
          }
        })
      );
  };

  componentDidMount() {
    this.loadData("getdocuments4Sign", "Wsign", {}, "documents4Sign");
  }

  addNewWsign_linkedDocs = (docId) => {
    //console.log(docId);

    let send = {
      docId: docId,
      clientId: this.props.clientId,
      workerId: this.props.workerId,
    };

    this.loadData(
      "addNewWsignlinkedDocs",
      "Wsign",
      send,
      "selectedDocument4Sign"
    );
  };

  selectedDocument4Sign = (event) => {
    this.addNewWsign_linkedDocs(event.target.value);
    //scroll.scrollToBottom();
  };

  render() {
    //let selectedDocument4Sign = this.state.selectedDocument4Sign;

    return (
      <section className="Wsign">
        {this.state.documents4Sign && this.state.documents4Sign.documents[0] ? (
          <div className="selectCont">
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel ">
                <InputGroup.Text>מסמך לחתימה</InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="inputPrepend customSelect"
                onChange={this.selectedDocument4Sign}
                value={this.state.selectedDocument4Sign}
                name="selectedDocument4Sign"
              >
                <option value="">בחר...</option>

                {this.state.documents4Sign.documents.map((item) => {
                  if (true) {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  } else return false;
                })}
              </FormControl>
            </InputGroup>
          </div>
        ) : (
          false
        )}
      </section>
    );
  }
}
