import React, { Component } from "react";
import { RestUrls } from "./../../../Urls";
import { InputGroup, FormControl, Alert } from "react-bootstrap";
import { FormValidator } from "../../../forms/FormValidator";
import { ValidationMessage } from "../../../forms/ValidationMessage";
import { ValidateForm } from "../../../forms/wholeFormValidation";

export default class NewUserModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentForm: false, // show / hide form
      name: "",
      username: "",
      userType: "",
      email: "",
      phone: "",
      licenseNumber: "",
      passwordMd5: "",
      branchId: "",
      errorInsert: "",
      branches: [
        { id: "1", name: "תל אביב" },
        { id: "2", name: "באר שבע" },
        { id: "3", name: "ראשון לציון" },
        { id: "4", name: "ירושלים" },
        { id: "5", name: "חיפה" },
      ],
    };
    this.rules = {
      name: { required: true, minlength: 3 },
      username: { required: true, minlength: 3 },
      passwordMd5: { required: true, minlength: 8 },
      userType: { required: true },
      email: { required: true, email: true },
      phone: { required: true, minlength: 9, numbers: true },
      /* terms: { true: true} */
    };
  }

  async insertUser() {
    //console.log('IN');
    // console.log(this.state);

    let userJ = JSON.parse(localStorage.getItem("user-info"));
    let user = userJ.data;

    let item = {
      userId: user.id,
      userName: user.username,
      userCredential: user.userCredential,
      token: user.token,
      siteCode: RestUrls.Code,
      name: this.state.name,
      username: this.state.username,
      userType: this.state.userType,
      email: this.state.email,
      phone: this.state.phone,
      licenseNumber: this.state.licenseNumber,
      branchId: this.state.branchId,
      passwordMd5: this.state.passwordMd5,
    };

    let url = "users?newUser=1";

    let fletchUrl = RestUrls.baseApiUrl + url;

    let result = await fetch(fletchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    });

    let data = await result.json();

    if (data === "unauthorized") {
      localStorage.setItem("user-info", "");
      window.location.reload();
    } else {
      if (data && data.error) {
        this.setState({ errorInsert: data.error });
      } else if (data && data.insertOk) {
        this.setState({ sentForm: true });
      }

      //console.log(data)
      //deleteMe(data.insertId,urlDelleteTwice);
      //this.editHandler(data.insertId);
    }
  }

  //Input Text
  updateFormValue = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //SELECT
  editHandlerSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    //this.uptateInputField (event.target.name, event.target.value);
  };

  sendForm = () => {
    //console.log(this.state);
    this.insertUser();
  };

  render() {
    return (
      <div>
        <div className="row justify-content-center inserUserModal">
          <div className="col-lg-12 text-center">
            <div className="row">
              {!this.state.sentForm ? (
                <FormValidator
                  data={this.state}
                  rules={this.rules}
                  btnText="שלחו"
                  buttonSendAction={this.sendForm}
                  validateForm={ValidateForm}
                >
                  <ValidationMessage field="form" />

                  <div className="formInput">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">שם</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className="boostrapInvertcornersLeft" //Sebas
                        placeholder=""
                        name="name" // FOR VALIDATOR
                        aria-label="שם"
                        onChange={(e) => this.updateFormValue(e)}
                        aria-describedby=""
                      />
                    </InputGroup>
                    <p className="validatorInputError">
                      <ValidationMessage field="name" />
                    </p>
                  </div>

                  <div className="formInput">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          שם משתמש
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className="boostrapInvertcornersLeft" //Sebas
                        placeholder=""
                        name="username" // FOR VALIDATOR
                        aria-label="שם משתמש"
                        onChange={(e) => this.updateFormValue(e)}
                        aria-describedby=""
                      />
                    </InputGroup>
                    <p className="validatorInputError">
                      <ValidationMessage field="username" />
                    </p>
                  </div>

                  <div className="formInput">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          כתובת מייל
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className="boostrapInvertcornersLeft" //Sebas
                        placeholder=""
                        name="email" // FOR VALIDATOR
                        aria-label="כתובת מייל"
                        onChange={(e) => this.updateFormValue(e)}
                        aria-describedby=""
                      />
                    </InputGroup>
                    <p className="validatorInputError">
                      <ValidationMessage field="email" />
                    </p>
                  </div>

                  <div className="formInput">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          טלפון נייד
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className="boostrapInvertcornersLeft" //Sebas
                        placeholder="טלפון נייד"
                        name="phone" // FOR VALIDATOR
                        aria-label="טלפון נייד"
                        onChange={(e) => this.updateFormValue(e)}
                        aria-describedby=""
                      />
                    </InputGroup>
                    <p className="validatorInputError">
                      <ValidationMessage field="phone" />
                    </p>
                  </div>

                  <div className="formInput">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          סיסמה
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="password"
                        className="boostrapInvertcornersLeft" //Sebas
                        placeholder="סיסמה"
                        name="passwordMd5" // FOR VALIDATOR
                        aria-label="סיסמה"
                        onChange={(e) => this.updateFormValue(e)}
                        aria-describedby=""
                      />
                    </InputGroup>
                    <p className="validatorInputError">
                      <ValidationMessage field="passwordMd5" />
                    </p>
                  </div>

                  <div className="formInput">
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          סוג משתמש
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                      <FormControl
                        as="select"
                        className="inputPrepend selectPrepend  selectDefault"
                        onChange={this.editHandlerSelect}
                        name="userType"
                        disabled={this.props.is_disabled ? true : false}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                      >
                        <option>בחר...</option>
                        {this.props.userTypes.map((item) => {
                          if (item.name !== "superAdmin") {
                            /* currentState={this.state} */
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name_hebrew}
                              </option>
                            );
                          } else return false;
                        })}
                      </FormControl>
                    </InputGroup>
                    <p className="validatorInputError">
                      <ValidationMessage field="userType" />
                    </p>
                  </div>

                  {this.state.userType === "4" && (
                    <div className="formInput">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            סניף
                          </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                          as="select"
                          className="inputPrepend selectPrepend selectDefault"
                          onChange={this.editHandlerSelect}
                          name="branchId"
                          value={this.state.branchId}
                          //disabled={this.props.is_disabled ? true : false}
                          id="basic-url"
                          aria-describedby="basic-addon3"
                        >
                          <option>בחר...</option>
                          {this.state.branches.map((item) => {
                            if (true) {
                              /* currentState={this.state} */
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            } else return false;
                          })}
                        </FormControl>
                      </InputGroup>
                      <p className="validatorInputError">
                        <ValidationMessage field="userType" />
                      </p>
                    </div>
                  )}

                  {this.state.userType === "5" && (
                    <div className="formInput">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            מספר רישיון
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          className="boostrapInvertcornersLeft" //Sebas
                          placeholder="מספר רישיון"
                          name="licenseNumber" // FOR VALIDATOR
                          aria-label="מספר רישיון"
                          onChange={(e) => this.updateFormValue(e)}
                          aria-describedby=""
                        />
                      </InputGroup>
                      <p className="validatorInputError">
                        <ValidationMessage field="licenseNumber" />
                      </p>
                    </div>
                  )}

                  <p className="validatorInputError">
                    <ValidationMessage field="inputFile" />
                  </p>
                  {this.state.errorInsert ? (
                    <Alert variant="danger">
                      <p style={{ fontSize: "16px", margin: "0" }}>
                        {this.state.errorInsert}
                      </p>
                    </Alert>
                  ) : (
                    false
                  )}
                </FormValidator>
              ) : (
                <p className="okUserInsert animate__animated animate__bounce">
                  הוסף בהצלחה
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
