import React, { Component } from "react";

import { ConstantsNames } from "../../Urls";

import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";

import bulletMainMenu from "./../../../img/icons/bulletMainMenu.png";

import menuHomeIcon from "./../../../img/icons/menuIcons/home.svg";
import menuClientsIcon from "./../../../img/icons/menuIcons/clients.svg";
import menuWorkersIcon from "./../../../img/icons/menuIcons/workers.svg";
//import menuReportsIcon from "./../../../img/icons/menuIcons/reports.svg";
import menuMatchIcon from "./../../../img/icons/menuIcons/match.svg";
import menuConfigIcon from "./../../../img/icons/menuIcons/config.svg";

export default class NavRight extends Component {
  render() {
    let PagesNames = this.props.PagesNames; //On Site Connector
    let clientData = this.props.info.SiteDataitems.SystemData;
    let currentPage = this.props.page;

    let userJ = JSON.parse(localStorage.getItem("user-info"));
    let user = userJ.data;

    /* console.log(currentPage);
    console.log(PagesNames.home.method); */

    return (
      <div className="site-menu">
        <div className="mainMenuTable">
          <div className="fixedPosition">
            <header>
              <img
                src={ConstantsNames.pic + clientData.logoClientMenu}
                alt={clientData.FirmName}
                className="logoClient"
              />
            </header>

            <nav className="mainMenuNav">
              {user.userCredential !== "socialWorker" ? (
                <div>
                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.home.method ? "is-active" : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.home.url}
                  >
                    <img
                      className="bulletMainMenu"
                      src={menuHomeIcon}
                      alt="בולט"
                    />
                    <span>{PagesNames.home.namePage}</span>
                  </NavLink>

                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.clients.method
                        ? "is-active"
                        : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.clients.url}
                  >
                    <img
                      className="bulletMainMenu"
                      src={menuClientsIcon}
                      alt="בולט"
                    />
                    <span>{PagesNames.clients.namePage}</span>
                  </NavLink>

                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.workers.method
                        ? "is-active"
                        : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.workers.url}
                  >
                    <img
                      className="bulletMainMenu"
                      src={menuWorkersIcon}
                      alt="בולט"
                    />
                    <span>{PagesNames.workers.namePage}</span>
                  </NavLink>

                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.tasks.method ? "is-active" : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.tasks.url}
                  >
                    <img
                      className="bulletMainMenu"
                      src={bulletMainMenu}
                      alt="בולט"
                    />
                    <span>{PagesNames.tasks.namePage}</span>
                  </NavLink>

                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.reports.method
                        ? "is-active"
                        : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.reports.url}
                  >
                    <img
                      className="bulletMainMenu"
                      src={bulletMainMenu}
                      alt="בולט"
                    />
                    <span>{PagesNames.reports.namePage}</span>
                  </NavLink>

                  {/* <NavLink
                    activeClassName={
                      currentPage === PagesNames.documents.method
                        ? "is-active"
                        : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.documents.url}
                  >
                    <img
                      className="bulletMainMenu"
                      src={menuReportsIcon}
                      alt="בולט"
                    />
                    <span>{PagesNames.documents.namePage}</span>
                  </NavLink> */}

                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.match.method ? "is-active" : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.match.url}
                  >
                    <img
                      style={{ marginLeft: "13px" }}
                      className="bulletMainMenu"
                      src={menuMatchIcon}
                      alt="בולט"
                    />
                    <span>{PagesNames.match.namePage}</span>
                  </NavLink>

                  {/* <NavLink 
                    activeClassName={currentPage === PagesNames.finance.method ? 'is-active' : ''}
                    role="menuitem" onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.finance.url} >
                      <img style={{marginLeft : '13px'}} className='bulletMainMenu' src={bulletMainMenu} alt='בולט' />
                      <span>{PagesNames.finance.namePage}</span>
                </NavLink> */}

                  {user.userCredential === "superAdmin" ||
                  user.userCredential === "admin" ? (
                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.users.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.users.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={bulletMainMenu}
                        alt="בולט"
                      />
                      <span>{PagesNames.users.namePage}</span>
                    </NavLink>
                  ) : (
                    false
                  )}

                  {user.userCredential === "superAdmin" ? (
                    <NavLink
                      activeClassName={
                        currentPage === PagesNames.config.method
                          ? "is-active"
                          : ""
                      }
                      role="menuitem"
                      onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.config.url}
                    >
                      <img
                        className="bulletMainMenu"
                        src={menuConfigIcon}
                        alt="בולט"
                      />
                      <span>{PagesNames.config.namePage}</span>
                    </NavLink>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                false
              )}

              <NavLink
                activeClassName={
                  currentPage === PagesNames.homeVisits.method
                    ? "is-active"
                    : ""
                }
                role="menuitem"
                onClick={() => scroll.scrollTo(0)}
                to={PagesNames.homeVisits.url}
              >
                <img
                  style={{ marginLeft: "13px" }}
                  className="bulletMainMenu"
                  src={bulletMainMenu}
                  alt="בולט"
                />
                <span>{PagesNames.homeVisits.namePage}</span>
              </NavLink>

              {/* {user.userCredential === "socialWorker" && (
                <>
                  <NavLink
                    activeClassName={
                      currentPage === PagesNames.homeVisits1.method
                        ? "is-active"
                        : ""
                    }
                    role="menuitem"
                    onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.homeVisits1.url}
                  >
                    <img
                      style={{ marginLeft: "13px" }}
                      className="bulletMainMenu"
                      src={bulletMainMenu}
                      alt="בולט"
                    />
                    <span>{PagesNames.homeVisits1.namePage}</span>
                  </NavLink>
                </>
              )} */}
            </nav>

            <div className="credit">
              <a
                href="https://waveproject.co.il/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-w-hd.png"
                  alt="WaveProject"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
