import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import QuadGraph from './QuadGraph'

export default class DataSummary extends Component {

    render() {

        

        let dataSumary = this.props.dataSummary;
        //console.log(dataSumary);

        return (
            <div className="DataSummary">
                <h2 className="titStyle ">סיכום נתונים</h2>

                <Container>
                    <div className="graphsContainer row ">

                        <div className="col-xl-3 col-lg-6 col-sm-6">
                            <QuadGraph title={dataSumary.graph1.title} percent={this.props.dataSummary.graph1.percent} />
                        </div>

                        <div className="col-xl-3 col-lg-6 col-sm-6">
                            <QuadGraph title={dataSumary.graph2.title} percent={this.props.dataSummary.graph2.percent} />
                        </div>

                        <div className="col-xl-3 col-lg-6 col-sm-6">
                            <QuadGraph title={dataSumary.graph3.title} percent={this.props.dataSummary.graph3.percent} />
                        </div>

                        <div className="col-xl-3 col-lg-6 col-sm-6">
                            <QuadGraph title={dataSumary.graph4.title} percent={this.props.dataSummary.graph4.percent} />
                        </div>
                    </div> 
                </Container>
            </div>
        )
    }
}
