import React, { Component } from 'react'
import convertDate from './../../../convertDateToDisplay'
import { Table, Button } from 'react-bootstrap';

export default class LinksWorker extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             displayAll : false
        }
    }
    

    render() {

        let counter = 0;


        return (
            <div className='LinksWorkerCont'>

                <div className="overFlowDiv">

                    {this.props.linkedClients ? <Table className="defaultDisplayTable" bordered hover>
                        <thead>
                            <tr>
                                <th>שם הקשיש</th>
                                <th>תאריך התחלה</th>
                                <th>תאריך סיום</th>
                                <th>תאגיד</th>
                                <th>סך ותק מחושב</th>
                            </tr>
                        </thead>

                        <tbody>
                            
                            {this.props.linkedClients.map(item => { if (counter < 1 || this.state.displayAll ) {

                                counter++;

                                return <tr key={item.idPlaces} >
                                    {item.dataSenior?.id ? <td><a href={"/לקוחות?id=" + item.dataSenior?.id } target="_blank" rel="noopener noreferrer">{item.dataSenior.name} {item.dataSenior.surname}</a></td> :
                                    <td>?</td>}
                                    <td>{convertDate(item.datePlacement)}</td>
                                    <td>{ (item.datePlacementStatus === '1') ? <span style={{color: 'green'}}>כרגע בתפקיד</span> : convertDate(item.toDate)}</td>
                                    <td>?</td>
                                    <td>{item.totalTime} {item.totalTimeFinal}</td>
                                </tr>
                            } else return false}) }

                        </tbody>
                    </Table> : <h4>ללא שיבוץ</h4> }

                    {this.props.linkedClients && this.props.linkedClients[0] ? <div style={{textAlign : 'left' }}>
                        <Button className=""  onClick={ () => this.setState({displayAll : !this.state.displayAll }) } variant='secondary' size="" >{!this.state.displayAll ? 'כל השיבוצים' : 'סגירה'}</Button>
                    </div> : false }
                </div>
            </div>

        )
    }
}
