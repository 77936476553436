import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import { Table, Button } from 'react-bootstrap';
import convertDate from './../../../convertDateToDisplay'
import { RestUrls } from "./../../../Urls"

export default class WsignShowDocs4Sign extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            docs4Sign : false,
            displayAll : false,
            reload : this.props.reload
        }
    }

    componentDidUpdate() {

        if (this.props.reload) {

            this.loadData('getDocs4SignConfig','Wsign', { clientId : this.props.clientId },'docs4Sign');
            this.props.reloaded();

        }

    }
    

    loadData = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData }, function() {


            if(stateNameResponse === 'delleteDocsResp') {

                setTimeout(() =>this.componentDidMount(), 50);
                //console.log(getData);
                
            }


        }));
    }

    componentDidMount() {
        this.loadData('getDocs4SignConfig','Wsign', { clientId : this.props.clientId },'docs4Sign');
    }
    
    deletteMe = (rowId) => {

        //console.log(rowId);
        this.loadData('delleteDocs4SignConfig','Wsign', { rowId : rowId },'delleteDocsResp');
        
    }
    


    render() {

        let counter = 0;


        return (
            <div>

                {this.state.docs4Sign.documents && this.state.docs4Sign.documents[0] ? <div className="StatusDocs">

                        <div className="reLoad">
                            <button className='goBackCustomBtn' onClick ={ ()=> this.componentDidMount() } ><i className="fas fa-sync-alt"></i></button>
                        </div>

                        <Table responsive="sm">

                        <thead>
                            <tr>
                                <th>תאריך פתיחה</th>
                                <th>שם מסמך</th>
                                <th>קישור</th>
                                <th>#</th>
                            </tr>
                        </thead>

                        <tbody>

                            {this.state.docs4Sign.documents.map(item => {
                                
                                
                                let aText = item.idWsign ? <span>לצפייה במסמך</span> : <span className="green">למיקום החתימות</span>;

                                let urlSign = RestUrls.baseApiUrl1 + 'wsign/workersFlow.php?file=' + item.token;
                                let urlView = RestUrls.baseApiUrl1 + 'Wsign/Documents?file=' + item.token;

                                let url = item.idWsign ?  urlView : urlSign;


                                if ((counter < 3 || this.state.displayAll )) {

                                    counter++;

                                return <tr key = {item.id}>
                                            <td>{convertDate(item.created_at)}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <a className ="action" href={url} target="_blank" rel="noopener noreferrer" >{aText} &raquo;</a>
                                            </td>
                                            <td>
                                                {/* disabled={item.is_linked ? true : false} */}
                                                <button style={{fontSize : '14px'}} disabled={true} className='goBackCustomBtn' onClick ={ ()=> this.deletteMe(item.id) } ><i className="far fa-trash-alt"></i></button>
                                            </td>
                                            
                                        </tr>
                                        
                            } else return false}) }

                        </tbody>

                        </Table>

                        <div style={{textAlign : 'left' }} >
                            <Button className=""  onClick={ () => this.setState({displayAll : !this.state.displayAll }) } variant='secondary' size="" >{!this.state.displayAll ? 'כל המסמכים' : 'סגירה'}</Button>
                        </div>

                </div>: <h2 className='noDocuments' >לא קיימים מסמכים</h2>}

            </div>
        )
    }
}
