import React, { Component } from 'react'
//import { Modal } from 'react-bootstrap';

import { RestUrls } from "../Components/Urls";

import HomePage  from './pages/desktop/HomePage/HomePage';
import Clients  from './pages/desktop/Clients/Clients';
import Workers  from './pages/desktop/Workers/Workers';
import AllFiles from './pages/desktop/AllFiles/AllFiles';
import Reports from './pages/desktop/Reports/Reports';
import Users from './pages/desktop/Users/Users';
import Match from './pages/desktop/Match/Match';
import Config from './pages/desktop/Config/Config';
import Tasks from './pages/desktop/Tasks/Tasks';
import HomeVisits from './pages/desktop/HomeVisits/HomeVisits';
import HomeVisitsNewVisit from './pages/desktop/HomeVisits/HomeVisitsNewVisit';
import Finance from './pages/desktop/Finance/Finance';



//import Accessibility  from './pages/desktop/Accessibility/Accessibility';
//import Seo from './../Seo';

import Page404  from './pages/Page404';
import Loading from './-HELPERS-/Loading';


export default class ContentWrapper extends Component {


    constructor(props) {
        super(props);
        
        this.state = {
          items: [],
          isLoaded: false
        }

        
      }


      async componentDidMount() {
        
        //const pageName = RestUrls.Pages + this.props.page;
        const pageName = RestUrls.Pages + this.props.page;
        
        let API = pageName;
        let user = this.props.info.user.data;

        //console.log(API);

        //console.log(this.props.page);

        let sitePass = { 
            siteCode :  RestUrls.Code,
            userId : user.id,
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token
        };

        //console.log(sitePass);

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(sitePass)
          });

        const data = await res.json();

        this.setState(
            {items: data,  isLoaded: true,}

        )
    }

      render() {

        //console.log(this.props.page);

        let {isLoaded, items } = this.state;

        //console.log(paramWorker);

        //let closeButton = <button className="Xbtn">X</button>
        //console.log(items);



        if (!isLoaded) {
            return <Loading open={true} noBackdrop = {true} />
        }

        else {

        
            switch (this.props.page) {

                case "index":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* console.log(this.props.info.user) */}
                            <HomePage pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "clients":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* console.log(this.props.info.user) */}
                            <Clients pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />

                        </React.Fragment>
                    )

                case "foreignWorkers":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* console.log(this.props.info.user) */}
                            <Workers pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />

                        </React.Fragment>
                    )

                case "documents":
                    return (
                        <React.Fragment>
                           
                            <AllFiles pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />

                        </React.Fragment>
                    )

                case "reports":
                    return (
                        <React.Fragment>
                           
                            <Reports pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />

                        </React.Fragment>
                    )

                case "users":
                    return (
                        <React.Fragment>
                            <Users pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "match":
                    return (
                        <React.Fragment>
                            <Match pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "configPage":
                    return (
                        <React.Fragment>
                            <Config pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "tasks":
                    return (
                        <React.Fragment>
                            <Tasks pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "homeVisits":
                    return (
                        <React.Fragment>
                            <HomeVisits pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "HomeVisitsNewVisit":
                    return (
                        <React.Fragment>
                            <HomeVisitsNewVisit pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                    
                case "finance":
                    return (
                        <React.Fragment>
                            <Finance pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                /* case "welcome/accessibility":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Accessibility pageData={items} {...this.props} />
                        </React.Fragment>
                    )  */

                case "welcome/error404Page":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            <Page404 />
                        </React.Fragment>
                    )

                default:
                    return (
                        <div>DEFAULT CASE  Page1</div>
                    )
            }

        }
    }
}
