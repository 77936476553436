import React, { useEffect, useState } from 'react'

import { map,range } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function ReportSpecialCase(props) {

    const { setMoreDataPdf, sendForm,  responseData } = props

    let userJ = JSON.parse(localStorage.getItem('user-info'));
    let user = userJ.data;

    const inputsBuilder = [

        {
            'name' : 'general1',
            'label' : 'תאריך קבלת הדיווח',
            'defaultValue': '',
            
        },
        {
            'name' : 'general2',
            'label' : 'שמו של המדווח',
            'defaultValue': '',
            
        },
        {
            'name' : 'general3',
            'label' : 'קרבה של המדווח',
            'defaultValue': '',
            
        },
        {
            'name' : 'general4',
            'label' : 'דרכי התקשרות',
            'defaultValue': '',
            
        },
        {
            'name' : 'general5',
            'label' : 'אופן קבלת הדיווח',
            'defaultValue': '',
            
        },

        {
            'name' : 'theText1',
            'label' : 'סוג האירוע החריג',
            'defaultValue': '',
            
        },

        {
            'name' : 'theText2',
            'label' : 'פירוט הדיווח / תלונה שהתקבל/ה',
            'defaultValue': '',
            
        },

        {
            'name' : 'select1',
            'label' : 'הוגשה תלונה במשטרה?',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'select1Text',
            'label' : 'פרטי התלונה',
            'defaultValue': '',
            
        },

        {
            'name' : 'select2',
            'label' : 'דיווח ללשכת הרווחה באזור מגורי המטופל',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        
        {
            'name' : 'theText3',
            'label' : 'סיכום ראיון של עו"ס עם המתלונן',
            'defaultValue': '',
            
        },

        {
            'name' : 'theText4',
            'label' : 'סיכום ראיון של עו"ס עם העובד הזר',
            'defaultValue': '',
            
        },

        {
            'name' : 'theText5',
            'label' : 'פירות הממצאים שהתגלו',
            'defaultValue': '',
            
        },

        {
            'name' : 'theText6',
            'label' : 'סיכום של העו"ס האחראי/ת',
            'defaultValue': '',
            
        },
        
        {
            'name' : 'doc1',
            'label' : 'סיכומי ראיונות',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc2',
            'label' : 'אישור הגשת תלונה במשטרה',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc3',
            'label' : 'מסמכים רפואיים',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc4',
            'label' : 'מסמכים רפואיים',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc5',
            'label' : 'צילומים',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'doc6',
            'label' : 'אחר',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'doc6Text',
            'label' : 'אחר',
            'defaultValue': '',
            
        },
        {
            'name' : 'socialWorker',
            'label' : 'שם עו"ס אחראי/ת',
            'defaultValue': user?.name,
            
        },
        
        
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder, responseData));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);
    

  return (
    <div>

        <h3>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            <Grid item md={6} >

                <header>
                    <h4>{inputsBuilder[21].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[21].name}
                    value={formData[inputsBuilder[21].name]}
                />

            </Grid>

            {range(0, 5).map((current, index, range) => {

                return <Grid item md={6} key = {current} >

                    <header>
                        <h4>{inputsBuilder[current].label}</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="select"
                        name={inputsBuilder[current].name}
                        value={formData[inputsBuilder[current].name]}
                    />

                    </Grid>
            })}

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[5].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[5].name}
                    value={formData[inputsBuilder[5].name]}
                />

            </Grid>

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[6].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[6].name}
                    value={formData[inputsBuilder[6].name]}
                />

            </Grid>



            <Grid item md={8} >

                <header>
                    <h4>{inputsBuilder[7].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[7].name}
                    value={formData[inputsBuilder[7].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[7].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            { formData[inputsBuilder[7].name] === '1' && <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[8].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[8].name}
                    value={formData[inputsBuilder[8].name]}
                />

            </Grid> }

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[9].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[9].name}
                    value={formData[inputsBuilder[9].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[9].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[10].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[10].name}
                    value={formData[inputsBuilder[10].name]}
                />

            </Grid>

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[11].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[11].name}
                    value={formData[inputsBuilder[11].name]}
                />

            </Grid>

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[12].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[12].name}
                    value={formData[inputsBuilder[12].name]}
                />

            </Grid>

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[13].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[13].name}
                    value={formData[inputsBuilder[13].name]}
                />

            </Grid>

        
        </Grid>

        <br/>
        <h3>מסמכים שצורפו לסופו הדיווח:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >
            
        {range(14, 19).map((current, index, range) => {

            return <Grid item md={6} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[current].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

                </Grid>
            })}

            <Grid item md={6} >

                <header>
                    <h4>{inputsBuilder[20].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[20].name}
                    value={formData[inputsBuilder[20].name]}
                />

            </Grid>

        </Grid>

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder,savedData) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = savedData[item.name] ?  savedData[item.name] : item.defaultValue
        
    })
    
    return objReturn;

}

