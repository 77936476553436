import React, { Component } from 'react'

export default class Finance extends Component {
    render() {
        return (
            <div>
                <h2 className="titStyle ">הנהלת חשבונות</h2>
                <ul>
                    <li>FINANCE PAGE</li>
                </ul>
            </div>
        )
    }
}
