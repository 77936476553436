import React, { Component } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; //npm install react-router-dom
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import { RestUrls } from "./Urls";
import SiteConnector from "./SiteConnector";
import Loading from "./-HELPERS-/Loading";

//import MaterialTest  from './pages/desktop/MaterialTest/index';

export default class getPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      media: isMobile ? "mobile" : "desktop",
      lang: "he",
      SiteDataitems: [],
      user: JSON.parse(localStorage.getItem("user-info")),
      PageDataSeo: [],
      isReady: false,
    };
  }

  async componentDidMount() {
    let userJ = JSON.parse(localStorage.getItem("user-info"));
    let user = userJ.data;

    //let loginDate = user.dateTimeLogin;
    if (user.dateTimeLogin) {
      let now1 = new Date();
      let loginDate = new Date(
        user.dateTimeLogin.toString().replace(/-/g, "/")
      ); // IPHONE FIX
      let nowDate = new Date(now1.toString().replace(/-/g, "/")); // IPHONE FIX

      const diffTime = Math.abs(nowDate - loginDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 1) {
        localStorage.setItem("user-info", "");
        window.location.reload();
      }
    }

    //console.log(diffTime + " milliseconds");
    //console.log(diffDays + " days");

    const API = RestUrls.SiteData;

    let siteCode = { siteCode: RestUrls.Code };

    const res = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(siteCode),
    });

    const data = await res.json();

    //console.log(this.props.location.pathname);

    this.setState({
      SiteDataitems: data,
      isReady: true,
    });
  }

  render() {
    //let currentPath = this.props.location.pathname;
    //console.log(this.props.location);
    //localStorage.setItem('currentPath',currentPath);
    localStorage.setItem("currentPathSearch", this.props.location.search);

    if (this.state.isReady) {
      if (this.state.user.data.userCredential === "socialWorker") {
        return (
          <React.Fragment>
            <Router>
              <Switch>
                {/* Route for adding new home visits for social workers  */}
                <Route path="/הוספת_ביקור_בית" component={() => <SiteConnector page="HomeVisitsNewVisit" info={this.state} />} />
                <Route
                  path="/"
                  component={() => (
                    <SiteConnector page="homeVisits" info={this.state} />
                  )}
                />
              </Switch>
            </Router>
          </React.Fragment>
        );
      } else
        return (
          <React.Fragment>
            <Router>
              <Switch>
                {this.state.SiteDataitems.pages.map((pageData) => {
                  if (pageData.seo.method !== "users") {
                    return (
                      <Route
                        key={pageData.id}
                        path={"/" + pageData.seo.friendly}
                        component={() => (
                          <SiteConnector
                            pageName={pageData.title}
                            page={pageData.seo.method}
                            info={this.state}
                          />
                        )}
                      />
                    );
                  } else if (
                    (pageData.seo.method === "users" &&
                      this.state.user.data.userCredential === "superAdmin") ||
                    (pageData.seo.method === "users" &&
                      this.state.user.data.userCredential === "admin")
                  ) {
                    return (
                      <Route
                        key={pageData.id}
                        path={"/" + pageData.seo.friendly}
                        component={() => (
                          <SiteConnector
                            pageName={pageData.title}
                            page={pageData.seo.method}
                            info={this.state}
                          />
                        )}
                      />
                    );
                  } else return false;
                })}

                <Route
                  path="/"
                  component={() => (
                    <SiteConnector page="index" info={this.state} />
                  )}
                />
              </Switch>
            </Router>
          </React.Fragment>
        );
    } else {
      return <Loading open={true} noBackdrop={true} />;
    }
  }
}
