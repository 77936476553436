import React from 'react'
import { useState } from 'react';
import { Button } from "react-bootstrap";
import loader from "../../../../../img/preLoader.gif";
import getDataFromApi from '../../../../DinamicForm/newApi/getDataFromApi';
import { toast } from 'react-toastify';

export default function InsertComment(props) {

    const { action,seniorId,workerId,setCommentsLog } = props

    const [text, setText] = useState('');
    const [ loading, setLoading] = useState(false);

    const addNewComment = () => { 

        //console.log('addNewComment');

        const sendData = {

            action : action,
            seniorId : seniorId,
            workerId : workerId,
            text : text

        }

        sendtoApi(
            "insertComment",
            "first",
            sendData,
            setLoading,
            setCommentsLog,
            setText
        );

    }


  return (
    <div className="insertComment">

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        <input
            className="form-control"
            placeholder="הערה חדשה"
            value={text}
            onChange={(e) => setText(e.target.value)}
        />

        <Button
            className="addRecordBtn"
            onClick={ addNewComment }
            disabled={ loading || !text ? true : false }
            variant={text ? "primary" : "secondary"} >
            הוספת הערה
        </Button>

    </div>
  )
}

function sendtoApi(
    url,
    controller,
    objectToSend,
    setLoading,
    setResponseData,
    setText
  ) {

    setLoading(true);
  
    //console.log(url);
  
    const getData = getDataFromApi(url, objectToSend, controller, "all");
  
    getData.then((getData) => {

      if (getData.error) {
        toast.error(getData.error, {
          toastId: "responseError",
        });
        
      }

      if (getData.ok) {

        toast.success(getData.ok);

        if (getData?.data) {
            setResponseData(getData?.data);
            setText('');
        }
        
      } else {

        toast.error("שגיאה", {
          toastId: "genericError",
        });

      }

    });
  
    getData.catch((error) => {
      console.log(error);
    });
  
    getData.finally(() => {
      setLoading(false);
    });

  }