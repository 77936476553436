import React, { useEffect, useState } from 'react'

import { map,range } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function SocialOpinion(props) {

    const { setMoreDataPdf, sendForm,  responseData } = props


    const inputsBuilder = [


        {
            'name' : 'social1',
            'label' : 'נפגשתי עם המטופל אשר...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'social1Text',
            'label' : 'ביום',
            'defaultValue': '',
            
        },
        {
            'name' : 'social2',
            'label' : 'משך היכרות עם המטופל...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'social2Text',
            'label' : 'הינו (חודשים/שים)',
            'defaultValue': '',
            
        },

        {
            'name' : 'social3',
            'label' : 'חוות הדעת מוגשת על ידי לשכה פרטיט',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        

        {
            'name' : 'social4',
            'label' : 'חוות הדעת מוגשת על ידי עובד סוציאלי של הרשות המקומית',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'theText',
            'label' : 'פירוט חוות הדעת',
            'defaultValue': '',
            
        },
       
        
        
        
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder, responseData));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    

    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);
    

  return (
    <div>

        <h3>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            <Grid item md={8} >

                <header>
                    <h4>{inputsBuilder[0].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[0].name}
                    value={formData[inputsBuilder[0].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[0].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            { formData[inputsBuilder[0].name] === '1' && <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[1].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[1].name}
                    value={formData[inputsBuilder[1].name]}
                />

            </Grid> }

            <Grid item md={8} >

                <header>
                    <h4>{inputsBuilder[2].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[2].name}
                    value={formData[inputsBuilder[2].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[2].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            { formData[inputsBuilder[2].name] === '1' && <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[3].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[3].name}
                    value={formData[inputsBuilder[3].name]}
                />

            </Grid> }


            {range(4, 6).map((current, index, range) => {

            return <Grid item md={12} key = {current}>

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[current].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

                </Grid>
            })}

            <Grid item md={12} >

                <header>
                    <h4>{inputsBuilder[6].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[6].name}
                    value={formData[inputsBuilder[6].name]}
                />

            </Grid>
        
        </Grid>

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder,savedData) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = savedData[item.name] ?  savedData[item.name] : item.defaultValue
        
    })
    
    return objReturn;

}

