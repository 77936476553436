/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extend-native */
import React, { useEffect, useState } from "react";
import getDataFromApi from "../../../DinamicForm/getDataFromApi";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";

import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import Loading from "../../../-HELPERS-/Loading";

import wazeLogo from "../../../../img/icons/wazze.png";
import FilterBar from "./FilterBar";

function OutdatedVisits(props) {
  function filterArrayByClientName(arr, str) {
    return arr.filter(
      (item) =>
        item.clientData.name.startsWith(str) ||
        item.clientData.name.includes(str)
    );
  }

  //console.log('props', props);
  const [clientNameFilter, setClientNameFilter] = useState("");

  const { editRow } = props;

  const history = useHistory();
  const { data } = JSON.parse(localStorage.getItem("user-info"));
  const [userData, _] = useState(data);

  const [visitsData, setVisitsData] = useState([]);
  const [initVisitData, setInitVisitData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  Date.prototype.getDateWithoutTime = function () {
    return new Date(this.toDateString());
  };
  let getData = async () => {
    setIsLoading(true);

    try {
      const res = await getDataFromApi(
        "getMonthVisits",
        {
          token: userData.token,
          socialWorkerId: userData.id,
        },
        "HomeVisits"
      );

      setIsLoading(false);
      return res;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      setIsLoading(true);

      async function fetchData() {
        const res = await getData();

        setIsLoading(false);
        setVisitsData(res);
        setInitVisitData(res);
      }

      fetchData();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, []);
  const onClientNameChange = (e) => {
    setClientNameFilter(e.target.value);
    setVisitsData(filterArrayByClientName(initVisitData, e.target.value));
  };
  function handleClick(clientId, type = 1) {
    history.push(`/הוספת_ביקור_בית?clientId=${clientId}&ReportHome1=${type}`);
  }
  function getObjectByClientName(clientName, array) {
    return array.find(
      (item) => item.clientData && item.clientData.name === clientName
    );
  }
  useEffect(() => {
    console.log(visitsData, "visitsData");
  }, [clientNameFilter, visitsData]);
  //console.log('VisitsData', visitsData);

  return (
    <div>
      <Loading open={isLoading} noBackdrop={true} />

      <div>
        <h2 className="titStyle mt-5">ביקורי בית הקרובים</h2>
        <div className="w-50  py-2">
          <input
            type="text"
            value={clientNameFilter}
            onChange={onClientNameChange}
            placeholder="סינון שם מטופל"
            className="form-control"
          />{" "}
        </div>
        <FilterBar visitsData={visitsData} setVisitsData={setVisitsData} />
        <br />
        {!isMobile ? (
          <div className="tableDisplay">
            <div className="trTable titlesTr">
              <div>#</div>
              <div>שם מטופל</div>
              <div>אנשי קשר</div>
              <div>תאריך ביקור אחרון</div>
              <div>תאריך ביקור מתוכנן</div>
              <div>סוג ביקור</div>
              <div>התחל ביקור</div>
            </div>
            <div className="trTable contentTds" style={{ background: "none" }}>
              <div className="vSpace"></div>
            </div>
            {visitsData &&
              visitsData.map((item, i) => {
                const lastVisitDate = item.fromDateVisit;

                return (
                  <div key={item.clientData.id} className="trTable contentTds">
                    <div></div>
                    <div>{item.clientData.name}</div>
                    <div className="contacts">
                      <ShowPhone data={item.clientData} />
                    </div>
                    <div>
                      {!item?.fromDateVisit ? "טרם התבצע ביקור" : lastVisitDate}
                    </div>
                    <div>{item?.nextVisit ? item.nextVisit : "-"}</div>
                    <div>
                      {item.visitType !== undefined
                        ? item.visitType
                        : "ביקור לפני השמה"}
                    </div>
                    <div>
                      {item?.id && item.is_done !== "1" ? (
                        <Fragment>
                          <button className="enterBtn">
                            <div onClick={() => editRow(item)}>
                              <span>המשך ביקור</span>
                              <i className="fas fa-arrow-circle-left" />
                            </div>
                          </button>
                        </Fragment>
                      ) : (
                        <button
                          onClick={() => {
                            handleClick(
                              item.clientData.id,
                              !item?.fromDateVisit ? 2 : 1
                            );
                          }}
                          className="enterBtn"
                        >
                          <a
                            href={`/הוספת_ביקור_בית?clientId=${
                              item.clientData.id
                            }&ReportHome1=${!item?.fromDateVisit ? 2 : 1}`}
                          >
                            התחלת ביקור
                            <i className="fas fa-arrow-circle-left" />
                          </a>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="mobile-tab">
            {visitsData &&
              visitsData.map((item, i) => {
                const lastVisitDate = item.fromDateVisit;
                return (
                  <div key={i} className="mobile-table">
                    <div></div>
                    <div className="table-item">
                      <p className="title">שם מטופל</p>
                      <p className="contanct">{item.clientData.name}</p>
                    </div>
                    <div className="table-item">
                      <p className="title">תאריך ביקור אחרון</p>
                      <p className="contanct">
                        {!item?.fromDateVisit
                          ? "טרם התבצע ביקור"
                          : lastVisitDate}
                      </p>
                    </div>

                    <div className="table-item">
                      <p className="title">תאריך ביקור מתוכנן</p>
                      <p className="contanct">
                        {item?.nextVisit ? item.nextVisit : "-"}
                      </p>
                    </div>

                    {item?.fromDateVisit && (
                      <div className="table-item">
                        <p className="title">סוג ביקור</p>
                        <p className="contanct">{item.visitType}</p>
                      </div>
                    )}

                    <div className="table-item">
                      <p className="title">אנשי קשר</p>

                      <p className="contanct">
                        <ShowPhone data={item.clientData} />
                      </p>
                    </div>

                    <div className="table-item">
                      <p className="title">כתובת</p>

                      <p className="contanct">
                        <ShowAddress data={item.clientData} />
                      </p>
                    </div>

                    <div className="table-button">
                      <>
                        {" "}
                        <div className="contanct">
                          {item?.id && item.is_done !== "1" ? (
                            <Fragment>
                              <button className="btn btn-light">
                                <div onClick={() => editRow(item)}>
                                  <span>המשך ביקור</span>
                                  <i className="fas fa-arrow-circle-left" />
                                </div>
                              </button>
                            </Fragment>
                          ) : (
                            <button
                              onClick={() => {
                                handleClick(
                                  item.clientData.id,
                                  !item?.fromDateVisit ? 2 : 1
                                );
                              }}
                              className="btn btn-light"
                            >
                              <a
                                href={`/הוספת_ביקור_בית?clientId=${
                                  item.clientData.id
                                }&ReportHome1=${!item?.fromDateVisit ? 2 : 1}`}
                              >
                                התחל ביקור
                                <i className="fas fa-arrow-circle-left" />
                              </a>
                            </button>
                          )}
                        </div>
                      </>
                    </div>

                    <hr />
                    <br />
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default OutdatedVisits;

function ShowPhone(props) {
  const { data } = props;

  if (!data?.phoneSMS) {
    //console.log('data', data);

    return <span>-</span>;
  }

  if (data.phoneSMS.length > 5) {
    if (isMobile) {
      return (
        <a href={`tel:${data.phoneSMS}`}>
          <span>{data.phoneSMS}</span>
        </a>
      );
    } else {
      return <span>{data.phoneSMS}</span>;
    }
  } else if (data.phone.length > 5) {
    if (isMobile) {
      return (
        <a href={`tel:${data.phone}`}>
          <span>{data.phoneSMS}</span>
        </a>
      );
    } else {
      return <span>{data.phone}</span>;
    }
  } else {
    if (isMobile) {
      return (
        <Fragment>
          {data.contacts.map((item, i) => {
            return (
              <a href={`tel:${item.phone}`} key={item.id}>
                <span>
                  {item.name} - {item.phone} - {item.proximity}{" "}
                </span>
              </a>
            );
          })}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {data.contacts.map((item, i) => {
            return (
              <span key={item.id}>
                {item.name} - {item.phone} - {item.proximity}{" "}
              </span>
            );
          })}
        </Fragment>
      );
    }
  }
}

function ShowAddress(props) {
  const { data } = props;

  let url = "https://www.waze.com/ul?navigate=yes&zoom=17";

  url += "&q=" + data.address + ", " + data.city;

  return (
    <span className="addressCont">
      <span>
        {data.city}, {data.address}
      </span>
      <a href={url} target="_blank" rel="noreferrer">
        <img src={wazeLogo} alt="waze" />
      </a>
    </span>
  );
}
