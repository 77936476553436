import React, { Component, useState } from "react";

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
//import { InputGroup, FormControl } from 'react-bootstrap';

import SearchAutocompleteAndDbFocusClients from "./SearchAutocompleteAndDbFocusClients";
import convertDate, { changeFormatToSlashFormat } from "./../../../convertDateToDisplay";
import { getAllUrlParams } from "./../../../UrlParameters";

// import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
// import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

import HomeVisitsEditRow from "./HomeVisitsEditRow";
//import md5 from 'md5';

import { Button } from "react-bootstrap";
import EditVisitBeforeAsama from "./EditVisitBeforeAsama";

import { RestUrls } from "./../../../Urls";
import OutdatedVisits from "./OutdatedVisits";
import { toast } from "react-toastify";

import loader from "../../../../img/preLoader.gif";
import { withRouter } from 'react-router-dom';
class HomeVisits extends Component {
  constructor(props) {
    super(props);
    let today = new Date();
    this.state = {
      data: "",
      dataClient: false,
      visitType: "",
      comments: "",
      created_at: new Date(today.toString().replace(/-/g, "/")),
      trimmedDataURL: null,
      responseVisit: false,
      selectedClientId: "",
      chosenSocialWorker: "",
      editRowId: false,
      showHistory: false,
    };
  }

  loadData = (
    url,
    controller,
    objectToSend = {},
    stateNameResponse = false
  ) => {
    //console.log('IN');

    //let objectToSend = {hello : '1'}

    let setStateName;

    if (stateNameResponse) {
      setStateName = stateNameResponse;
    } else {
      setStateName = "data";
    }

    let getData = getDataFromApi(url, objectToSend, controller);
    getData
      .then(function (response) {
        return response;
      })
      .then((resData) => {
        //console.log({ [setStateName]: resData });
        this.setState({ [setStateName]: resData });
      });
  };

  getClientId() {
    let urlParams = localStorage.getItem("currentPathSearch")
      ? localStorage.getItem("currentPathSearch")
      : false;
    let urlQuery = urlParams ? getAllUrlParams(urlParams) : false;

    if (urlQuery.id) {
      return urlQuery.id;
    } else {
      return false;
    }
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(this.props.location.search);
    const clientId = queryParams.get('id');


    if (clientId) {
      this.setState({ selectedClientId: clientId }, function () {
        this.loadData(
          "getclientData",
          "homeVisits",
          { clientId: clientId },
          "dataClient"
        );
      });
    }

    //this.loadData('getclients','homeVisits');
    //
  }

  getHistoryData = () => {
    let send = {};

    if (!this.state.showHistory) {
      this.setState({ showHistory: true });

      send = {
        clientId: this.state.selectedClientId,
        history: true,
      };
    } else {
      this.setState({ showHistory: false });

      send = {
        clientId: this.state.selectedClientId,
      };
    }

    this.loadData("getclientData", "homeVisits", send, "dataClient");
  };

  reload = () => {
    this.loadData(
      "getclientData",
      "homeVisits",
      { clientId: this.state.selectedClientId },
      "dataClient"
    );
  };

  handleSearchSelected = (item) => {
    window.location.replace("/ביקורי_בית?id=" + item.id);
    // this.setState({ selectedClientId: item.id }, function () {
    //   this.loadData(
    //     "getclientData",
    //     "homeVisits",
    //     { clientId: item.id },
    //     "dataClient"
    //   );
    // });
  };

  editRow = (item) => {
    //console.log(item);

    let stateItem = "";

    if (item.name === "ביקור לפני השמה") {
      stateItem = {
        id: item.id,
        name: "ביקור לפני השמה",
      };
    } else {
      stateItem = item.id;
    }

    this.setState({ editRowId: stateItem });
  };

  pdfMaker = (item) => {
    const md5 = require("md5");

    let url =
      RestUrls.baseApiUrl1 +
      "DigformsPDF/makePdfVisitBeforeAsama?id=" +
      item.id +
      "&token=";

    url = url + md5(item.id + "|" + RestUrls.Code);

    window.open(url, "_blank");

    //console.log(url);
  };

  editMyRow = (item) => {
    this.editRow(item);
  };

  render() {
    let userJ = JSON.parse(localStorage.getItem("user-info"));
    let user = userJ.data;
    const { userCredential } = user;
    const md5 = require("md5");

    //console.log(this.state.dataClient);

    return (
      <div className="homeVisitsPage">
        {/* Edit row */}
        {!this.state.editRowId ? (
          <>
            <div className="searchClients">
              {user.userCredential !== "1" ? (
                <div>
                  {true ? (
                    <div className="row justify-content-center selectorContent">
                      <div className="col-lg-8 text-center selectClientsWorkers">
                        <div className="row">
                          <div className="SearchAutocomplete">
                            {user.userCredential !== "socialWorker" ||
                            (user.userCredential === "socialWorker" &&
                              parseInt(this.props.pageData.countClients) >=
                                1) ? (
                              <div className="w-100 p-1">
                                <SearchAutocompleteAndDbFocusClients
                                  keys={["name"]}
                                  coordinatorId={user.id}
                                  placeholder="חיפוש לקוחות לצפייה ההיסטוריה ביקורי בית"
                                  submit={this.handleSearchSelected}
                                  DBItems={"homeVisits/getclients?searchData=1"}
                                />
                              </div>
                            ) : (
                              <h2 style={{ color: "red" }}>
                                אין לקוחות משוייכים
                              </h2>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                false
              )}
            </div>

            {this.state.dataClient ? (
              <section className="clientDataVisit">
                <div className="headerCont">
                  <h2 className="titStyle">
                    לקוח -{" "}
                    {this.state.dataClient.client.name +
                      " " +
                      this.state.dataClient.client.surname}
                  </h2>

                  <Button
                    onClick={() => this.getHistoryData()}
                    size="sm"
                    variant="secondary"
                  >
                    {!this.state.showHistory ? (
                      <span>היסטוריית ביקורי הבית &raquo;</span>
                    ) : (
                      <span>&laquo; חזור</span>
                    )}
                  </Button>
                </div>
                {this.state.dataClient.homeVisitsLog[0] && (
                  <div>
                    {!isMobile ? (
                      <div className="tableComments SeniorContacts desktop tableDisplay">
                        <div className="seniorsTableContact">
                          <div className="trTable titlesTr">
                            <div className="tdTable">תאריך</div>
                            <div className="tdTable">סוג ביקור</div>
                            <div className="tdTable">שם עובדים סוציאלים</div>
                            <div className="tdTable">#</div>
                            <div className="tdTable">חתימה</div>
                            <div className="tdTable">#</div>
                          </div>
                          <div className="trTable contentTds">
                            <div className="vSpace"></div>
                          </div>

                          {this.state.dataClient.homeVisitsLog.map((item) => {
                            console.log(item)
                            /* currentState={this.state} */
                            return (
                              <div key={item.id} className="trTable contentTds">
                                <div className="col-md-2 divBoostrap col-sm-12 name">
                                  <p>
                                    {item?.created_view
                                      ? changeFormatToSlashFormat(item?.created_view)
                                      : convertDate(item?.created)}
                                  </p>
                                </div>
                                <div className="col-md-2 divBoostrap col-sm-12 name">
                                  <p>{item.name}</p>
                                </div>
                                <div className="col-md-2 divBoostrap col-sm-12 name">
                                  <p>{item.socialWorker_name}</p>
                                </div>
                                <div className="col-md-2 divBoostrap col-sm-12 name">
                                  {item.comments && <p>{item.comments}</p>}
                                  {(user?.userCredential === "superAdmin" ||
                                    user?.userCredential === "admin") && (
                                    <DeleteReport
                                      item={item}
                                      reload={() => this.reload()}
                                    />
                                  )}
                                </div>
                                <div className="col-md-2 divBoostrap col-sm-12 name">
                                  {item.sign ? (
                                    <img src={item.sign} alt="sign" />
                                  ) : null}
                                </div>
                                {(item.name === "ביקור חצי שנתי" ||
                                  item.name ===
                                    "ביקור אחרי השמה (30 יום אחרי)") &&
                                  item.hasData && (
                                    <div className="col-md-2 divBoostrap col-sm-12 buttons">
                                      <button
                                        onClick={() => this.editRow(item)}
                                      >
                                        <i className="fas fa-edit"></i>
                                        <span>עריכה</span>
                                      </button>
                                      <a
                                        href={
                                          "/הוספת_ביקור_בית?print=" +
                                          md5(item.id)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="fas fa-print"></i>
                                        <span>הדפסה</span>
                                      </a>
                                    </div>
                                  )}
                                {item.name === "ביקור לפני השמה" &&
                                  item.hasData && (
                                    <div className="col-md-2 divBoostrap col-sm-12 buttons">
                                      <button
                                        onClick={() => this.editRow(item)}
                                      >
                                        <i className="fas fa-edit"></i>
                                        <span>עריכה</span>
                                      </button>
                                      <button
                                        onClick={() => this.pdfMaker(item)}
                                      >
                                        <i className="fas fa-file-pdf"></i>
                                        <span>הורדת PDF</span>
                                      </button>
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="mobile-tab">
                        {this.state.dataClient.homeVisitsLog &&
                          this.state.dataClient.homeVisitsLog.map((item, i) => {
                            const lastVisitDate = convertDate(item.created);

                            return (
                              <div key={i} className="mobile-table">
                                <div className="table-item">
                                  <p className="title">תאריך</p>
                                  <p className="contanct">{lastVisitDate}</p>
                                </div>
                                <div className="table-item">
                                  <p className="title">סוג ביקור</p>
                                  <p className="contanct">
                                    <span>{item.name}</span>
                                  </p>
                                </div>
                                <div className="table-item">
                                  <p className="title"> שם עובדים סוציאלים</p>
                                  <p className="contanct">
                                    {item.socialWorker_name}
                                  </p>
                                </div>
                                <div className="table-item">
                                  <p className="title"> הערות</p>
                                  <p className="contanct">{item.comments} </p>
                                </div>
                                <div className="table-item">
                                  <p className="title"> חתימה</p>
                                  <p className="contanct">
                                    {" "}
                                    {item.sign ? (
                                      <img src={item.sign} alt="sign" />
                                    ) : null}{" "}
                                  </p>
                                </div>
                                <div className="table-button">
                                  <>
                                    {" "}
                                    <div className="contanct">
                                      {(item.name === "ביקור חצי שנתי" ||
                                        item.name ===
                                          "ביקור אחרי השמה (30 יום אחרי)") &&
                                        item.hasData && (
                                          <div className="col-md-2 divBoostrap col-sm-12 buttons">
                                            <button
                                              className="btn btn-light"
                                              onClick={() => this.editRow(item)}
                                            >
                                              <i className="fas fa-edit"></i>
                                              <span>עריכה</span>
                                            </button>
                                            <button className="btn btn-light">
                                              <a
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                                href={
                                                  "/הוספת_ביקור_בית?print=" +
                                                  md5(item.id)
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <i className="fas fa-print"></i>
                                                <span>הדפסה</span>
                                              </a>
                                            </button>
                                          </div>
                                        )}
                                      {item.name === "ביקור לפני השמה" &&
                                        item.hasData && (
                                          <div className="col-md-2 divBoostrap col-sm-12 buttons">
                                            <button
                                              className="btn btn-light"
                                              onClick={() => this.editRow(item)}
                                            >
                                              <i className="fas fa-edit"></i>
                                              <span>עריכה</span>
                                            </button>
                                            <button
                                              className="btn btn-light"
                                              onClick={() =>
                                                this.pdfMaker(item)
                                              }
                                            >
                                              <i className="fas fa-file-pdf"></i>
                                              <span>הורדת PDF</span>
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                  </>
                                </div>
                                <hr />
                                <br />
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                )}{" "}
              </section>
            ) : (
              false
            )}

            {(user?.userCredential === "superAdmin" ||
              user?.userCredential === "admin" ||
              user?.userCredential === "coordinator") && (
              <div className="newVisitBtnCont clear">
                {!this.state.selectedClientId ? (
                  <div></div>
                ) : (
                  // <NavLink
                  //   onClick={() => scroll.scrollTo(0)}
                  //   to={`/הוספת_ביקור_בית?clientId=${this.state.selectedClientId}`}
                  // >
                  //   <span>הוספת ביקור חדש</span>
                  // </NavLink>
                  <a
                    href={`/הוספת_ביקור_בית?clientId=${this.state.selectedClientId}`}
                  >
                    <span>הוספת ביקור חדש</span>
                  </a>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="editRow">
            {console.log("EEE",this.state.editRowId)}
            <button
              className="goBackCustomBtn bounce"
              onClick={() => {
                if (this.state.selectedClientId) {
                  window.location.replace("/ביקורי_בית?id=" + this.state.selectedClientId)
                } else {
                  this.setState({ editRowId: false });
                }
              }}
            >
              « חזור
            </button>
            {this.state.editRowId?.name && this.state.editRowId?.id ? (
              <EditVisitBeforeAsama editRowId={this.state.editRowId} />
            ) : (
              <HomeVisitsEditRow editRowId={this.state.editRowId} />
            )}
          </div>
        )}
        <div className="newVisitBtnCont clear"></div>
        {/* OUTDATED VISITS */}
        {userCredential === "socialWorker" && !this.state.dataClient && (
          <OutdatedVisits
            submit={this.handleSearchSelected}
            editRow={this.editMyRow}
          />
        )}
        {/* OUTDATED VISITS */}
      </div>
    );
  }
}
export default withRouter(HomeVisits);
function DeleteReport(props) {
  const { item, reload } = props;
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteMe = () => {
    const send = {
      rowId: item.id,
    };

    //console.log(send);
    sendtoApi("deletteRow", "homeVisits", send, setLoading, reload);
  };

  return (
    <div className="delBtns">
      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      {!showDelete && (
        <span>
          <i
            className="far fa-trash-alt delete"
            onClick={() => setShowDelete(true)}
          ></i>
        </span>
      )}

      {showDelete && (
        <div className="sureDelete">
          <p>בטוח למחוק?</p>
          <div className="btns">
            <span onClick={() => deleteMe()}>כן</span>
            <span onClick={() => setShowDelete(false)}>ביטול</span>
          </div>
        </div>
      )}
    </div>
  );
}

function sendtoApi(url, controller, objectToSend, setLoading, reload) {
  setLoading(true);

  //console.log(url);

  const getData = getDataFromApi(url, objectToSend, controller, "all");

  getData.then((getData) => {
    setLoading(false);

    if (getData.error) {
      toast.error(getData.error, {
        toastId: "responseError",
      });
    }

    if (getData.ok) {
      toast.success("נמחק בהצלחה");
      reload();

      //console.log("getData", getData);
    } else {
      toast.error("שגיאה", {
        toastId: "genericError",
      });
    }
  });

  getData.catch((error) => {
    console.log(error);
  });
}
