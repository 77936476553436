import React, { Component } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import InputMet from "./InputMet";
import { toast } from "react-toastify";

const PricesArr = [0, 35, 50, 58.50, 60, 70, 140];
const PricesAsama = [0, 500,585,1000,1170,1200,1404,1500,1755,1800,2000];


export default class AddItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemText4: this.props.currentState.itemText4,
      itemPrice1: this.props.currentState.itemPrice1,
      itemPrice2: this.props.currentState.itemPrice2,
      itemPrice3: this.props.currentState.itemPrice3,
      itemPrice4: this.props.currentState.itemPrice4,
    };
  }
  // INPUT
  handleChangeAtSumIdOne = (e) => {};
  // INPUT
  handleChange = async (event) => {
    
    //console.log(event.target.name, event.target.value);
    
    
    
    const monthDifference =  getMonthDifference(
                          new Date(convertDate(this.props.currentState.invoiceDate1)),
                          new Date(convertDate(this.props.currentState.invoiceDate2))
                          );
    
    //const modify = event.target.value / 1.17;
    let eventValue = event.target.value

    if( event.target.name === 'itemPrice1'  && !this.props.currentState.isKevaPay ) {
      
      eventValue = eventValue * monthDifference; 

      if(eventValue <= 0) {
        toast.error('יש לבחור לפחות תקופה של חודש');
      }

    }

    
    

    await this.setState(
      { [event.target.name]: eventValue },
      function () {
        this.props.InvoiceAddItems(this.state, event.target.name.slice(9));
      }
    );
    // await this.props.sumAllItemsAndUpdateState();
  };

  removeKey = () => {
    this.props.removeKey(this.props.idItem);
  };

  render() {
    let idItem = this.props.idItem;

    let nameFromId = this.props.items.filter(function (desc) {
      return desc.id === idItem;
    });

    //console.log(nameFromId);

    return (
      <div className="row ItemsCont">
        {idItem === "1" && !this.props.currentState.isKevaPay ? (
          <div className="row">
            <div className="col-md-6 divBoostrap col-sm-12">
              <InputMet
                text="תקופת תשלום מ-"
                as="dateInput"
                value={this.props.currentState.invoiceDate1}
                name="invoiceDate1"
                submit={this.props.submit}
              />
            </div>

            <div className="col-md-6 divBoostrap col-sm-12">
              <InputMet
                text="תקופת תשלום ל-"
                as="dateInput"
                value={this.props.currentState.invoiceDate2}
                name="invoiceDate2"
                submit={this.props.submit}
              />
            </div>
          </div>
        ) : (
          false
        )}

        <div className="col-md-8 divBoostrap col-sm-12">
          {idItem !== "4" ? (
            <InputGroup className="customInput">
              {/* <InputGroup.Prepend className='inputPrependLabel '>
                        <InputGroup.Text>{nameFromId[0].name}</InputGroup.Text>
                    </InputGroup.Prepend> */}

              <FormControl
                disabled={true}
                //className='inputPrepend'
                onChange={this.handleChange}
                name={"itemText" + idItem}
                value={nameFromId[0].name}
              />
            </InputGroup>
          ) : (
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel ">
                <InputGroup.Text>פירוט</InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                className="inputPrepend"
                onChange={this.handleChange}
                name={"itemText" + idItem}
                value={this.state["itemText" + idItem]}
              />
            </InputGroup>
          )}
        </div>

        <div className="col-md-3 divBoostrap col-sm-12">
          <InputGroup className="customInput">
            <InputGroup.Prepend className="inputPrependLabel ">
              {idItem === "1" && (
                <div className="col-md-12 divBoostrap col-sm-12">
                  <InputGroup className="customInput borderColorGreen">
                    <InputGroup.Prepend className="inputPrependLabel ">
                      <InputGroup.Text>בחירת סכו"ם</InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      as="select"
                      name={"itemPrice" + 1}
                      /* 'inputPrepend customSelect' */
                      className="inputPrepend customSelect"
                      onChange={
                        this.state["itemPrice" + 1] < 99999
                          ? this.handleChange
                          : console.log("notChange")
                      }
                      /* value={
                        this.state["itemPrice" + 1] > 0
                          ? this.state["itemPrice" + 1]
                          : ""
                      } */

                      //for change date
                      value=''
                    >
                      {PricesArr.map((item, id) => {
                        return (
                          <option key={item + id} value={parseFloat(item)}>
                            {item}
                          </option>
                        );
                      })}
                    </FormControl>
                  </InputGroup>
                </div>
              )}

              {idItem === "2" && (
                <div className="col-md-12 divBoostrap col-sm-12">
                  <InputGroup className="customInput borderColorGreen">
                    <InputGroup.Prepend className="inputPrependLabel ">
                      <InputGroup.Text>בחירת סכו"ם</InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      as="select"
                      name={"itemPrice" + 2}
                      /* 'inputPrepend customSelect' */
                      className="inputPrepend customSelect"
                      onChange={
                        this.state["itemPrice" + 2] < 99999
                          ? this.handleChange
                          : console.log("notChange")
                      }
                      value={
                        this.state["itemPrice" + 2] > 0
                          ? this.state["itemPrice" + 2]
                          : ""
                      }
                    >
                      {PricesAsama.map((item, id) => {
                        return (
                          <option key={item + id} value={parseFloat(item)}>
                            {item}
                          </option>
                        );
                      })}
                    </FormControl>
                  </InputGroup>
                </div>
              )}
            </InputGroup.Prepend>
            {(idItem !== "1" && idItem !== "2" )&& (
              <>
                <InputGroup.Text>סכום</InputGroup.Text>
                <FormControl
                  className="inputPrepend"
                  onChange={
                    this.state["itemPrice" + idItem] < 99999
                      ? this.handleChange
                      : console.log("notChange")
                  }
                  name={"itemPrice" + idItem}
                  value={
                    this.state["itemPrice" + idItem] > 0
                      ? this.state["itemPrice" + idItem]
                      : ""
                  }
                  placeholder="0 ₪"
                  type="number"
                  max={100}
                />
              </>
            )}
          </InputGroup>
        </div>

        <div className="col-md-1 divBoostrap col-sm-12">
          <button className="removeBtn" onClick={this.removeKey}>
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      </div>
    );
  }
}


function getMonthDifference(startDate, endDate) {

  //https://bobbyhadz.com/blog/javascript-get-number-of-months-between-two-dates#:~:text=To%20get%20the%20number%20of,12%20and%20return%20the%20sum.

  const value =  endDate.getMonth() -  startDate.getMonth() +  12 * (endDate.getFullYear() - startDate.getFullYear() );

  console.log('getMonthDifference', value);


  return value;
}


function convertDate (dateValue) {

  let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

  /* 2021-03-25 */

  let formatted_date = current_datetime.getFullYear() + '-' +
                      (current_datetime.getMonth() + 1) + "-" +
                      current_datetime.getDate();
                      
  return formatted_date;
}