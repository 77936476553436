import React, { Component } from "react";
import getDataFromApi from "../../../DinamicForm/getDataFromApi";

//import convertDate from "../../../convertDateToDisplay";
import convertDateToDb from "../../../convertDateToDb";

import FiltersVisitReportPlacement from "./FiltersVisitReportPlacement";

import { Button } from "react-bootstrap";
import { ConstantsNames, RestUrls } from "../../../Urls";
import Loading from "../../../-HELPERS-/Loading";
import { toast } from "react-toastify"; //npm i react-toastify

export default class ReportVisitPerPlacement extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.state = {
      columnName: "ביקור אחרון",
      dataResults: false,
      visitType: "ביקור אחרון",
      socialWorker: "",
      date1: firstDay,
      date2: lastDay,
      branch: "",
      company: "",
      exception: false,
      placement: true,
      loading: false,
      daysBefore: 0,
      daysAffter: 0,
    };
  }

  updateState = (state) => {
    this.setState(state, function () {
      //console.log(state);
    });
  };

  sendtoAdmin = (
    url,
    controller,
    objectToSend = {},
    stateNameResponse = false
  ) => {
    this.setState({ loading: true });

    let setStateName;

    if (stateNameResponse) {
      setStateName = stateNameResponse;
    } else {
      setStateName = "data";
    }

    let getData = getDataFromApi(url, objectToSend, controller);
    getData
      .then(function (response) {
        return response;
      })

      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        toast.error("שגיאה בשרת. יש למקד את החיפוש");
      })

      .then((getData) =>
        this.setState({ [setStateName]: getData, loading: false }, function () {
          this.setState({ loading: false });

          if (url === "visitsReportRows") {
            //console.log(getData.rows);
          }
        })
      );
  };

  loadData = () => {
    this.setState({ columnName: this.state.visitType }, function () {
      //console.log(this.state);
      this.sendtoAdmin(
        "visitsReportRows",
        "ReportsExcel",
        this.state,
        "dataResults"
      );
    });
  };

  /* componentDidMount() {
        this.loadData();
    } */

  getCsv = () => {
    var md5 = require("md5");
    
    //let query = "&socialWorker=" + this.state.socialWorker;
    let query =  "&date1=" + convertDateToDb(this.state.date1);
    query = query + "&date2=" + convertDateToDb(this.state.date2);
    query = query + "&company=" + this.state.company;
    query = query + "&branch=" + this.state.branch;
    
    let link =
      ConstantsNames.base_url +
      "/ReportsExcel/excelPlacement?token=" +
      md5(RestUrls.Code) +
      query;
    //let link = ConstantsNames.base_url + '/ReportsPdf/makeCsvVisit?token=' + md5(RestUrls.Code) + query;

    console.log(link, "Link");

    window.open(link, "_blank");
  };

  render() {
    //console.log(this.props.fields);

    return (

      <section className="ReportsSection animate__animated animate__fadeIn">
        
        <Loading open={this.state.loading} noBackdrop={true} />

        <FiltersVisitReportPlacement
          submit={(state) => this.updateState(state)}
          fields={this.props.fields}
        />

        <div style={{ textAlign: "left", margin: "10px 0 0 0" }}>
          <Button onClick={() => this.getCsv()} size="" variant="success">
            שליחה
          </Button>
        </div>

      </section>

    );
  }
}
