import React, { Component } from 'react'
import { ConstantsNames } from "../../../Urls";
import ModalSendMailFiles from './ModalSendMailFiles';
import { RestUrls } from "../../../Urls";
import { Fragment } from 'react';
import { useState } from 'react';

export default class GetAllFiles extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalShow : false,
            item : false,
            mail : this.props.mail
        }
    }
    
    modalShow = (value,item = false) => {

        if(item) {
            this.setState({ item: item, modalShow : value })    
        } else {
            this.setState({ modalShow : value })
        }
        
    }

    deleteMe = (item) => { 

        //console.log('item', item);
        
        this.updateFileStatus(item);
    }

    async updateFileStatus (item) { //delete

        //console.log('updateFileStatus...');

        const API =  RestUrls.baseApiUrl + 'updateFile';
        const userJ = JSON.parse(localStorage.getItem('user-info'));
        const user = userJ.data;

        //console.log(this.state);
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code,
                rowId : item?.data.id
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            //console.log('DONE');
            this.props.loadData()
        }
        
        
        
    }

    
    
    render() {
        return (
            <div className='filesWorker noReka'>
                {this.props.files ? <div><ul className='showData' style={{margin: '0 0 40px 0'}}>
                    {this.props.files.map(item => { if (this.props.categoryId === item.data.category ) {
                        return <li key={item.data.id} >
                            <FileOptions item = {item} modalShow = { () => this.modalShow(true, item) } deleteMe = {() => this.deleteMe(item)} />
                            
                        </li>
                    } else return false}) }
                </ul></div> : false }

                { this.state?.modalShow && <ModalSendMailFiles currentState={this.state} modalShow={(value)=> this.modalShow(value)} /> }
            </div>
        )
    }
}

function FileOptions(props) {

    const {item, modalShow, deleteMe } = props

    const [showDelete, setShowDelete] = useState(false)

    return(
        <Fragment>

            <a className="fileA" href={ConstantsNames.files + item.data.fileName} rel="noreferrer" target='_blank' >
                <i className="far fa-file"></i>
                <span>{item.data.documentName}</span>
            </a>
            <div className='sendMail'>
                <i className="far fa-envelope" onClick={ () => modalShow() }></i>
                {!showDelete && <i className="far fa-trash-alt delete" onClick={() => setShowDelete(true) }></i>}
            </div>
            
            { showDelete && <div className='sureDelete'>
                <p>בטוח למחוק?</p>
                <div className='btns'>
                    <span onClick={() => deleteMe() } >כן</span>
                    <span onClick={ () => setShowDelete(false) } >ביטול</span>
                </div>
            </div>}

        </Fragment>

    )






}
