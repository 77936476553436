export function checkClassName(taskStatus) {

    if(taskStatus === 'טופל') {
        return 'greenTrClass'
    } else if (taskStatus === 'ממתין') {
        return 'redTrClass'
    } else if (taskStatus === 'בתהליך') {
        return 'yellowTrClass'
    } else {
        return ''
    }

}