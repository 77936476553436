export default function convertDate(dateValue) {
  let current_datetime = dateValue
    ? new Date(dateValue.toString().replace(/-/g, "/"))
    : new Date(); // IPHONE FIX
  /* 2021-03-25 */

  /*     let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate(); */

  let formatted_date =
    current_datetime.getDate() +
    "/" +
    (current_datetime.getMonth() + 1) +
    "/" +
    +current_datetime.getFullYear();

  return formatted_date;
}

export function convertDateNew(dateValue) {
  let current_datetime = new Date(dateValue.toString().replace(/-/g, "/")); // IPHONE FIX

  /* 2021-03-25 */

  let formatted_date =
    current_datetime.getFullYear() +
    "-" +
    (current_datetime.getMonth() + 1) +
    "-" +
    current_datetime.getDate();

  return formatted_date;
}

export function changeDateToStandard(dateValue) {
  if (dateValue.toString()?.split(" ")?.[0]?.indexOf(("-")) > -1) {
   return dateValue.toString().split(" ")?.[0];
  }
  return dateValue.toString().replace(/\//g, "-").split("-").reverse().join("-");
}

export function changeDateFromStandard(dateValue) {
 
  return dateValue.toString().replace(/-/g, "/").split("/").reverse().join("/");
}

export function changeFormatToSlashFormat(dateValue) {
  console.log(dateValue)
  if (dateValue.toString()?.split(" ")?.[0]?.indexOf("-") > -1) {
    return dateValue.toString()?.split(" ")?.[0].replace(/-/g, "/").split("/").reverse().join("/")
  }
  // expect date to be in slash format already
  return dateValue.toString()
}