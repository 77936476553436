import React, { Component } from "react";
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import { RestUrls } from "../../../Urls";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchClientsWorkers from "./SearchClientsWorkers";
/* https://www.npmjs.com/package/react-datepicker */

function convertDate(dateValue) {
  let current_datetime = new Date(dateValue.toString().replace(/-/g, "/")); // IPHONE FIX

  /* 2021-03-25 */

  let formatted_date =
    current_datetime.getFullYear() +
    "-" +
    (current_datetime.getMonth() + 1) +
    "-" +
    current_datetime.getDate();

  return formatted_date;
}

async function sendToAdmin(newData) {
  let userJ = JSON.parse(localStorage.getItem("user-info"));
  let user = userJ.data;

  let item = {
    userId: user.id,
    userName: user.username,
    userCredential: user.userCredential,
    token: user.token,
    siteCode: RestUrls.Code,
    upTask: newData.upTask,
    doneTask: newData.doneTask,
    typeTask: newData.typeTask,
    taskStatus: newData.taskStatus,
    seniorId: newData.seniorId,
    workerId: newData.workerId,
    comments: newData.comments,
  };

  let urlAction = "firstTasks/?newTask=1";
  let fletchUrl = RestUrls.baseApiUrl + urlAction;

  let result = await fetch(fletchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(item),
  });

  let data = await result.json();

  if (data === "unauthorized") {
    localStorage.setItem("user-info", "");
    window.location.reload();
  } else {
  }
}

export default class NewTaskModalBody extends Component {
  constructor(props) {
    super(props);

    let now = new Date();
    let current_datetime = new Date(now.toString().replace(/-/g, "/"));

    let userJ = JSON.parse(localStorage.getItem("user-info"));
    let user = userJ.data;

    this.state = {
      upTask: current_datetime,
      doneTask: current_datetime,
      currenId: this.props.currenId,
      userId: user.id,
      seniorId: "",
      workerId: "",
      taskStatus: 1,
      typeTask: 1,
      comments: "",
      sent: false,
    };
  }

  // INPUT
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //For datePicker
  handleChangeDate = (date, nameInput) => {
    let newDate = convertDate(date);
    this.setState({ [nameInput]: newDate });
  };

  //SELECT
  editHandlerSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    //console.log([event.target.value]);
    //this.uptateInputField (event.target.name, event.target.value);
  };

  sendForm = () => {
    //console.log(this.state);
    sendToAdmin(this.state);
    this.setState({ sent: true });
  };

  handleSearchSelected = (item) => {
    let idType = item.searchType === "clients" ? "seniorId" : "workerId";

    //console.log(item);
    //console.log(idType);

    this.setState({ [idType]: item.id });
  };

  render() {
    let selectedDate1 = new Date(
      this.state.upTask.toString().replace(/-/g, "/")
    );
    let selectedDate2 = new Date(
      this.state.doneTask.toString().replace(/-/g, "/")
    );

    return (
      <div>
        {!this.state.sent ? (
          <div className="row">
            <div className="divBoostrap col-sm-12">
              <InputGroup className="customInput dateInput">
                <div className="dateTimeCont">
                  <div className="customPrepend">העלאת משימה</div>
                  <DatePicker
                    showYearDropdown={true}
                    selected={selectedDate1}
                    dateFormat="dd/MM/yy"
                    className="inputPrepend form-control"
                    name="upTask"
                    locale="he"
                    onChange={(date) => this.handleChangeDate(date, "upTask")}
                    disabled={this.props.is_disabled ? true : false}
                  />
                </div>
              </InputGroup>
            </div>

            <br />
            <br />

            <div className="divBoostrap col-sm-12">
              <InputGroup className="customInput dateInput">
                <div className="dateTimeCont">
                  <div className="customPrepend">תאריך לטיפול</div>
                  <DatePicker
                    showYearDropdown={true}
                    selected={selectedDate2}
                    dateFormat="dd/MM/yy"
                    className="inputPrepend form-control"
                    name="doneTask"
                    locale="he"
                    onChange={(date) => this.handleChangeDate(date, "doneTask")}
                    disabled={this.props.is_disabled ? true : false}
                  />
                </div>
              </InputGroup>
            </div>

            <br />
            <br />
            <div
              className="divBoostrap col-sm-12"
              style={{
                zIndex: 2,
              }}
            >
              <SearchClientsWorkers
                keys={["name"]}
                placeholder="חיפוש לקוחות/עובדים"
                submit={this.handleSearchSelected}
                currentSearch={"a"}
                DBItems="SiteDataItems?searchDataMenuItems=1"
              />
            </div>
            <br />
            <br />
            <div
              style={{
                marginBottom: "5px",
              }}
            >
              {this.state.workerId || this.state.seniorId ? (
                <div className="" style={{}}>
                  <button
                    onClick={() =>
                      this.setState({ workerId: "", seniorId: "" })
                    }
                  >
                    לאפס בחירה
                  </button>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="divBoostrap col-sm-12">
              <InputGroup className="customInput">
                <InputGroup.Prepend className="inputPrependLabel">
                  <InputGroup.Text id="basic-addon3">
                    סטטוס משימה
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  className="inputPrepend selectPrepend"
                  onChange={this.editHandlerSelect}
                  name="taskStatus"
                  //value={this.state.taskStatus}
                  //disabled={this.props.is_disabled ? true : false}
                  //id="basic-url"
                  //aria-describedby="basic-addon3"
                >
                  <option>בחר...</option>
                  {this.props.optionsStatusTasks.map((item) => {
                    if (true) {
                      /* currentState={this.state} */
                      return (
                        <option value={item.data.id} key={item.data.id}>
                          {item.data.name}
                        </option>
                      );
                    } else return false;
                  })}
                </FormControl>
              </InputGroup>
            </div>

            <br />
            <br />

            <div className="divBoostrap col-sm-12">
              <InputGroup className="customInput">
                <InputGroup.Prepend className="inputPrependLabel">
                  <InputGroup.Text id="basic-addon3">סוג משימה</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  className="inputPrepend selectPrepend"
                  onChange={this.editHandlerSelect}
                  name="typeTask"
                  //value={this.state.typeTask}
                  //disabled={this.props.is_disabled ? true : false}
                  //id="basic-url"
                  //aria-describedby="basic-addon3"
                >
                  <option>בחר...</option>
                  {this.props.optionsTaskTypes.map((item) => {
                    if (true) {
                      /* currentState={this.state} */
                      return (
                        <option value={item.data.id} key={item.data.id}>
                          {item.data.name}
                        </option>
                      );
                    } else return false;
                  })}
                </FormControl>
              </InputGroup>
            </div>

            <br />
            <br />

            <div className="divBoostrap col-sm-12">
              <InputGroup className="customInput textArea">
                <Form.Label style={{ width: "50px" }}>הערות:</Form.Label>

                <FormControl
                  style={{ paddingRight: "70px" }}
                  as="textarea"
                  rows={3}
                  className="inputPrepend"
                  onChange={this.handleChange}
                  name="comments"
                  value={this.state.comments}
                  disabled={this.props.is_disabled ? true : false}
                />
              </InputGroup>
            </div>

            <div
              className="divBoostrap col-sm-12"
              style={{ margin: "30px 0 0 0", textAlign: "center" }}
            >
              {/* variant={error ? 'danger' : 'success' } */}
              <Button variant="primary" size="lg" onClick={this.sendForm}>
                שלחו
              </Button>
            </div>
          </div>
        ) : (
          <div className="sentForm">
            <h2 className="animate__animated animate__bounce">
              המשימה הוספה בהצלחה
            </h2>
          </div>
        )}
      </div>
    );
  }
}
