import React from 'react'
import { useState } from 'react';
import { Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { isEmpty,size } from "lodash";
import BodyModalReturnMoneyWorker from './BodyModalReturnMoneyWorker';

export default function MoneyReturnWorker(props) {

    const { reloadInvoices,item } = props

    const [modalShow, setModalShow] = useState(false);

    const showHideModal = () => { 

        if(modalShow) {

            reloadInvoices();
            setModalShow(false);

        } else {

            setModalShow(true);

        }

     }


    //console.log(props);

  return (
    <Fragment>

        <Button className='BtnMoneyReturnCard' onClick={ showHideModal  } size="sm" variant="secondary" >< BtnText item = { item } /></Button>

        {/* https://react-bootstrap.netlify.app/components/modal/ */}
        <Modal
            className='animate__animated animate__fadeInDown animate__faster modalAllComments modalInvoices modalMoneyBack'
            animation={false}
            onHide={ showHideModal }
            /* size="sm" // בגודל קטן!!! */
            size="xl" // גדול!! */
            // backdrop="static" // חייב ללחוץ על כפתור!
            /* centered // VERTICA CENTER */
            show={ modalShow }
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header>
                <Modal.Title id="example-custom-modal-styling-title">
                    <h2 className="titStyle">יצירת חשבונית זיכוי</h2>
                </Modal.Title>
                <Button onClick={ showHideModal } variant="secondary" >X</Button>
            </Modal.Header>

            <Modal.Body>

                <BodyModalReturnMoneyWorker {...props} showHideModal = {showHideModal} />
                
            </Modal.Body>
            
            
        </Modal>
        
    </Fragment>
  )
}


function BtnText(props) {

    const { item } = props

    const count = size(item?.moneyReturns?.data);

    if(!isEmpty(item?.moneyReturns?.data)) {

        return <span>זיכוים ({count})</span>    

    } else {

        return <span>זיכוים</span>

    }

}