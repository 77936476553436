import React, { useEffect, useState } from 'react'

import { map,range } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';

export default function RishumLishka(props) {

    const { setMoreDataPdf, sendForm } = props


    const inputsBuilder = [

        {
            'name' : 'bossName',
            'label' : 'שם',
            'defaultValue': '',
            
        },
        {
            'name' : 'bossTz',
            'label' : 'ת.ז.',
            'defaultValue': '',
            
        },
        {
            'name' : 'bossPhone',
            'label' : 'נייד',
            'defaultValue': '',
            
        },
        {
            'name' : 'residenceType',
            'label' : 'בחר אחד',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'אני המטופל...'
                    },
                    {
                        'id' : '2',
                        'name' : 'אני אפוטרופוס...'
                    },
                    {
                        'id' : '3',
                        'name' : 'אני בן משפחה...'
                    },
                    
                ],
        },
        {
            'name' : 'aprotopus',
            'label' : 'ע"פ',
            'defaultValue': '',
            
        },
        
        
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    

    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);
    

  return (
    <div>

        <h3 style={{margin: '0 0 10px 0'}}>פרטי המעסיק (כשמטופל אינו מסוגל לשמש מעסיק)</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            
        {range(0, 3).map((current, index, range) => {

            return <Grid item md={4}  key = {current} >

                <header>
                    <h4>{inputsBuilder[current].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[current].name}
                    value={formData[inputsBuilder[current].name]}
                />

            </Grid>

        })}

        </Grid>

        <h3 style={{margin: '30px 0 10px 0'}}>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[3].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[3].name}
                    value={formData[inputsBuilder[3].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[3].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            {formData[inputsBuilder[3].name] === '2' && <Grid item md={4} >

                    <header>
                        <h4>{inputsBuilder[4].label}</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[4].name}
                        value={formData[inputsBuilder[4].name]}
                    />

            </Grid>}

            

        </Grid>

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = item.defaultValue
        
    })
    
    return objReturn;

}

