
import React, { Component } from 'react'
import { Alert, InputGroup, FormControl, ProgressBar, Button } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import { RestUrls } from "../../../Urls";
import axios from 'axios';

export default class SendSMSModalBody extends Component {
    
    constructor(props) {

        super(props);

        this.state = {
            sendPage : this.props.sendPage ? this.props.sendPage : '',
            show : false,
            dataFiles : [],
            sentForm : false, // show / hide form
            inputFile: '',
            uploadError: '',
            name : '',   //File Upload
            uploadProgress : '', //File Upload
            selectedFile: '',  //File Upload
            smsText : '',
            insertSMSid : false,
            smsTestNumber : '',
            sendAdminResponse : false,
            sendSmsLastBtnDisabled : false
        };
               
    }


    
    //File Upload
    fileSelectedHandler = event => {
        if(event.target.files[0] && event.target.files[0].name && event.target.files[0] ) {
            this.setState({
                selectedFile : event.target.files[0],
                inputFile : event.target.files[0].name
            })
        }
        
        
    }


    // INPUT
    handleChange = (event) => {

       
        this.setState({ smsText: event.target.value},
            function(){
                //console.log(this.state.smsText)
                });
        
        //console.log(this.state.inputValue);
    }
    
    

    //File Upload
    fileUploadHandler = () => {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        // let user = userJ.data;

        const fd = new FormData();

        // fd.append('userId',  user.id);
        // fd.append('userCredential',  user.userCredential);
        // fd.append('token',  user.token);
        //fd.append('name',  this.state.name);

        fd.append('siteCode',  RestUrls.Code);
        fd.append('action',  'SMSfile');
        fd.append('smsText', this.state.smsText);
        fd.append('idRows', this.props.rowsSelected);
        fd.append('sendPage', this.state.sendPage);
        fd.append('userId',  userJ.data.id);

        
        if(this.state.selectedFile.name) {
            fd.append('filename', this.state.selectedFile, this.state.selectedFile.name);
        }
        

        axios.post( RestUrls.filesMethod + 'SMS', fd, {
            onUploadProgress: progressEvent => {
                this.setState({ uploadProgress : Math.round(progressEvent.loaded / progressEvent.total * 100 ) });
                //console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100 ) + '%')
            }
        })
            .then( res => {

                //console.log(res);
                //console.log(res.data);


                if(res.data === 'unauthorized') {
                    localStorage.setItem('user-info','');
                    window.location.reload();

                } else {

                    console.log(res.data);

                    if(res.data.insert_SMSid) {
                        
                        this.setState({
                            sentForm : true, //SENT,
                            uploadError : res.data.uploadError ? res.data.uploadError : '',
                            inputFile: '',
                            name : '',   //File Upload
                            uploadProgress : null, //File Upload
                            selectedFile: null,  //File Upload
                            insertSMSid : res.data.insert_SMSid
                        });

                        //alert(res.data.insert_SMSid);
                    } else {
                        alert("ERROR");
                    }
                    
                    //this.loadData();
                }
            });
    }


    handleChangeSMS = (event) => {
        this.setState({ smsTestNumber: event.target.value},
            function(){
                //console.log(this.state.smsTestNumber)
        });
           
    }
    
    sendToAdmin = (url,objectToSend) => {

        //console.log('IN');

        let getData = getDataFromApi(url,objectToSend);
        getData.then(function(response) {return response;}).then(getData =>this.setState({sendAdminResponse: getData}));
    }

    sendSmsTest = () => {

        

        let objectToSend = {
            smsTestNumber : this.state.smsTestNumber,
            insertSMSid : this.state.insertSMSid
        }

        this.sendToAdmin('sendSms',objectToSend);

        this.setState({
            smsTestNumber : ''
        });

    }
    
    lastStep = (action) => {
        
        if(action === 'send') {
            console.log('sendLast');
            let objectToSend = {
                insertSMSid : this.state.insertSMSid
            }

            this.sendToAdmin('sendMultipleSms',objectToSend);

            this.setState({
                sendSmsLastBtnDisabled : true
            });

        }
        
        else if (action === "back") {
            this.setState({
                sentForm : false,
                inputFile: '',
                uploadError: '',
                name : '',   //File Upload
                uploadProgress : '', //File Upload
                selectedFile: '',  //File Upload
                smsText : '',
                insertSMSid : false,
                smsTestNumber : '',
                sendAdminResponse : false
                },
                function(){
                    //console.log(this.state.smsTestNumber)
            });
        }


    }
    

    render() {

        let count = this.props.rowsSelected.length;

        return (
            <div className="sendSMSModalBody">

                {this.state.sendAdminResponse.smsDone ? <div>
                    <h2  className='animate__animated animate__bounce' style={{color: 'green'}} >ה-SMS נשלחו בהצלחה</h2>
                </div> :

                <div>
                    {this.state.sendAdminResponse ? console.log(this.state.sendAdminResponse) : ''}

                    {this.state.insertSMSid? <div>

                        {this.state.sendAdminResponse.sms ? <div className="sendTruesms">
                            <div className="row">
                                <Button variant='danger' disabled={!this.state.sendSmsLastBtnDisabled ? false : true} size="lg" onClick={()=>this.lastStep('send')} >לשליחת ה-SMS לכולם</Button>
                            </div>
                            
                            <br/><br/>

                            <div className="row">
                                <Button variant='secondary' size="lg" onClick={()=>this.lastStep('back')} >לחזרה</Button>
                            </div>

                        </div> : <div className="smsTest">
                            <p>נא להכניס מספר נייד לבדיקת SMS: </p>
                            <InputGroup className="">
                                <FormControl
                                    placeholder = "מספר נייד לבדיקת SMS"
                                    onChange={this.handleChangeSMS} 
                                    value={this.state.smsTestNumber}
                                    name='smsTestNumber'
                                    />
                            </InputGroup>

                            <button className={this.state.smsTestNumber ? 'btn btn-success btnNextStep' : 'btn btn-secondary btnNextStep' }  disabled={this.state.smsTestNumber ? false : true } onClick={this.sendSmsTest} >שלח לי בדיקה לנייד</button>

                        </div> }

                    </div> : 
                    
                    <div>

                        { this.state.uploadProgress ? <ProgressBar now={this.state.uploadProgress} /> : false }

                        <Alert variant="danger" >
                            <p className="alertP">
                                <span>שים לב, אתה הולך לשלוח SMS ל-</span>
                                <span>{count} אנשים</span>
                            </p>
                        </Alert>
                        <div className="textSms">
                            <InputGroup className="">
                                <FormControl
                                    placeholder = "תוכן ה-SMS"
                                    as="textarea" rows={5}
                                    className='inputPrepend'
                                    onChange={this.handleChange} 
                                    value={this.state.smsText}
                                    name='txtSms'
                                    maxLength="170"
                                    />
                            </InputGroup>
                            <p style={{margin: '5px 0 0 0',fontSize:'14px',textAlign : 'left'}}>{this.state.smsText.length} / 170 תווים</p>
                        </div>

                        <section className="FilesWrapper animate__animated animate__fadeIn">
                                    
                            <div className="formFileSection">
                            {/* !this.state.sentForm */}
                            { !this.state.sentForm || this.state.uploadError ? <div>
                                        
                                    <input
                                        style={{display: 'none'}}
                                        type="file"
                                        name='inputFile' //For Validation
                                        onChange={this.fileSelectedHandler}
                                        ref={fileInput => this.fileInput = fileInput} //Para que no se Vea el Input y usar un boton.
                                        />

                                    <button className='btn btn-primary filePickUpBtn' onClick={() => this.fileInput.click()} >העלאת קובץ</button>
                                    <p className="fileTypesText">קבצים מסוג: JPG/PDF/PNG/TIF עד 5 מגה.</p>
                                    
                                    
                                </div> : false }

                                <p className="fileName">{ this.state.inputFile ? this.state.inputFile : false }</p>

                                {this.state.uploadError ? <p className="errorUpload"> {this.state.uploadError} </p>: ''}
                                
                                

                                <button className={this.state.smsText ? 'btn btn-success btnNextStep' : 'btn btn-secondary btnNextStep' }  disabled={this.state.smsText ? false : true } onClick={this.fileUploadHandler} >לשלב הבא &raquo;</button>

                            </div>
                                    


                                    
                                

                        </section>
                    </div> }
                </div> }
            </div>
        )
    }
}
