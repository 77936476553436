import React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "react-bootstrap";
import ReturnMoneyCreditCardValues from "./ReturnMoneyCreditCardValues";
import { useState } from "react";
import { map, isEmpty } from "lodash";
import ReturnMoneyCashValues from "./ReturnMoneyCashValues";
import { toast } from "react-toastify";

import convertDate from "./../../../convertDateToDisplay";

import { ConstantsNames, RestUrls } from "./../../../Urls";

export default function BodyModalReturnMoney(props) {
  const { item, showHideModal } = props;

  console.log("propsss", props);

  const [returnType, setReturnType] = useState(false);

  const returnMoneyTranzila = () => {
    toast.success("החשבונית החזר נשלחה בהצלחה");
    showHideModal();
  };

  const href =
    RestUrls.baseApiUrl1 + "assets/files/invoices/" + item?.pdfTranzilaFile;

  const openInvoice = async (invoiceId) => {
    try {
      const response = await fetch(
        `${ConstantsNames.base_url}Pays/downloadInvoice/${invoiceId}`
      );
      if (response) {
        const resp = await response.blob();
        const url = window.URL.createObjectURL(resp);
        window.open(url, "_blank"); // Open in new tab/window
        window.URL.revokeObjectURL(url); // Optional: Cleanup object URL
      }
    } catch (e) {
      console.log("ERROR in opening invoice", e);
    }
  };

  return (
    <div className="bodyModalReturnMoneyprops">
      <div className="heshData">
        <h3>פרטי החשבונית</h3>
        <ul>
          <li>
            <span>{convertDate(item.invoiceDate)} - </span>
            <span>{item.invoiceType} - </span>
            <span>₪{(parseFloat(item.SumValue) * 1.17).toFixed(2)}</span>
            {item?.creditCardCCNO && (
              <>
                <span> 4 ספורת אחרונות - </span>
                <span>{item.creditCardCCNO}</span>
              </>
            )}

            {!!item?.id && (
              <span
                onClick={() => {
                  openInvoice(item?.id);
                }}
                style={{ fontSize: "16px", color: "blue" }}
              >
                לצפיית החשבונית &raquo;
              </span>
            )}
          </li>
        </ul>
      </div>

      {!isEmpty(item?.moneyReturns?.data) && (
        <div className="moneyReturnsData">
          <h3>חשבוניות זיכוי קיימות</h3>

          <ul>
            {map(item?.moneyReturns?.data, (pdfFile) => {
              return <ShowMyHesh key={pdfFile?.id} item={pdfFile} />;
            })}
          </ul>
        </div>
      )}

      <h3>יצירת חשבונית זיכוי חדשה</h3>

      <Grid
        style={{ margin: "5px 0" }}
        className="lineForm"
        container
        spacing={2}
        direction="row"
        justifyContent="center"
      >
        {/* { (!item?.moneyReturns?.existBackCredit && item.are_card_pays) &&  */}

        <Grid item md={4} onClick={() => setReturnType("card")}>
          <Button variant="secondary" style={{ width: "100%" }}>
            זיכוי כרטיס אשראי
          </Button>
        </Grid>

        <Grid item md={4} onClick={() => setReturnType("cash")}>
          <Button variant="secondary" style={{ width: "100%" }}>
            זיכוי ידני
          </Button>
        </Grid>
      </Grid>

      {returnType === "card" && (
        <div className="ReturnMoneyCreditCard">
          <ReturnMoneyCreditCardValues
            item={item}
            returnMoneyTranzila={returnMoneyTranzila}
          />
        </div>
      )}
      <pre>{JSON.stringify(returnMoneyTranzila, null, 2)}</pre>
      {returnType === "cash" && (
        <div className="ReturnMoneyCreditCard">
          <ReturnMoneyCashValues
            item={item}
            returnMoneyTranzila={returnMoneyTranzila}
          />
        </div>
      )}
    </div>
  );
}

function ShowMyHesh(props) {
  const { item } = props;

  const href =
    RestUrls.baseApiUrl1 + "assets/files/invoices/" + item?.pdfTranzilaFile;

  //console.log('item', item);

  let returnType = "מזומן";

  if (item?.returnType === "card") {
    returnType = "כרטיס אשראי";
  } else if (item?.returnType === "bank") {
    returnType = "העברה בנקאית";
  }
  const openInvoice = async (invoiceId) => {
    try {
      const response = await fetch(
        `${ConstantsNames.base_url}Pays/downloadInvoice/${invoiceId}`
      );
      if (response) {
        const resp = await response.blob();
        const url = window.URL.createObjectURL(resp);
        window.open(url, "_blank"); // Open in new tab/window
        window.URL.revokeObjectURL(url); // Optional: Cleanup object URL
      }
    } catch (e) {
      console.log("ERROR in opening invoice", e);
    }
  };
  const openRefundInvoice = async (refundId) => {
    try {
      const response = await fetch(
        `${ConstantsNames.base_url}Pays/downloadRefundInvoice/${refundId}`
      );
      if (response) {
        const resp = await response.blob();
        const url = window.URL.createObjectURL(resp);
        window.open(url, "_blank"); // Open in new tab/window
        window.URL.revokeObjectURL(url); // Optional: Cleanup object URL
      }
    } catch (e) {
      console.log("ERROR in opening invoice", e);
    }
  };

  return (
    <li>
      <span>{convertDate(item.created_at)} - </span>
      <span>חשבונית זיכוי </span>
      {item?.responseTranzilaMoneyBack ? (
        <strong>כ"א</strong>
      ) : (
        <strong>{returnType}</strong>
      )}
      <span> - ₪{item?.cardCash}</span>

      <span
        onClick={() => {
          openRefundInvoice(item?.id);
        }}
        style={{
          fontSize: "16px",
          color: "blue",
          marginRight: "8px",
          cursor: "pointer",
        }}
        rel="noreferrer"
      >
        לצפיית החשבונית &raquo;
      </span>
    </li>
  );
}
