import React, { Component } from 'react'
import NewReport from './NewReport'
import ReportHistory from './ReportHistory'

//import { InputGroup, FormControl } from 'react-bootstrap';


export default class ReportIt extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            reloadHistory : false
        }
    }
    
    

    render() {
        return (
            <div>
                
                <NewReport  {...this.props} reloadHistory={()=>this.setState({ reloadHistory : true })} />

                {this.props.loadHistory ? <ReportHistory platform={this.props.platform} reloadHistory = {this.state.reloadHistory} changed={ ()=>this.setState({reloadHistory : false })} clientId={this.props.clientId} /> : false }
            </div>
        )
    }
}
