import React, { Component } from 'react'
import Loading from '../../../-HELPERS-/Loading';
//import { InputGroup, FormControl, Button } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import ShowLinksInvoices from "./ShowLinksInvoices";

export default class InvoiceFinalSend extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            mailadresses : '',
            invoiceData : '',
        }
    }

    // INPUT
    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        //console.log('IN');

        //let objectToSend = {hello : '1'}

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData }));
    }

    componentDidMount() {

        if (this.props.invoiceId) {

            this.sendtoAdmin('getInvoiceId','pays', { invoiceId : this.props.invoiceId },'invoiceData');

        }

    }



    render() {

        return (
            <div className="">

                {this.state.invoiceData ? <ul className="navButtons endPays">

                    <ShowLinksInvoices item={this.state.invoiceData.invoice} editInvoiceId={false} />

                    </ul> : <Loading open={true} noBackdrop = {true} /> }
            </div> 
        )
    }
}
