import React, { Fragment, useEffect, useState } from 'react'
import { InputGroup, FormControl, Button } from "react-bootstrap";

import { sendtoAdmin } from '../../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../../img/preLoader.gif"

//import { map,find } from "lodash";
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import InputMet from '../../Finance/InputMet';
import convertDateToDb from '../../../../convertDateToDb';
import { map } from "lodash";

export default function NewInvoice(props) {

    const { item, setWorkerTransactions, linkedClient } = props;

    //console.log('linkedClient', linkedClient);

    const [ money, setMoney ]= useState(parseFloat(item?.money));

    const today = new Date();
    const now = new Date(today.toString().replace(/-/g, '/'));


    const [ dateInvoicePay, setDateInvoicePay ]= useState(convertDateToDb(now));
    const [ currency_code, setCurrency_code ]= useState('ILS');

    const [moneyPayShekel, setMoneyPayShekel] = useState(0);

    const [exchangeRate, setExchangeRate] = useState('');

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(false);

    //console.log('item',item);

    const addInvoice = () => { 

        if(!money) {

          toast.error('יש להזין סכום תשלום');
          return;      

        }

        if(currency_code !== 'ILS' && !exchangeRate) {

          toast.error('יש להזין ערך המרה');
          return;

        }

        /* if(currency_code !== 'ILS' && countDecimalDigits(exchangeRate) > 3 ) {

          toast.error('ערך המרה חייב להיות עד 2 ספרות עשרוניות');
          return;

        } */

       //console.log('exchangeRate >',countDecimalDigits(exchangeRate));


        const sendData = {

            transactionId :  item.id,
            money : moneyPayShekel,
            exchangeRate : parseFloat(exchangeRate).toFixed(2),
            dateInvoicePay : dateInvoicePay,
            currency_code : currency_code,
            moneyPay : money,
            linkedSeniorId : linkedClient?.id ? linkedClient?.id : '',
            remarks : clientInfo


        }

        //toast.success('ok');
        //console.log('sendData', sendData);
        sendtoAdmin('insertInvoiceWorker', 'financeWorkers', sendData, setResponse, setLoading);

    }

    useEffect(() => {

        if(response?.error) {
  
          toast.error(response?.error);
  
        } else if (response?.ok) {
  
          toast.success(response?.ok);
          setMoney('');

          //setTransactionId('');
          //setMoney(false);
  
          if(response?.workerTransactions) {
              setWorkerTransactions(response?.workerTransactions);
          }
  
        }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [response])

      //console.log('response', response);

      const currencyValues = [

        {
          id : 'ILS',
          name : 'שקל'
        },
        {
          id : 'USD',
          name : 'דולר'
        },
        {
          id : 'EUR',
          name : 'אירו'
        },
        
      ]

      useEffect(() => {

        const moneyPayCalc = exchangeCalc(exchangeRate,money);

        if( currency_code !== 'ILS' && ( parseFloat(moneyPayCalc) !== 'moneyPay' ) ) {

          setMoneyPayShekel(moneyPayCalc);

        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [exchangeRate,money])


      useEffect(() => {


        if(currency_code === 'ILS') {return;}

        if(currency_code === 'USD') {

          setExchangeRate(3.8);

        } else if(currency_code === 'EUR') {

          setExchangeRate(4.09);

        }


      }, [currency_code])


      let clientInfo = 'לא נמצא לקוח/ת משובץ';

      if( linkedClient?.id ) {

        clientInfo = linkedClient?.name + ' ' + linkedClient?.surname;
        clientInfo += ' | ת.ז: ' +  linkedClient?.tz;

      }
        
      
      

      

      

      //console.log('dateInvoicePay', dateInvoicePay);
      //console.log('currency_code', currency_code);

  return (
    <div className='newInvoice'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        <Grid className='lineForm' container spacing={2}  direction="row"  > {/* justifyContent='center' */}
        
            <Grid item md={4} >

              <InputGroup className="customInput">
                
                  <InputGroup.Prepend className="inputPrependLabel">
                    <InputGroup.Text id="basic-addon3">סכום שהתקבל</InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    className="inputPrepend"
                    onChange={ (e)=> setMoney(e.target.value) }
                    //name={}
                    value={ money }
                  />

              </InputGroup>

            </Grid>

            <Grid item md={3} >

              <div className='selectInput'>

                  <InputGroup className="customInput">
                      <InputGroup.Prepend className="inputPrependLabel ">
                      <InputGroup.Text>מטבע</InputGroup.Text>
                      </InputGroup.Prepend>

                      <FormControl
                      as="select"
                      /* 'inputPrepend customSelect' */
                      className="inputPrepend customSelect"
                      onChange={ (e) =>  setCurrency_code(e.target.value) }
                      value={ currency_code }
                      //name=""
                      >
                      <option value="">בחירת מטבע...</option>

                      {   map(currencyValues, item => {
                              
                              if( true ) {

                                return <option value={ item?.id } key={item.id} > { item.name } </option>

                              }
                          
                            })
                      }
                      
                      </FormControl>

                  </InputGroup>
              </div>

            </Grid>

            { currency_code !== 'ILS' && <Fragment>

              <Grid item md={3} >

                <InputGroup className="customInput">
                  
                    <InputGroup.Prepend className="inputPrependLabel">
                      <InputGroup.Text id="basic-addon3">ערך המרה</InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      className="inputPrepend"
                      onChange={ (e)=> setExchangeRate(e.target.value) }
                      type='number'
                      placeholder='לדוגמה: 3.8'
                      //name={}
                      value={ exchangeRate }
                    />

                </InputGroup>

              </Grid>

              <Grid item md={4} >

                <InputGroup className="customInput">
                  
                    <InputGroup.Prepend className="inputPrependLabel">
                      <InputGroup.Text id="basic-addon3">סכום בשקלים</InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl

                      className="inputPrepend"
                      type='number'
                      disabled={true}
                      //name={}
                      //defaultValue={moneyPay}
                      onChange={()=> console.log('change calc...')}
                      value={ moneyPayShekel }

                    />

                </InputGroup>

              </Grid>

            </Fragment> }

            <Grid item md={4} >

              <InputMet text='תאריך חשבונית + תשלום' as={'dateInput'} value={ dateInvoicePay } placeholder='' name='dateInvoicePay' submit={ (date) => setDateInvoicePay(date?.dateInvoicePay) } />
              
            </Grid>

            <Grid item md={12} >
                <p>
                  <span>לקוח: </span>
                  <span>{ clientInfo }</span>
                </p>
            </Grid>

            

            <Grid item md={2} >
                <Button onClick = { addInvoice } disabled={ parseFloat(money) <= 0 ? true : false } variant="success" style={{ width: '100%' }} >הוספת חשבונית</Button>
            </Grid>



        </Grid>

    </div>
  )


}

function exchangeCalc(exchangeRate,money) {

  //console.log('exchangeRate', exchangeRate);
  //console.log('money', money);

  let calc = money;

  if( parseFloat(exchangeRate) > 0 ) {

    calc =  parseFloat(money * exchangeRate).toFixed(2);

  }

  return  parseFloat(calc);


}

/* function countDecimalDigits(number)
{
  var char_array = number.toString().split(""); // split every single char
  var not_decimal = char_array.lastIndexOf(".");
  return (not_decimal<0)?0:char_array.length - not_decimal;
} */