import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../DinamicForm/newApi/getDataFromApi'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
registerLocale('he', he)

function convertDate (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}


export default function ModalChangeHistory(props) {

    const { setShowModal, inputData, inputValueDB, setInputValueS, dataParam } = props;
    
    const [inputValue, setInputValue] = useState(inputValueDB);
    const [isLoading, setIsLoading] = useState(false);

    //console.log('inputData', inputData);
    //console.log('inputValue', inputValue);

    const title = getTitle(inputData.explain);

    //For datePicker
    const handleChangeDate = (date) => {

        let newDate = convertDate(date);
        setInputValue(newDate);
        
    }

    let today = new Date();
    let current_datetime = inputValue === ''  ? new Date(today.toString().replace(/-/g, '/')) : new Date(inputValue.toString().replace(/-/g, '/'));  
    
    const sendData = () => {
      
        if (!isLoading) {

            //all DATA
            //console.log('dataParam', dataParam);

            const send = {

                rowId: dataParam.id ? dataParam.id : false,
                inputValue: inputValue,
                oldValue: '',
                nameInput : inputData.name,

            }

            console.log('send', send);
        
            sendtoAdmin('updateHistoryFields', 'first', send, setIsLoading, inputValue, setInputValueS, setShowModal)
          }
        

    };
    
    // Daniel use this Modal
    
  return <Modal
        className='newTaskModal ModalChangeHistory animate__animated animate__fadeInDown animate__faster '
        animation={false}
        onHide={()=>setShowModal(false)}

        /* size="sm" // בגודל קטן!!! */
        /* size="lg" // גדול!! */
        // backdrop="static" // חייב ללחוץ על כפתור!
        /* centered // VERTICA CENTER */
        show={true}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
    >
        <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
                <span>החלפת {title}</span>
            </Modal.Title>
            <Button onClick={() => setShowModal(false)} variant="secondary" >X</Button>
        </Modal.Header>
        
        <Modal.Body>

            <div className='inputsCont'>

                {inputData.type === 'date' && <>

                <DatePicker 
                    showYearDropdown = {true}
                    selected={current_datetime}
                    dateFormat='dd/MM/yy'
                    className='inputPrepend form-control'
                    name={inputData.name}
                    locale="he"
                    onChange={(date)=>handleChangeDate(date)}
                />
                
                </>}

            </div>

            <div className='btnCont'>
                <Button disabled={isLoading} onClick={sendData} size="" variant="success" >
                    {isLoading ? 'טוען…' : 'שליחה'}
                </Button>
            </div>

            
        </Modal.Body>

    </Modal>;
}

function getTitle(explain) {

    switch (explain) {

        case "ויזה בתוקף עד":
            return 'תוקף ויזה'

        case "עד תאריך":
            return 'תוקף'

        case "בתוקף":
            return 'תוקף מספר דרכון'
    
        default:
            return explain
    }

}


function sendtoAdmin(url, controller, objectToSend, setLoading, inputValue, setInputValueS, setShowModal) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {
      if (getData.ok) {

        //setRequest(getData.ok)
        setInputValueS(convertDateShow(inputValue));
        toast.success(getData.ok);
        setLoading(false)
        setShowModal(false);

      } else if (getData.error) {
        setLoading(false)
        toast.error(getData.error)

      }

    })

    getData.catch((error) => {
      setLoading(false)
      console.log(error)
    })

    /* getData.finally(() => {
      setLoading(false)
    }) */
}

function convertDateShow (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    /* 2021-03-25 */

    let formatted_date = 
                        current_datetime.getDate() + '/' +
                        (current_datetime.getMonth() + 1) + "/" +
                        current_datetime.getFullYear();
                        
    return formatted_date;
}