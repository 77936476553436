import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { InputGroup, FormControl, Button } from "react-bootstrap";

import { map, find, isEmpty } from "lodash"; //https://lodash.com/docs/4.17.15#map  //find

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";

import moment from "moment"; //npm install moment --legacy-peer-deps

registerLocale("he", he);

export default function FilterBar(props) {
  const { visitsData, setVisitsData } = props;
  const [clientNameFilter, setClientNameFilter] = useState("");

  const [allVisitsData, setAllVisitsData] = useState([]);

  const [showFromDate, setShowFromDate] = useState(false);
  const [showFromDateNext, setShowFromDateNext] = useState(false);

  useEffect(() => {
    if (isEmpty(allVisitsData)) {
      setAllVisitsData(visitsData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitsData]);

  //console.log('visitsData', visitsData);
  //console.log('allVisitsData', allVisitsData);

  const inputsBuilder = [
    {
      name: "visitType",
      label: "סוג ביקור",
      defaultValue: "all",
    },
    {
      name: "fromDate",
      label: "מ-תאריך ביקור אחרון",
      defaultValue: new Date(),
    },
    {
      name: "fromNextDate",
      label: "מ-תאריך ביקור מתוכנן",
      defaultValue: new Date(),
    },
    {
      name: "countryArea",
      label: "אזור מגורים",
      defaultValue: "all",
    },
    {
      name: "companyClient",
      label: "סניף סיעוד",
      defaultValue: "all",
    },
  ];

  const [formData, setFormData] = useState(defaultValueForm(inputsBuilder));

  const handleFilter = (e) => {
    //console.log('handleFilter...',e.target.name);
    if (e.target.name !== "clientName") {
      setClientNameFilter(""); // reset client name filter
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    const newFilter = makFilters(
      e,
      allVisitsData,
      formData,
      showFromDate,
      showFromDateNext,
      clientNameFilter // add this parameter
    );

    setVisitsData(newFilter);
  };

  useEffect(() => {
    if (showFromDate) {
      handleFilter({
        target: {
          name: "fromDate",
          value: formData[inputsBuilder[1].name],
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFromDate]);

  useEffect(() => {
    if (showFromDateNext) {
      handleFilter({
        target: {
          name: "fromNextDate",
          value: formData[inputsBuilder[2].name],
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFromDate]);

  //let current_datetime = formData[inputsBuilder[0].name] ? formData[inputsBuilder[0].name] : new Date(); // IPHONE FIX

  const resetMe = () => {
    //console.log('reset...');

    setShowFromDate(false);
    setShowFromDateNext(false);
    setFormData(defaultValueForm(inputsBuilder));
    setVisitsData(allVisitsData);
    setClientNameFilter(""); // reset client name filter
  };

  const countryAreas = [
    {
      id: "1",
      name: "תל אביב",
    },
    {
      id: "2",
      name: "מרכז",
    },
    {
      id: "3",
      name: "פריפריה",
    },
  ];

  const companyClient = getCompaniesClient(allVisitsData);

  //console.log('companyClient', companyClient);

  return (
    <div className="filterBar">
      <Grid
        className="lineForm"
        container
        spacing={2}
        direction="row"
        justifyContent="center"
      >
        {" "}
        {/*  */}
        {showFromDate ? (
          <Grid item md={4}>
            <InputGroup className={"customInput dateInput "}>
              <div className="dateTimeCont">
                <div className="customPrepend">{inputsBuilder[1].label}</div>

                <DatePicker
                  showYearDropdown={true}
                  selected={formData[inputsBuilder[1].name]}
                  disabled={false}
                  dateFormat="dd/MM/yy"
                  className="inputPrepend form-control"
                  name={inputsBuilder[1].name}
                  locale="he"
                  onChange={(date) =>
                    handleFilter({
                      target: {
                        name: "fromDate",
                        value: date,
                      },
                    })
                  }
                />
              </div>
            </InputGroup>
          </Grid>
        ) : (
          <Grid item md={2}>
            <Button
              style={{ width: "100%" }}
              onClick={() => setShowFromDate(true)}
              size=""
              variant="secondary"
            >
              תאריך אחרון
            </Button>
          </Grid>
        )}
        {showFromDateNext ? (
          <Grid item md={4}>
            <InputGroup className={"customInput dateInput "}>
              <div className="dateTimeCont">
                <div className="customPrepend">{inputsBuilder[2].label}</div>

                <DatePicker
                  showYearDropdown={true}
                  selected={formData[inputsBuilder[2].name]}
                  disabled={false}
                  dateFormat="dd/MM/yy"
                  className="inputPrepend form-control"
                  name={inputsBuilder[2].name}
                  locale="he"
                  onChange={(date) =>
                    handleFilter({
                      target: {
                        name: "fromNextDate",
                        value: date,
                      },
                    })
                  }
                />
              </div>
            </InputGroup>
          </Grid>
        ) : (
          <Grid item md={2}>
            <Button
              style={{ width: "100%" }}
              onClick={() => setShowFromDateNext(true)}
              size=""
              variant="secondary"
            >
              תאריך מתוכנן
            </Button>
          </Grid>
        )}
        <Grid item md={4}>
          <div className="selectInput">
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel ">
                <InputGroup.Text>{inputsBuilder[0].label}</InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="inputPrepend customSelect"
                onChange={handleFilter}
                value={formData[inputsBuilder[0].name]}
                name={inputsBuilder[0].name}
              >
                <option value="all">כול הביקורים</option>
                <option value="ביקור חצי שנתי">ביקור חצי שנתי</option>
                <option value="ביקור לפני השמה">ביקור לפני השמה</option>
                <option value="ביקור אחרי השמה (30 יום אחרי)">
                  ביקור אחרי השמה (30 יום אחרי)
                </option>
              </FormControl>
            </InputGroup>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="selectInput">
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel ">
                <InputGroup.Text>{inputsBuilder[3].label}</InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="inputPrepend customSelect"
                onChange={handleFilter}
                value={formData[inputsBuilder[3].name]}
                name={inputsBuilder[3].name}
              >
                <option value="all">כול האזורים</option>

                {map(countryAreas, (item) => {
                  return (
                    <option value={item?.id} key={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </FormControl>
            </InputGroup>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="selectInput">
            <InputGroup className="customInput">
              <InputGroup.Prepend className="inputPrependLabel ">
                <InputGroup.Text>{inputsBuilder[4].label}</InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="inputPrepend customSelect"
                onChange={handleFilter}
                value={formData[inputsBuilder[4].name]}
                name={inputsBuilder[4].name}
              >
                <option value="all">כול הסניפים</option>

                {map(companyClient, (item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </FormControl>
            </InputGroup>
          </div>
        </Grid>
        <Grid item md={1}>
          <Button onClick={resetMe} size="" variant="secondary">
            איפוס
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

function defaultValueForm(inputsBuilder) {
  let objReturn = {};

  //console.log(savedData);

  map(inputsBuilder, (item) => {
    objReturn[item.name] = item.defaultValue;
  });

  return objReturn;
}

function makFilters(
  e,
  allVisitsData,
  formData,
  showFromDate,
  showFromDateNext,
  clientNameFilter // add this parameter
) {
  let filtered = [];

  map(allVisitsData, (item) => {
    //console.log('e.target.value', e.target.value);

    const change = {
      value: e.target.value,
      name: e.target.name,
    };

    const visitTypeFlag = getVisitTypeFlag(formData, change, item);
    const countryAreaFlag = getVisitCountryAreaFlag(formData, change, item);
    const companyClientFlag = getVisitCompanyClientFlag(formData, change, item);
    const clientNameFlag = clientNameFilter
      ? item.clientData.name.includes(clientNameFilter)
      : true; // check if client name matches filter
    let visitFromDateFlag;
    let visitNextDateFlag;

    if (!showFromDate) {
      visitFromDateFlag = true;
    } else {
      visitFromDateFlag = getVisitFromDateFlag(formData, change, item);
    }

    if (!showFromDateNext) {
      visitNextDateFlag = true;
    } else {
      visitNextDateFlag = getNextVisitFromDateFlag(formData, change, item);
    }

    //console.log('visitTypeFlagvalues', visitTypeFlag, visitFromDateFlag);
    if (
      visitTypeFlag &&
      visitFromDateFlag &&
      visitNextDateFlag &&
      countryAreaFlag &&
      companyClientFlag &&
      clientNameFlag // include this in the condition
    ) {
      filtered.push(item);
    }
  });

  return filtered;
  //console.log('filtered', filtered);
}

function getVisitTypeFlag(formData, change, item) {
  //console.log('formData', formData);

  const value = getMyValue(formData, change, "visitType");

  if (value === "all") {
    return true;
  }

  if (item?.visitType === value) {
    return true;
  } else if (value === "ביקור לפני השמה" && !item?.visitType) {
    return true;
  } else {
    return false;
  }
}

function getVisitCountryAreaFlag(formData, change, item) {
  //console.log('formData', formData);

  const value = getMyValue(formData, change, "countryArea");

  //console.log('value', value);

  if (value === "all") {
    return true;
  }

  if (item?.clientData?.countryArea === value) {
    return true;
  }

  return false;
}

function getVisitCompanyClientFlag(formData, change, item) {
  //console.log('formData', formData);

  const value = getMyValue(formData, change, "companyClient");

  //console.log('value', value);

  if (value === "all") {
    return true;
  }

  if (item?.clientData?.companyClient === value) {
    return true;
  }

  return false;
}

function getVisitFromDateFlag(formData, change, item) {
  //console.log('formData', formData);

  if (!item?.fromDateVisit) {
    return true;
  }

  const value = getMyValue(formData, change, "fromDate");

  const itemDateValue = moment(item?.fromDateVisit, "DD/MM/YYYY");
  const filterValue = moment(convertDate2(value));

  //console.log('itemDateValue', itemDateValue);
  //console.log('filterValue', filterValue);

  if (itemDateValue >= filterValue) {
    return true;
  }

  return false;
}

function getNextVisitFromDateFlag(formData, change, item) {
  //console.log('formData', formData);

  if (!item?.nextVisit) {
    return true;
  }

  const value = getMyValue(formData, change, "fromNextDate");

  const itemDateValue = moment(item?.nextVisit, "DD/MM/YYYY");
  const filterValue = moment(convertDate2(value));

  console.log("itemDateValue", itemDateValue);
  console.log("filterValue", filterValue);

  if (itemDateValue >= filterValue) {
    return true;
  }

  return false;
}

function getMyValue(formData, change, action) {
  if (change.name === action) {
    return change.value;
  } else {
    return formData[action];
  }
}

function convertDate2(dateValue) {
  let current_datetime = new Date(dateValue.toString().replace(/-/g, "/")); // IPHONE FIX

  /* 2021-03-25 */

  let formatted_date =
    current_datetime.getFullYear() +
    "-" +
    (current_datetime.getMonth() + 1) +
    "-" +
    current_datetime.getDate();

  return formatted_date;
}

function getCompaniesClient(allVisitsData) {
  let allCompanies = [];

  map(allVisitsData, (item) => {
    const value = item?.clientData?.companyClient;

    if (
      !find(allCompanies, function (o) {
        return o === value;
      })
    ) {
      allCompanies.push(value);
    }
  });

  return allCompanies;
}
