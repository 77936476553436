import React, { Fragment, useEffect, useState } from 'react'
import {  sendtoAdmin } from '../../../DinamicForm/newApi/getDataFromApi';

import AllFiles from '../AllFiles/AllFiles'
import loader from "../../../../img/preLoader.gif"

export default function OurFilesPanel(props) {

    const { clientId, user, workerId, mail } = props

    const [responseData, setResponseData ] = useState(false);
    const [loading, setLoading ] = useState(false);



    useEffect(() => {

        const send = {
           
        };

        sendtoAdmin('filesSection', 'First', send, setResponseData, setLoading);
        //console.log('deleteRow: ', item.id );

    }, [])
    
    //console.log(responseData);

    //this.props.infoSite.user.data

    const infoSite = {

        user : {
            data : user
        }

    }


  return (
    <Fragment>
        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />
        
        { responseData?.files_categories && < AllFiles mail= { mail} pageData = { responseData } clientId = { clientId } workerId = { workerId } show={true} infoSite = {infoSite} /> }
    </Fragment>
  )
}
