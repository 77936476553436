import React, { useState, useEffect } from "react";

import { sendtoAdmin } from "../../../DinamicForm/newApi/getDataFromApi";
import loader from "../../../../img/preLoader.gif";

import ReportHome1 from "./ReportHome1";
import ReportHome1SaveData from "./ReportHome1SaveData";

export default function HomeVisitsEditRow(props) {
  const { editRowId } = props;

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(false);

  const [visitDate, setVisitDate] = useState(null);

  useEffect(() => {
    const send = {
      editRowId: editRowId,
    };

    sendtoAdmin(
      "getDataEditRow",
      "HomeVisits",
      send,
      setResponseData,
      setLoading,
      "all",
      (d) => {
        setVisitDate(d.visitDate);
      }
    );
  }, [editRowId]);

  //console.log(editRowId);
  //console.log('responseData', responseData);

  return (
    <div>
      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />
      {responseData && responseData.statusData ? (
        <>
          <ReportHome1
            visitType={responseData.visitType}
            clientId={responseData.clientId}
            visitDate={visitDate}
            onChangeVisitDate={(d) => {
              setVisitDate(d);
            }}
            chosensocialWorker={responseData.chosensocialWorker}
            savedData={responseData.statusData} // FOR RETRIEVE DATA
            setNewVisitStep2Data={null} // ???
          />
          <div className="formCont saveData">
            <ReportHome1SaveData
              visitDate={visitDate}
              responseData={responseData}
              formSavedData={responseData.formSavedData}
              idFormData={editRowId}
            />
          </div>
        </>
      ) : (
        <h3>אין נתונים נוספים</h3>
      )}
    </div>
  );
}
