import React, { Component } from 'react'
import convertDate from './../../../convertDateToDisplay'
import { Button } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";



export default class PayItem extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            responseDelettePay : false
        }
    }
    

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData },function(){

            if( stateNameResponse === 'responseDelettePay') {

                this.props.reLoadData();

            }
            //console.log(this.state[setStateName]);

        }));
    }




    removePay = (idPay) => {
        
        console.log(idPay);
        this.sendtoAdmin('delettePayId','pays', { payId : idPay, clientId : this.props.clientId },'responseDelettePay');


    }

    
    

    render() {

        let item = this.props.item;

        if(item.typePay === "צ'קים") {

            let Jitem = JSON.parse(item.checkData);

            return (
                <li className="checks">
                    <Button className="showPayDelette" onClick={ () => this.removePay(item.id) } size="sm" variant='outline-danger' ><i className="far fa-trash-alt"></i></Button>
                    <strong>צ'ק: </strong>
                    <span>תאריך: {convertDate(item.checkDate)}</span> - 
                    <span>מספר צ'ק: {Jitem.checkNumber}</span> - 
                    <span>מספר בנק: {Jitem.checkBankName}</span> - 
                    <strong> סכום: {Jitem.checkSum} ₪</strong>
                </li>
            )

        }

        else {

            return (
                <li>
                    { item.typePay !== "כרטיס אשראי" ?
                        <Button className="showPayDelette"
                            onClick={ () => this.removePay(item.id) }
                            size="sm" variant='outline-danger' ><i className="far fa-trash-alt"></i></Button> : false }
                    
                    <strong>{item.typePay}: </strong> <span>{item.SumValue} ₪</span>
                </li>
            )
        }
        
    }
}
