import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core';
import InputMet from './InputMet';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { sendtoAdmin } from '../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../img/preLoader.gif"
import { toast } from 'react-toastify';

export default function FreeAsama(props) {

    const { goBack, dataClient } = props

    //console.log('dataClient', dataClient);

    let today = new Date();
    const today2 = new Date(today.toString().replace(/-/g, "/"));

    const [ fromDate, setFromDate ] = useState( today2 );
    const [ toDate, setToDate ] = useState( today2 );

    const [ formData, setFormData ] = useState(
        {
            fromDate : convertDate2(fromDate),
            toDate : convertDate2(toDate)
        }
    );

    

    const submitDate = (value) => { 

        

        if(value?.fromDate) {

            let today = new Date(value?.fromDate);
            const today2 = new Date(today.toString().replace(/-/g, "/"));

            setFormData({
                ...formData,
                fromDate: value?.fromDate,
            })

            setFromDate(today2)

        } else {

            let today = new Date(value?.toDate);
            const today2 = new Date(today.toString().replace(/-/g, "/"));

            setFormData({
                ...formData,
                toDate: value?.toDate,
            })

            setToDate(today2)


        }

        //console.log('value', value);

    }

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

    const submitForm = () => { 

        console.log('formData', formData);

        const send = {
            
            clientId: dataClient?.id,
            ...formData
            
        };

        sendtoAdmin('addFreeAsama', 'Pays', send, setResponseData, setLoading);


    }

    useEffect(() => {

        if(responseData?.ok) {

            toast.success(responseData?.ok);
            goBack();

        } else if(responseData?.error){

            toast.error(responseData?.error);

        }
      

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])
    

    console.log('responseData', responseData);

    

  return (
    <div className='freeAsama'>

        <h2 className="blueH2">חידוש מסגרת לפטורי עמלה</h2>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        <Grid className='line' container spacing={2}  direction="row" >

            <Grid item md={4} >
                <InputMet
                  text="מתאריך"
                  disabled={false}
                  as="dateInput"
                  value={ fromDate }
                  name="fromDate"
                  submit={ submitDate }
                />
            </Grid>

            <Grid item md={4} >
                <InputMet
                  text="לתאריך"
                  disabled={false}
                  as="dateInput"
                  value={ toDate }
                  name="toDate"
                  submit={ submitDate }
                />
            </Grid>

            <Grid item md={3} >
                <Button disabled={loading} className='sendBtn' onClick={submitForm} style={{width : '100%'}} variant="success" >שליחה</Button> {/* size="lg" */}
            </Grid>

        </Grid>

        <div className="buttonCont">
            <button className="btn btn-secondary" onClick={ () => goBack() }>
            סגירת חלון
            </button>
        </div>
    </div>
  )
}

function convertDate2 (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}