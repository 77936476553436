import React, { Fragment, useEffect, useState } from 'react'
import ShowKevaPays from './ShowKevaPays';
import { sendtoAdmin } from '../../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../../img/preLoader.gif"
import TurnOnOffCronjob from './TurnOnOffCronjob';

export default function KevaPaysIndex() {

    //console.log('hello');
    
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);

    useEffect(() => {

        getData();
    
    }, []);

    const getData = () => { 

        const send = {};
        sendtoAdmin('configGetKevaInfo', 'pays', send, setResponseData, setLoading);

     }

    //console.log('responseData', responseData);

  return (
    <div className='kevaPaysIndex'>

        <div className="reLoad">
            <button className='goBackCustomBtn' onClick ={ ()=> getData() } ><i className="fas fa-sync-alt"></i></button>
            <span>רענון</span>
        </div>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        { responseData?.data && <Fragment>

            < TurnOnOffCronjob data = { responseData?.data } reload = { getData } />
            
            < ShowKevaPays data = { responseData?.data } />

        </Fragment> }

        

    </div>
  )
}
