import React, { Component } from 'react'
import TdContacts from './TdContacts'
export default class Contacts extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            savedMessage : false
        }
    }
    
    
    render() {

        let allContacts = this.props.dataPage.contactsSeniors;
        let contactsDataForm =  this.props.dataPage.formContacts;

        return (
            <div className="SeniorContacts">

                {this.state.savedMessage ? <div className="savedWindow animate__animated animate__bounceIn animate__slow">השינוים נשמרו בהצלחה</div> : false }

                <div className="seniorsTableContact">

                    <div className="trTable titlesTr">
                        <div className="tdTable">שם מלא</div>
                        <div className="tdTable">קרבה</div>
                        <div className="tdTable">טלפון</div>
                        <div className="tdTable">דואר אלקטרוני</div>
                        <div className="tdTable">הערות</div>
                        <div className="tdTable">#</div>
                    </div>
                    <div className="trTable contentTds">
                        <div className="vSpace"></div>
                    </div>
                    {allContacts.map(itemContact => { if (true) {
                            return <React.Fragment key={itemContact.data.id}>
                                    <TdContacts savedMessage={(value)=>this.setState({savedMessage: value}) } platform={this.props.platform} user={this.props.user} submit={this.props.changeArrayContacts} itemData={itemContact.data} contactsDataForm={contactsDataForm} />
                                </React.Fragment>
                        } else return false}) }
                    
                </div>
            </div>
        )
    }
}
