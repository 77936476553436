import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import DataSummary from "./DataSummary/DataSummaryIndex";
import Tasks from "./Tasks/TasksIndex";

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from 'react-router-dom'; 

export default class HomePage extends Component {
   

    render() {

        
        //let PagesNames = this.props.PagesNames; //On Site Connector

        //console.log(PagesNames);
        //console.log(this.props);
        let pageData = this.props.pageData;
        //console.log(pageData.dataSummary);

        return (
            
            <Container>
                <div className="HomePage animate__animated">
                    
                    <section className="DataSummary"> 
                        {/* {...this.props} */}
                        <DataSummary pageData={pageData.page.method} dataSummary={pageData.dataSummary}  />
                    </section>
                    
                    <section className="Tasks animate__animated animate__fadeInRightDown ">
                        <Tasks media ={this.props.infoSite.media} user = {this.props.infoSite.user.data} />
                        
                        <NavLink 
                            className="toTasksBtn"
                            onClick={() => scroll.scrollTo(0)}
                            to={'משימות'} >
                                <span>לכל המשימות &raquo;</span>
                        </NavLink>
                        
                    </section>
                    
                </div>
            </Container>
        )
    }
}
