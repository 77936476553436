//import {convertDate, convertDateToDisplay} from '../../../../Components/utils/helpFunctions'

//const today = new Date();
//let current_datetime = new Date(today.toString().replace(/-/g, '/'));

//console.log(current_datetime);

export const inputsBuilder = [
  {
    name: "visitDate",
    label: "תאריך הביקור",
    defaultValue: "",
    required: true,
  },
  {
    name: "firmName",
    label: "שם הלשכה",
    defaultValue: "",
    required: true,
  },
  {
    name: "socialWorkerName",
    label: "שם עובד סוציאלי",
    defaultValue: "",
    required: true,
  },
  {
    name: "visitPeople",
    label: "נוכחות בביקור",
    defaultValue: "",
    required: true,
  },
  {
    name: "visitReason",
    label: "סיבת הפניה",
    defaultValue: "",
    required: true,
  },

  /* פרטים אישיים של המעסיק */
  {
    name: "clientSurname",
    label: "שם משפחה",
    defaultValue: "",
  },
  {
    name: "clientName",
    label: "שם פרטי",
    defaultValue: "",
  },
  {
    name: "clientTz",
    label: "תעודת זהות",
    defaultValue: "",
  },
  {
    name: "clientBirthday",
    label: "תאריך לידה",
    defaultValue: "",
  },
  {
    name: "clientSex",
    label: "מין",
    defaultValue: "",
    options: [
      {
        name: "זכר",
      },
      {
        name: "נקבה",
      },
    ],
  },
  {
    name: "clientMaritalStatus",
    label: "מצב משפחתי",
    defaultValue: "",
    options: [
      { name: "רווק/ה" },
      { name: "אלמן/ה" },
      { name: "פרוד/ה" },
      { name: "נשוי/אה" },
      { name: "גרוש/ה" },
      { name: "ידוע/ה בציבור" },
    ],
  },
  {
    name: "clientCountry",
    label: "ארץ מוצא",
    defaultValue: "",
  },
  {
    name: "clientAliya",
    label: "שנת עליה",
    defaultValue: "",
  },
  {
    name: "clientWeight",
    label: "משקל",
    defaultValue: "",
  },
  {
    name: "clientHeight",
    label: "גובה",
    defaultValue: "",
  },
  {
    name: "clientReligion",
    label: "זרם דתי",
    defaultValue: "",
    options: [
      { name: "חרדי" },
      { name: "דתי" },
      { name: "מסורתי" },
      { name: "חילוני" },
      { name: "אחר" },
    ],
  },
  {
    name: "clientReligionOther",
    label: "זרם דתי אחר",
    defaultValue: "",
  },

  {
    name: "clientLanguajes1",
    label: "שפות דיבור 1",
    defaultValue: "",
  },

  {
    name: "clientLanguajes2",
    label: "שפות דיבור 2",
    defaultValue: "",
  },

  {
    name: "clientLanguajes3",
    label: "שפות דיבור 3",
    defaultValue: "",
  },

  //כתובת המעסיק
  {
    name: "clientAddress",
    label: "רחוב",
    defaultValue: "",
  },
  {
    name: "clientHomeNumber",
    label: "מספר בית",
    defaultValue: "",
  },
  {
    name: "clientEnter",
    label: "כניסה",
    defaultValue: "",
  },
  {
    name: "clientDira",
    label: "דירה",
    defaultValue: "",
  },

  {
    name: "clientCity",
    label: "ישוב",
    defaultValue: "",
  },

  {
    name: "clientPostal",
    label: "מיקוד",
    defaultValue: "",
  },

  {
    name: "clientPhone2",
    label: "טלפון קווי",
    defaultValue: "",
  },

  {
    name: "clientPhone1",
    label: "טלפון נייד",
    defaultValue: "",
  },

  {
    name: "clientEmail",
    label: "מייל",
    defaultValue: "",
  },

  //הקשיש נמצא כעת ב:
  // keep this field as remarks
  {
    name: "seniorPlaceAddress",
    label: 'בכתובת הנ"ל',
    defaultValue: "",
  },
  {
    name: "seniorPlaceHospital",
    label: "בבית חולים: ",
    defaultValue: "",
  },
  {
    name: "seniorPlaceHospital1",
    label: "מחלקה: ",
    defaultValue: "",
  },
  {
    name: "seniorPlaceHospital2",
    label: "תאריך שחרור: ",
    defaultValue: "",
  },

  {
    name: "seniorPlaceOther1",
    label: "בכתובת אחרת: ",
    defaultValue: "",
  },

  {
    name: "seniorPlaceOther2",
    label: "כתובת",
    defaultValue: "",
  },

  //פרטים על בן/בת הזוג:

  {
    name: "clientPartnerSurname",
    label: "שם משפחה",
    defaultValue: "",
  },
  {
    name: "clientPartnerName",
    label: "שם פרטי",
    defaultValue: "",
  },
  {
    name: "clientPartnerTz",
    label: "מספר זהות",
    defaultValue: "",
  },
  {
    name: "clientPartnerData",
    label: "פרטים על בן/בת  הזוג",
    defaultValue: "",
  },

  //asdadsd
  //כתובת המעסיק
  {
    name: "guardianAddress",
    label: "רחוב",
    defaultValue: "",
  },
  {
    name: "guardianNumber",
    label: "מספר בית",
    defaultValue: "",
  },
  {
    name: "guardianEnter",
    label: "כניסה",
    defaultValue: "",
  },
  {
    name: "guardianDira",
    label: "דירה",
    defaultValue: "",
  },

  {
    name: "guardianCity",
    label: "ישוב",
    defaultValue: "",
  },

  {
    name: "guardianPostal",
    label: "מיקוד",
    defaultValue: "",
  },

  {
    name: "guardianPhone2",
    label: "טלפון קווי",
    defaultValue: "",
  },

  {
    name: "guardianPhone1",
    label: "טלפון נייד",
    defaultValue: "",
  },

  {
    name: "guardianEmail",
    label: "מייל",
    defaultValue: "",
  },

  {
    name: "guardianSurname",
    label: "שם משפחה",
    defaultValue: "",
  },

  {
    name: "guardianName",
    label: "שם פרטי",
    defaultValue: "",
  },

  {
    name: "guardianLink",
    label: "קרבה למטופל/ת",
    defaultValue: "",
    options: [
      { name: "הורה" },
      { name: "בן/בת" },
      { name: "אח/ות" },
      { name: "אפוטרופוס" },
      { name: "בן/בת סוג" },
      { name: "אחר" },
    ],
  },

  //אנשים הגרים עם המעסיק
  {
    name: "contactSurname1",
    label: "שם משפחה",
    defaultValue: "",
  },
  {
    name: "contacName1",
    label: "שם פרטי",
    defaultValue: "",
  },
  {
    name: "contactLink1",
    label: "קרבה למטופל/ת - עיסוק - גיל - הערות",
    defaultValue: "",
  },

  {
    name: "contactSurname2",
    label: "שם משפחה",
    defaultValue: "",
  },
  {
    name: "contacName2",
    label: "שם פרטי",
    defaultValue: "",
  },
  {
    name: "contactLink2",
    label: "קרבה למטופל/ת - עיסוק - גיל - הערות",
    defaultValue: "",
  },

  {
    name: "contactSurname3",
    label: "שם משפחה",
    defaultValue: "",
  },
  {
    name: "contacName3",
    label: "שם פרטי",
    defaultValue: "",
  },
  {
    name: "contactLink3",
    label: "קרבה למטופל/ת - עיסוק - גיל - הערות",
    defaultValue: "",
  },

  //תיאור מצב המטופל
  {
    name: "seniorHealth",
    label: "מצב בריאותי",
    defaultValue: "",
  },

  {
    name: "seniorHealth1",
    label: "מצב פסיכיאטרי",
    defaultValue: "",
    options: [
      { name: "אין" },
      { name: "יש" },
      { name: "לא ידוע" },
      { name: "לא אובחן" },
      //{'name' : 'פרט'},
    ],
  },

  {
    name: "seniorHealth1a",
    label: "פרט",
    defaultValue: "",
  },

  {
    name: "seniorHealth2",
    label: "מצב קוגניטיבי",
    defaultValue: "",
    options: [
      { name: "מתמצא בזמן במקום" },
      { name: "ירידה קלה בהתמצאות" },
      { name: "ירידה משמעותית בהתמצא" },
      { name: "הפרעה בדיבור ובתפקוד" },
      { name: "אין מידע" },
    ],
  },

  {
    name: "seniorHealth3",
    label: "מצב תפקודי",
    defaultValue: "",
    options: [
      { name: "כסא גלגלים" },
      { name: "הליכון" },
      { name: "מרותק למיטה" },
      { name: "מתהלך" },
      { name: "סיוע במנוף למעברים" },
      { name: "אחר" },
    ],
  },

  {
    name: "seniorHealth4",
    label: "אחר",
    defaultValue: "",
  },

  {
    name: "seniorHealth4a",
    label: "שליטה על סוגרים",
    defaultValue: "",
    options: [
      { name: "מלאה" },
      { name: "חלקית" },
      { name: "מוצרי ספיגה" },
      //{'name' : 'אמצעי עזר (סטומה,קטטר)'},
    ],
  },

  {
    name: "seniorHealth5",
    label: "ראיה",
    defaultValue: "",
    options: [
      { name: "תקינה" },
      { name: "חלשה" },
      { name: "עיוור" },
      { name: "אחר" },
    ],
  },

  {
    name: "seniorHealth5a",
    label: "אחר",
    defaultValue: "",
  },

  {
    name: "seniorHealth6",
    label: "שמיעה",
    defaultValue: "",
    options: [
      { name: "תקינה" },
      { name: "חלשה" },
      { name: "חרש" },
      { name: "אחר" },
    ],
  },

  {
    name: "seniorHealth6a",
    label: "אחר",
    defaultValue: "",
  },

  {
    name: "seniorHealth7",
    label: "הלבשה",
    defaultValue: "",
    options: [
      { name: "עצמאי" },
      { name: "זקוק לעזרה חלקית" },
      { name: "זקוק לעזרה מלאה" },
      { name: "אחר" },
    ],
  },

  {
    name: "seniorHealth7a",
    label: "אחר",
    defaultValue: "",
  },

  {
    name: "seniorHealth8",
    label: "אכילה",
    defaultValue: "",
    options: [
      { name: "עצמאי" },
      { name: "זקוק לעזרה חלקית" },
      { name: "זקוק לעזרה מלאה" },
      { name: "אחר" },
    ],
  },

  {
    name: "seniorHealth8a",
    label: "אחר",
    defaultValue: "",
  },

  {
    name: "seniorHealth9",
    label: "רחיצה",
    defaultValue: "",
    options: [
      { name: "עצמאי" },
      { name: "זקוק לעזרה חלקית" },
      { name: "זקוק לעזרה מלאה" },
      { name: "אחר" },
    ],
  },

  {
    name: "seniorHealth9a",
    label: "אחר",
    defaultValue: "",
  },

  //תיאור מצב המעסיק ובן הזוג מההיבט התיפקודי הקוגניטיבי
  {
    name: "seniorHealth10",
    label: "פרט",
    defaultValue: "",
  },

  //מערכת תמיכה בלתי פורמלית
  {
    name: "seniorSupport1",
    label: "מערכת תמיכה",
    defaultValue: "",
    options: [{ name: "אין" }, { name: "יש" }],
  },

  {
    name: "seniorSupport2a",
    label: "שם משפחה",
    defaultValue: "",
  },
  {
    name: "seniorSupport2b",
    label: "שם פרטי",
    defaultValue: "",
  },
  {
    name: "seniorSupport2c",
    label: "טלפון",
    defaultValue: "",
  },

  {
    name: "seniorSupport3a",
    label: "שם משפחה",
    defaultValue: "",
  },
  {
    name: "seniorSupport3b",
    label: "שם פרטי",
    defaultValue: "",
  },
  {
    name: "seniorSupport3c",
    label: "טלפון",
    defaultValue: "",
  },

  {
    name: "seniorServiceSupport",
    label: "שירותים",
    defaultValue: "",
    options: [
      { name: "עוזרת בית" },
      { name: "טיפול בית חוק סיעוד" },
      { name: "אפוטרופוס" },
      { name: "מרכז יום" },
      { name: "קופת חולים" },
      { name: "מתנדב/ שכן" },
      { name: "אחר" },
    ],
  },
  {
    name: "seniorServiceSupport2",
    label: "אחר",
    defaultValue: "",
  },

  //מקורות הכנסה

  {
    name: "seniorMoney",
    label: "מקורות הכנסה",
    defaultValue: "",
    options: [
      { name: "קצבת ביטוח לאומי ללא השלמת הכנסה" },
      { name: "קצבת ביטוח לאומי עם השלמת הכנסה" },
      { name: "פנסיה מהעבודה" },
      { name: "רנטה, שילומים" },
      { name: "אחר" },
    ],
  },
  {
    name: "seniorMoney2",
    label: "אחר",
    defaultValue: "",
  },

  //<h2>תיאור מצב סוציו אקונומי</h2>
  {
    name: "seniorMoneyStatus",
    label: "תיאור",
    defaultValue: "",
  },

  //מטפלים קודמים

  {
    name: "seniorPrevWorkers",
    label: "מטפלים קודמים",
    defaultValue: "",
    options: [{ name: "כן" }, { name: "לא" }],
  },
  {
    name: "seniorPrevWorkers2",
    label: "סיבת עזיבה",
    defaultValue: "",
  },

  //פרופיל עובד זר מבוקש
  {
    name: "seniorWants1",
    label: "מין",
    defaultValue: "",
    options: [{ name: "זכר" }, { name: "נקבה" }],
  },
  {
    name: "seniorWants2",
    label: "ארץ מוצא",
    defaultValue: "",
  },

  {
    name: "seniorWants3",
    label: "שפות",
    defaultValue: "",
  },

  {
    name: "seniorWants4",
    label: "שפות 2",
    defaultValue: "",
  },

  {
    name: "seniorWants5",
    label: "טווח גילאים",
    defaultValue: "",
  },

  {
    name: "seniorWants6",
    label: "דת",
    defaultValue: "",
  },

  {
    name: "seniorWants7",
    label: "מצב משפחתי",
    defaultValue: "",
  },

  {
    name: "seniorWants8",
    label: "נתונים פיזים",
    defaultValue: "",
  },

  {
    name: "seniorWants9",
    label: "נתונים אישיותיים",
    defaultValue: "",
  },

  {
    name: "seniorWants10",
    label: "מיומניות עם כישורים מיוחדים נדרשים",
    defaultValue: "",
  },

  {
    name: "seniorWants11",
    label: "רשיון נהיגה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },

  /* תפקידי המטפל */
  {
    name: "seniorWorker1",
    label: "ניקיון",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },
  {
    name: "seniorWorker2",
    label: "כביסה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },
  {
    name: "seniorWorker3",
    label: "הלבשה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },
  {
    name: "seniorWorker4",
    label: "רחצה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },
  {
    name: "seniorWorker5",
    label: "האכלה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },
  {
    name: "seniorWorker6",
    label: "החתלה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },
  {
    name: "seniorWorker7",
    label: "ליווי לטיפולים רפואיים",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },

  {
    name: "seniorWorker8",
    label: "השגחה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },

  {
    name: "seniorWorker10",
    label: "מתן תרופות",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },

  {
    name: "seniorWorker11",
    label: "קניות/סידורים",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },

  {
    name: "seniorWorker12",
    label: "אחר",
    defaultValue: "",
  },

  {
    name: "seniorWorker13",
    label: "יום חופשה מיועד לעובד הזר",
    defaultValue: "",
  },

  {
    name: "seniorWorker14",
    label: "מיקום מיגורי העובד הזר",
    defaultValue: "",
    options: [{ name: "חדר פרטי" }, { name: "חדר עם המטופל" }, { name: "אחר" }],
  },

  {
    name: "seniorWorker15",
    label: "אחר",
    defaultValue: "",
  },

  {
    name: "seniorWorker16",
    label: "ציפיות מהעובד הזר",
    defaultValue: "",
  },

  {
    name: "seniorRecommends",
    label: "סיכום והמלצות",
    defaultValue: "",
  },

  {
    name: "seniorSign",
    label: "שם המעסיק / בן משפחה / אפוטרופוס",
    defaultValue: "",
  },

  {
    name: "clientMugan",
    label: 'האם הכתובת הנ"ל הינה דיור מוגן?',
    defaultValue: "",
    options: [
      {
        name: "כן",
      },
      {
        name: "לא",
      },
    ],
    required: true,
  },

  {
    name: "guardianLinkOther",
    label: "קרבה אחר",
    defaultValue: "",
  },

  {
    name: "guardianLinkTz",
    label: "מספר זהות",
    defaultValue: "",
  },

  {
    name: "seniorHealth4aa",
    label: "אמצעי עזר",
    defaultValue: "",
  },

  {
    name: "seniorOtherComments",
    label: "הערות נוספות",
    defaultValue: "",
  },

  {
    name: "clientReligion2",
    label: "לאום",
    defaultValue: "",
    options: [
      { name: "יהודי" },
      { name: "מוסלמי" },
      { name: "נוצרי" },
      { name: "בדואי" },
      { name: "דרוזי" },
      { name: "אחר" },
    ],
  },

  {
    name: "clientReligion2Other",
    label: "לאום אחר",
    defaultValue: "",
  },

  {
    name: "clientShabat",
    label: "שמירת שבת",
    defaultValue: "",
    options: [{ name: "כן" }, { name: "לא" }],
  },

  {
    name: "clientKasrut",
    label: "שמירת כשרות",
    defaultValue: "",
    options: [{ name: "כן" }, { name: "לא" }],
  },

  {
    name: "clientPet",
    label: 'בע"ח',
    defaultValue: "",
    options: [{ name: "כן" }, { name: "לא" }],
  },

  {
    name: "clientPetOther",
    label: 'בע"ח לפרט',
    defaultValue: "",
  },

  {
    name: "clientSmoke",
    label: "מעשן",
    defaultValue: "",
    options: [{ name: "כן" }, { name: "לא" }],
  },
  {
    name: "seniorWorkerCook",
    label: "בישול",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },

  {
    name: "seniorWorkerTipulLaila",
    label: "טיפול לילה",
    defaultValue: "",
    options: [{ name: "לא" }, { name: "כן" }],
  },
  // New changes
  {
    name: "clientFloor",
    label: "קומה",
    defaultValue: "",
  },
  {
    name: "clientElevator",
    label: "מעלית",
    defaultValue: "",
    options: [{ name: "כן" }, { name: "לא" }],
  },
  {
    name: "clientNumberOfRooms",
    label: "מס' חדרים בבית",
    defaultValue: "",
  },
  {
    name: "contactLink1Relation",
    label: "קרבה למטופל/ת",
    defaultValue: "",
  },
  {
    name: "contactLink1Profession",
    label: "עיסוק",
    defaultValue: "",
  },
  {
    name: "contactLink1Age",
    label: "גיל",
    defaultValue: "",
  },
  {
    name: "contactLink1Comment",
    label: "הערות",
    defaultValue: "",
  },
  {
    name: "contactLink2Relation",
    label: "קרבה למטופל/ת",
    defaultValue: "",
  },
  {
    name: "contactLink2Profession",
    label: "עיסוק",
    defaultValue: "",
  },
  {
    name: "contactLink2Age",
    label: "גיל",
    defaultValue: "",
  },
  {
    name: "contactLink2Comment",
    label: "הערות",
    defaultValue: "",
  },
  {
    name: "contactLink3Relation",
    label: "קרבה למטופל/ת",
    defaultValue: "",
  },
  {
    name: "contactLink3Profession",
    label: "עיסוק",
    defaultValue: "",
  },
  {
    name: "contactLink3Age",
    label: "גיל",
    defaultValue: "",
  },
  {
    name: "contactLink3Comment",
    label: "הערות",
    defaultValue: "",
  },
  {
    name: "seniorIsAddressSameAsCurrent",
    label: 'בכתובת הנ"ל',
    defaultValue: "",
    options: [{ name: "כן" }, { name: "לא" }],
  },
  {
    name: "seniorPlaceOtherMain1",
    label: "בדיור מוגן: ",
    defaultValue: "",
  },

  {
    name: "seniorPlaceOtherMain2",
    label: "כתובת",
    defaultValue: "",
  },
  {
    name: "clientLanguage",
    label: "שפות",
    defaultValue: "",
    options: [{ name: "עברית" }, { name: "אנגלית" }, { name: "אחר" }],
  },
  {
    name: "clientLanguageOther",
    label: "אחר",
    defaultValue: "",
  },
  {
    name: "workerDayOffSunday",
    label: "אחר",
    defaultValue: "",
  },
  {
    name: "workerDayOffMonday",
    label: "אחר",
    defaultValue: "",
  },
  {
    name: "workerDayOffTuesday",
    label: "אחר",
    defaultValue: "",
  },
  {
    name: "workerDayOffWednesday",
    label: "אחר",
    defaultValue: "",
  },
  {
    name: "workerDayOffThursday",
    label: "אחר",
    defaultValue: "",
  },
  {
    name: "workerDayOffFriday",
    label: "אחר",
    defaultValue: "",
  },
  {
    name: "workerDayOffSaturday",
    label: "אחר",
    defaultValue: "",
  },
];
