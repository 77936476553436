import React from 'react'
import { map } from "lodash" //https://lodash.com/docs/4.17.15#map  //find

export default function ShowKevaPays(props) {

    const { data } = props

    //const counter = data?.kevaPays ? size(data?.kevaPays) : 0;

    let counter = 0;

    map(data?.kevaPays, item => {

        if (!item.errorCardPayThisMonth) {

            counter++;

        }
            
    }); 

  return (

    <div className='ShowKevaPays'>

        <h3>סה"כ הוראות קבע לביצוע היום: <strong>{counter}</strong></h3>
        
        <table className='defaultTable'>
            <tbody>

                <tr>
                    <th>#</th>
                    <th>שם לקוח</th>
                    <th>ת.ז.</th>
                    <th>סכום לתשלום</th>
                    <th>תשלום אחרון</th>
                    <th>בנקאית?</th>
                    <th>שגיאה בתשלום החודש?</th>
                    <th>#</th>
                </tr>

                {map(data?.kevaPays, item => {
                    if (true) {
                        return <ShowKevaDataTr item ={item } key ={ item?.id } /> 
                } else return false})}
                
            </tbody>
        </table>
        
    </div>
  )
}


function ShowKevaDataTr(props) {

    const {item} = props

    return(
        <tr>
            <td>{item.id}</td>
            <td>{item.clientData.name} {item.clientData.surname}</td>
            <td>{item.clientData.tz}</td>
            <td><strong>{item?.superTotalInvoice}</strong></td>
            <td>{item.lastPayShow}</td>
            <td>{ item.is_kevaPayBank === '1' ? 'כן' : 'לא' }</td>
            <td>{item.errorCardPayThisMonth ? <strong style={{ color: 'red' }}>כן - לא פעיל</strong> : <span>לא</span> }</td>
            <td>
                <a href={`/לקוחות?id=${item.clientData.id}`} target='_blank' rel="noreferrer">פתיחת תיק לקוח &raquo;</a>
            </td>
        </tr>
    )

}