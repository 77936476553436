import React, { useEffect, useState } from 'react'
import { InputGroup, FormControl, Button } from "react-bootstrap";

import { sendtoAdmin } from '../../../../DinamicForm/newApi/getDataFromApi';
import loader from "../../../../../img/preLoader.gif"

import { map,find } from "lodash";
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';

export default function NewTransaction(props) {

    const {  dataParam, transactions, setWorkerTransactions, isAnotherFirmImport } = props

    //console.log("transactions", transactions);
    //console.log('dataParam',  dataParam);

    const [ transactionId, setTransactionId ]= useState('');
    const [ money, setMoney ]= useState('');

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(false);

    //console.log('trasactionId', transactionId);

    //changeMoneyValue
    useEffect(() => {

        if(transactionId) {


          const itemPrice = find(transactions, { id: transactionId });

          if( money !== itemPrice?.priceValue ) {

              setMoney(itemPrice?.priceValue );

          }


        }
        
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId])

    const addTransaction = () => { 

        const sendData = {

            workerId : dataParam?.id,
            transactionId :  transactionId,
            money : money

        }

        //console.log('sendData', sendData);
        sendtoAdmin('insertTransactionWorker', 'financeWorkers', sendData, setResponse, setLoading);
    }

    useEffect(() => {

      if(response?.error) {

        toast.error(response?.error);

      } else if (response?.insert_id) {

        toast.success(response?.ok);
        setTransactionId('');
        setMoney(false);

        if(response?.workerTransactions) {
            setWorkerTransactions(response?.workerTransactions);
        }

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response])
    

  //console.log('response', response);
  //console.log('isAnotherFirmImport',isAnotherFirmImport);

  return (
    <div className='newTransaction'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        <h3>הוספת עסקה</h3>

        <Grid className='lineForm' container spacing={2}  direction="row" justifyContent='center' > {/*  */}
        
            <Grid item md={5} >

            <div className='selectInput'>
                <InputGroup className="customInput">
                    <InputGroup.Prepend className="inputPrependLabel ">
                    <InputGroup.Text>בחירת פריט</InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                    as="select"
                    /* 'inputPrepend customSelect' */
                    className="inputPrepend customSelect"
                    onChange={ (e) =>  setTransactionId(e.target.value) }
                    value={ transactionId }
                    //name=""
                    >
                    <option value="">בחירת פריט...</option>

                    {   map(transactions, item => {
                            
                            if( (isAnotherFirmImport && item?.id !== '1') || !isAnotherFirmImport ) {

                              return <option value={ item?.id } key={item.id} > { item.name } </option>

                            }
                        
                          })
                    }
                    
                    </FormControl>
                </InputGroup>
            </div>

            </Grid>

            {transactionId && <Grid item md={4} >

            <InputGroup className="customInput">
                
                  <InputGroup.Prepend className="inputPrependLabel">
                    <InputGroup.Text id="basic-addon3">סכום לתשלום</InputGroup.Text>
                  </InputGroup.Prepend>

                <FormControl
                  className="inputPrepend"
                  onChange={ (e)=> setMoney(e.target.value) }
                  //name={}
                  value={ money }
                />
              </InputGroup>

            </Grid>}

            { (transactionId ) && <Grid item md={2} >
                <Button onClick = { addTransaction } disabled={ parseFloat(money) <= 0 ? true : false } variant="success" style={{ width: '100%' }} >הוספה</Button>
            </Grid>}



        </Grid>

    </div>
  )
}
