import React, { useEffect, useState } from 'react'

import { map,range } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Grid } from '@material-ui/core';
import { FormControl, Button } from 'react-bootstrap';
import { Fragment } from 'react';
//import InputMet from '../../Finance/InputMet';

export default function AnnexA(props) {

    const { setMoreDataPdf, sendForm, responseData } = props

    console.log(responseData);

    //let date = new Date();
    //let current_datetime = new Date(date.toString().replace(/-/g, '/')); // IPHONE FIX

    const inputsBuilder = [
         /* {
            'name' : 'date1',
            'label' : 'י/תצא לחופשה מתאריך',
            'defaultValue': current_datetime,
            
        },
        {
            'name' : 'date2',
            'label' : 'עד תאריך',
            'defaultValue': current_datetime,
            
        }, */
        
        {
            'name' : 'text1',
            'label' : 'גובה',
            'defaultValue': '',
            
        },
        {
            'name' : 'text2',
            'label' : 'משקל',
            'defaultValue': '',
            
        },

        {
            'name' : 'seniorEngName',
            'label' : 'שם מלא באנגלית',
            'defaultValue': '',
            
        },
       
        {
            'name' : 'smoke',
            'label' : 'מעשן?',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    },
                                  
                ],
        },

        {
            'name' : 'area',
            'label' : 'אזור',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'תל אביב'
                    },
                    {
                        'id' : '2',
                        'name' : 'מרכז'
                    },
                    {
                        'id' : '3',
                        'name' : 'פריפריה'
                    }                                  
                ],
        },
        {
            'name' : 'cityEng',
            'label' : 'עיר באנגלית',
            'defaultValue': '',
            
        },
        {
            'name' : 'residenceType',
            'label' : 'סוג מגורים',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'בית פרטי'
                    },
                    {
                        'id' : '2',
                        'name' : 'דירה'
                    },
                    {
                        'id' : '3',
                        'name' : 'דירה בקיבוץ'
                    },
                    {
                        'id' : '4',
                        'name' : 'דירה במוסד לדיור מוגן'
                    },
                    {
                        'id' : '5',
                        'name' : 'אחר'
                    }  
                ],
        },
        {
            'name' : 'residenceTypeOther',
            'label' : 'סוג מגורים - אחר (נא לתאר)',
            'defaultValue': '',
            
        },
        {
            'name' : 'accomodation',
            'label' : 'מגורי העובד הזר/מטפל',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'חדר פרטי'
                    },
                    {
                        'id' : '2',
                        'name' : 'מתגורר באותו חדר'
                    },
                    {
                        'id' : '3',
                        'name' : 'מתגורר בסלון'
                    },
                    {
                        'id' : '4',
                        'name' : 'מתגורר בעליית גג'
                    },
                    {
                        'id' : '5',
                        'name' : 'אחר'
                    }  
                ],
        },
        {
            'name' : 'accomodationOther',
            'label' : 'אחר, נא לפרט',
            'defaultValue': '',
            
        },
        {
            'name' : 'accomodationOther2',
            'label' : 'אחר, נא לתאר',
            'defaultValue': '',
            
        },
        {
            'name' : 'otherPersons1',
            'label' : 'בן/בת זוג',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },

        {
            'name' : 'otherPersonsChildrens1',
            'label' : 'ילדים, כמה?',
            'defaultValue': '',
            
        },

        {
            'name' : 'otherPersonsChildrens2',
            'label' : 'ילדים. הגילאים',
            'defaultValue': '',
            
        },

        {
            'name' : 'otherPersonsChildrens3',
            'label' : 'ילדים. המגזר',
            'defaultValue': '',
            
        },

        {
            'name' : 'otherPersonsOther1',
            'label' : 'אחר, נא לפרט',
            'defaultValue': '',
            
        },

        {
            'name' : 'otherPersonsOther2',
            'label' : 'אחר, מספרם',
            'defaultValue': '',
            
        },

        {
            'name' : 'otherPersonsOther3',
            'label' : 'אחר, הגילאים',
            'defaultValue': '',
            
        },

        {
            'name' : 'otherPersonsOther4',
            'label' : 'אחר, המגזר',
            'defaultValue': '',
            
        },
        {
            'name' : 'language1',
            'label' : 'שפה עברית',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'language2',
            'label' : 'שפה ערבית',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'language3',
            'label' : 'שפה רוסית',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'language4',
            'label' : 'שפה אנגלית',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'languageOther',
            'label' : 'שפה אחרת',
            'defaultValue': '',
            
        },
        {
            'name' : 'pets1',
            'label' : 'טיפול בכלב/ים, חתול/ים...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'petsOther',
            'label' : 'בעלי חיים - אחר',
            'defaultValue': '',
            
        },
        {
            'name' : 'petsNumber',
            'label' : 'בעלי חיים - מספר ',
            'defaultValue': '',
            
        },
        {
            'name' : 'driving',
            'label' : 'חובה נהיגה',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'כן'
                    },
                    {
                        'id' : '2',
                        'name' : 'לא'
                    }
                ],
        },
        {
            'name' : 'dutiesOther',
            'label' : 'אחר, נא לפרט',
            'defaultValue': '',
            
        },
        {
            'name' : 'dutiesOther2',
            'label' : 'אחר, נא לתאר',
            'defaultValue': '',
            
        },
        {
            'name' : 'specialSupervisionOther',
            'label' : 'זקוק להשגחה מיוחדת - אחר',
            'defaultValue': '',
            
        },
        {
            'name' : 'specialSupervision',
            'label' : 'זקוק להשגחה מיוחדת',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'שיטיון (דמנציה)'
                    },
                    {
                        'id' : '2',
                        'name' : 'מחלת אלצהיימר'
                    },
                    {
                        'id' : '3',
                        'name' : 'מחלת נפש'
                    }
                ],
        },
        {
            'name' : 'merutak',
            'label' : 'המטופל...',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'מרותק למיטה'
                    },
                    {
                        'id' : '2',
                        'name' : 'מתנייד בכסא גלגלים'
                    },
                    {
                        'id' : '3',
                        'name' : 'מסתייע במקל הליכה'
                    },
                    {
                        'id' : '4',
                        'name' : 'הליכון'
                    },
                    {
                        'id' : '5',
                        'name' : 'לא מסתייע לצורך ניוד'
                    }
                ],
        },
        {
            'name' : 'explainDetail',
            'label' : 'הסבירו בפירוט כל סוג אחר של צרכים...',
            'defaultValue': '',
            
        },
        {
            'name' : 'insuranceCompany',
            'label' : 'חברת הביטוח',
            'defaultValue': '',
            'options' : 
                [
                    {
                        'id' : '1',
                        'name' : 'הראל'
                    },
                    {
                        'id' : '2',
                        'name' : 'מנורה'
                    },
                    {
                        'id' : '3',
                        'name' : 'איילון'
                    },
                ],
        },
        {
            'name' : 'address',
            'label' : 'כתובת מקום הבידוד בו ישהה העובד',
            'defaultValue': '',
            
        },
        {
            'name' : 'phone',
            'label' : 'מספר טלפון של איש קשר',
            'defaultValue': '',
            
        },
        
    ]


    const [formData, setFormData] = useState(defaultValueForm(inputsBuilder,responseData));

    
    const onChange = (e) => {

        //console.log(e.target.name, e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    /* const submitDate = (date) => { 

        const keys = Object.keys( date );
        const dateFieldName = keys[0];

        //console.log(key[0]);

        setFormData({
            ...formData,
            [dateFieldName]: date[dateFieldName],
        })


     } */


    useEffect(() => {
     
        setMoreDataPdf(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    

    const submit = () => { 
        
        sendForm();

    }

    //console.log('inputsBuilder', inputsBuilder);
    //console.log('formData', formData);
    

  return (
    <div>

        <h3 style={{margin: '0 0 30px 0'}}>נא להשלים פרטים:</h3>
        <Grid className='line' container spacing={2}  alignItems="center"  /* justifyContent="right" */ direction="row" >

            {/* <Grid item md={6} >
                <InputMet text={inputsBuilder[2].label} as={'dateInput'} value={ formData[inputsBuilder[2].name] } placeholder='' name={inputsBuilder[2].name} submit={submitDate} />
            </Grid> */}
            {/* <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[2].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[2].name}
                    value={formData[inputsBuilder[2].name]}
                />
            </Grid> */}

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[0].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[0].name}
                    value={formData[inputsBuilder[0].name]}
                />
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[1].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[1].name}
                    value={formData[inputsBuilder[1].name]}
                />
            </Grid>

            
            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[3].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[3].name}
                    value={formData[inputsBuilder[3].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[3].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[4].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[4].name}
                    value={formData[inputsBuilder[4].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[4].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>
            </Grid>
            
{/*             <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[5].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[5].name}
                    value={formData[inputsBuilder[5].name]}
                />
                
            </Grid> */}

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[6].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[6].name}
                    value={formData[inputsBuilder[6].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[6].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>
            </Grid>

            { formData[inputsBuilder[6].name] === '5' && <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[7].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[7].name}
                    value={formData[inputsBuilder[7].name]}
                />
                
            </Grid> }

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[8].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[8].name}
                    value={formData[inputsBuilder[8].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[8].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>
            </Grid>

            { formData[inputsBuilder[8].name] === '5' && <Fragment>
                
                <Grid item md={4} >

                    <header>
                        <h4>{inputsBuilder[9].label}</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[9].name}
                        value={formData[inputsBuilder[9].name]}
                    />

                </Grid>

                <Grid item md={4} >

                    <header>
                        <h4>{inputsBuilder[10].label}</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[10].name}
                        value={formData[inputsBuilder[10].name]}
                    />

                </Grid>
                
                
                </Fragment> }

            <Grid item md={8} >

                <header>
                    <h4>אנשים אחרים המתגוררים - <strong>{inputsBuilder[11].label}</strong></h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[11].name}
                    value={formData[inputsBuilder[11].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[11].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            {range(12, 19).map((current, index, range) => {

                return <Grid item md={4} key={current} >

                    <header>
                        <h4>{inputsBuilder[current].label}</h4>
                    </header>

                    <FormControl onChange={onChange}
                        //as="textarea"
                        name={inputsBuilder[current].name}
                        value={formData[inputsBuilder[current].name]}
                    />

                </Grid>

            })}

            {range(19, 23).map((current, index, range) => {

                return <Grid item md={4} >

                    <header>
                        <h4>{inputsBuilder[current].label}</h4>
                    </header>

                    <FormControl onChange={onChange}
                        as="select"
                        name={inputsBuilder[current].name}
                        value={formData[inputsBuilder[current].name]}
                    >
                        <option value="">בחירה...</option>

                        {map(inputsBuilder[current].options, item => {
                            if (true) {
                                return < option value ={item.id } key ={ item.name }> 
                                            { item.name } 
                                        </ option >
                            } else return false})}
                    </FormControl>

                    </Grid>
                })}
                
            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[23].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[23].name}
                    value={formData[inputsBuilder[23].name]}
                />
                
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[24].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[24].name}
                    value={formData[inputsBuilder[24].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[24].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[25].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[25].name}
                    value={formData[inputsBuilder[25].name]}
                />
                
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[26].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[26].name}
                    value={formData[inputsBuilder[26].name]}
                />
                
            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[27].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[27].name}
                    value={formData[inputsBuilder[27].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[27].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[28].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[28].name}
                    value={formData[inputsBuilder[28].name]}
                />

            </Grid>

            <Grid item md={8} >

                <header>
                    <h4>{inputsBuilder[29].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[29].name}
                    value={formData[inputsBuilder[29].name]}
                />

            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[31].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[31].name}
                    value={formData[inputsBuilder[31].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[31].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            <Grid item md={8} >

                <header>
                    <h4>{inputsBuilder[30].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[30].name}
                    value={formData[inputsBuilder[30].name]}
                />

            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[32].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[32].name}
                    value={formData[inputsBuilder[32].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[32].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            <Grid item md={8} >

                <header>
                    <h4>{inputsBuilder[33].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="textarea"
                    name={inputsBuilder[33].name}
                    value={formData[inputsBuilder[33].name]}
                />

            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[34].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    as="select"
                    name={inputsBuilder[34].name}
                    value={formData[inputsBuilder[34].name]}
                >
                    <option value="">בחירה...</option>

                    {map(inputsBuilder[34].options, item => {
                        if (true) {
                            return < option value ={item.id } key ={ item.name }> 
                                        { item.name } 
                                    </ option >
                        } else return false})}
                </FormControl>

            </Grid>

            <Grid item md={8} >

                <header>
                    <h4>{inputsBuilder[35].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[35].name}
                    value={formData[inputsBuilder[35].name]}
                />

            </Grid>

            <Grid item md={4} >

                <header>
                    <h4>{inputsBuilder[36].label}</h4>
                </header>

                <FormControl onChange={onChange}
                    //as="textarea"
                    name={inputsBuilder[36].name}
                    value={formData[inputsBuilder[36].name]}
                />

            </Grid>


                

        </Grid>

        <div style={{textAlign: 'center',margin: '30px 0 0 0'}}>
            <Button className='sendBtn' onClick={submit} size="lg" variant="success" >שליחה</Button>
        </div>
    </div>
  )

}

function defaultValueForm(inputsBuilder,savedData) {

    let objReturn = {};

    //console.log(savedData);
      
    map(inputsBuilder, item => {
              
        objReturn[item.name] = savedData[item.name] ?  savedData[item.name] : item.defaultValue
        
    })
    
    return objReturn;

}

