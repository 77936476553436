import React, { Component } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import InputMet from './../Finance/InputMet';
import convertDateToDb from './../../../convertDateToDb'
import { ConstantsNames, RestUrls } from "./../../../Urls";

export default class WorkersData extends Component {

    constructor(props) {
        super(props)
    
        let date = new Date();

        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.state = {
            branch : '',
            company : '',
            socialWorker : '',
            date1 : new Date(firstDay.toString().replace(/-/g, '/')),
            date2 : new Date(lastDay.toString().replace(/-/g, '/')),

        }
    }

    submit = (event) => {

        //console.log(event);

        this.setState(event,
            function(){
                //this.submitToProps();
            }
        );
    }

    handleSelect = (event) => {

        
        this.setState({ [event.target.name]: event.target.value},function(){
            //console.log(this.state)
            //this.submitToProps();
        });

    }

    sendData = () => {

        let sendObj = {

            socialWorker : this.state.socialWorker,
            date1 :  convertDateToDb(this.state.date1),
            date2 : convertDateToDb(this.state.date2)
        } 
        
        var md5 = require('md5');
        
        //let query = '&rakaz=' + sendObj.socialWorker;
        let query = '';
        query = query + '&date1=' + sendObj.date1;
        query = query + '&date2=' + sendObj.date2;
        query = query + '&company=' + this.state.company;
        query = query + '&branch=' + this.state.branch;
        
        let link = ConstantsNames.base_url + '/ReportsExcel/excelWorkersData?token=' + md5(RestUrls.Code) + query;
        //let link = ConstantsNames.base_url + '/ReportsPdf/makeCsvVisit?token=' + md5(RestUrls.Code) + query;

        window.open(link, '_blank');

        //console.log(sendObj);
    }
    

    render() {
        return (
            <section className="ReportsSection animate__animated animate__fadeIn">

                <p>תאריך לפי קליטת העובד:</p>
                <div className="row">

                    <div className="col-md-3 divBoostrap col-sm-12">
                        <InputMet text='מתאריך' as={'dateInput'} value={this.state.date1} placeholder='' name='date1' submit={this.submit} />
                    </div>

                    <div className="col-md-3 divBoostrap col-sm-12">
                        <InputMet text='לתאריך' as={'dateInput'} value={this.state.date2} placeholder='' name='date2' submit={this.submit} />
                    </div>

                    <div className="col-md-3 divBoostrap col-sm-12">
                        <InputGroup className="customInput">
                            <InputGroup.Prepend className='inputPrependLabel '>
                                <InputGroup.Text>סניף</InputGroup.Text>
                            </InputGroup.Prepend>
                            
                            <FormControl
                                as='select'
                                /* 'inputPrepend customSelect' */
                                className='inputPrepend customSelect'
                                onChange={this.handleSelect} 
                                value={this.state.branch}
                                name="branch"
                            >
                            
                            <option value="">בחר...</option>

                            {this.props.fields.branches.map(item => { if (true) {
                                /* currentState={this.state} */
                                return  <option value={item.name} key={item.id}>{item.name}</option>
                            } else return false}) }
                            

                            </FormControl>

                        </InputGroup>
                    </div>

                    <div className="col-md-3 divBoostrap col-sm-12">
                        <InputGroup className="customInput">
                            <InputGroup.Prepend className='inputPrependLabel '>
                                <InputGroup.Text>סניף סיעוד</InputGroup.Text>
                            </InputGroup.Prepend>
                            
                            <FormControl
                                as='select'
                                /* 'inputPrepend customSelect' */
                                className='inputPrepend customSelect'
                                onChange={this.handleSelect} 
                                value={this.state.company}
                                name="company"
                            >
                            
                            <option value="">בחר...</option>

                            {this.props.fields.companies.map(item => { if (true) {
                                /* currentState={this.state} */
                                return  <option value={item.name} key={item.id}>{item.name}</option>
                            } else return false}) }
                            

                            </FormControl>

                        </InputGroup>
                    </div>

                    <div className="col-md-2 divBoostrap col-sm-12">
                        <Button style={{width : '100%'}} onClick={()=>this.sendData()} size="" variant="success" >שליחה</Button>
                    </div>

                </div>
                
            </section>
        )
    }
}
